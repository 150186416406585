import React from "react";

import {isEmpty, isUndefined} from "underscore";

import Resizeable from "components/common/Resizeable";
import Text from "components/common/Text";
import SketchCanvas from "components/sketch/canvas/SketchCanvas";
import VehicleSvg from "components/step/VehicleSvg";
import booleanTypesConstants from "constants/booleanTypes";
import carDamageTypesConstants from "constants/carDamageTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import claimTypesConstants from "constants/claimTypes";

import styles from "containers/step/summary/SummaryInfo.module.scss";

export default function SummaryInfo({claimType, repairServiceType}) {

    return (
        <Summary title="summary.content.info.title"
                 onePart={true}
                 refName={summaryRefConstants.INFO.REF}>

            {/* step 5*/}
            <Summary.ItemValue label="summary.content.carDamageType.label"
                               refName={summaryRefConstants.INFO.CAR_DAMAGE_TYPE}
                               formName={{name: flowConstants.steps.CAR_DAMAGE_TYPE.NAME}}>
                {form => (
                    form.type === carDamageTypesConstants.OTHER
                        ? form.otherName
                        : <Text label={`summary.content.carDamageType.value.${form.type}`}/>
                )}
            </Summary.ItemValue>

            {/* step 5.3*/}
            <Summary.ItemValue label="summary.content.theftType.label"
                               refName={summaryRefConstants.INFO.THEFT_TYPE}
                               formName={{name: flowConstants.steps.THEFT_TYPE.NAME}}>
                {form => <Text label={`summary.content.theftType.value.${form.theftType}.label`}/>}
            </Summary.ItemValue>

            {/* step 6*/}
            <Summary.ItemValue label={`summary.content.accidentDate.${claimType}.label`}
                               refName={summaryRefConstants.INFO.ACCIDENT_DATE}
                               formName={{name: flowConstants.steps.ACCIDENT_DATE.NAME}}>
                {form => form.date}
            </Summary.ItemValue>

            {/* step 7*/}
            <Summary.ItemValue label={`summary.content.accidentTime.${claimType}.label`}
                               refName={summaryRefConstants.INFO.ACCIDENT_TIME}
                               formName={{name: flowConstants.steps.ACCIDENT_TIME.NAME}}>
                {form => (
                    form.choice === booleanTypesConstants.DONT_KNOW
                        ? <Text label="summary.content.common.boolean.dontKnow"/>
                        : form.time
                )}
            </Summary.ItemValue>

            {/* step 8*/}
            <Summary.ItemValue label={`summary.content.accidentLocation.${claimType}.label`}
                               refName={summaryRefConstants.INFO.ACCIDENT_LOCATION}
                               formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
                {form => isEmpty(form.selectedLocation) ?
                    <Text label="summary.content.common.boolean.dontKnow"/> : form.selectedLocation}
            </Summary.ItemValue>

            {/* step 8*/}
            <Summary.ItemValue label={`summary.content.accidentLocation.description.${claimType}.label`}
                               refName={summaryRefConstants.INFO.ACCIDENT_DESCRIPTION}
                               formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
                {form => isEmpty(form.locationDescription) ?
                    <Text label="summary.content.common.boolean.dontKnow"/> : form.locationDescription}
            </Summary.ItemValue>

            {/* step 9*/}
            <Summary.ItemValue label="summary.content.vehicleType.label"
                               refName={summaryRefConstants.INFO.VEHICLE_TYPE}
                               formName={{name: flowConstants.steps.VEHICLE_TYPE.NAME}}>
                {form => <Text label={`summary.content.vehicleType.${form.vehicleType}`}/>}
            </Summary.ItemValue>

            {/* step 9*/}
            <Summary.ItemValue label="summary.content.vehicleDamage.current.label"
                               refName={summaryRefConstants.INFO.VEHICLE_DAMAGE.CURRENT}
                               formName={{name: flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME}}>
                {form => <SummaryVehicleDamage type={form.type} value={form.damage}/>}
            </Summary.ItemValue>

            {/* step 9*/}
            <Summary.ItemValue label="summary.content.vehicleDamageCurrentDescription.label"
                               refName={summaryRefConstants.INFO.VEHICLE_DAMAGE.CURRENT_DESCRIPTION}
                               formName={{name: flowConstants.steps.VEHICLE_DAMAGE_CURRENT_DESCRIPTION.NAME}}>
                {form => form.vehicleDamageCurrentDescription}
            </Summary.ItemValue>

            {
                repairServiceType === repairServiceTypeConstants.NOT_DRIVEABLE
                    ?
                    [
                        <Summary.ItemValue label={`summary.content.ableToDrive.vehicleLocation.label`}
                                           refName={summaryRefConstants.INFO.VEHICLE_LOCATION}
                                           formName={{name: flowConstants.steps.VEHICLE_LOCATION.NAME}}>
                            {form => isEmpty(form.selectedLocation) ?
                                <Text label="summary.content.common.boolean.dontKnow"/> : form.selectedLocation}
                        </Summary.ItemValue>,
                        <Summary.ItemValue label={`summary.content.ableToDrive.vehicleLocationDescription.label`}
                                           refName={summaryRefConstants.INFO.VEHICLE_LOCATION_DESCRIPTION}
                                           formName={{name: flowConstants.steps.VEHICLE_LOCATION.NAME}}>
                            {form => isEmpty(form.locationDescription) ?
                                <Text label="summary.content.common.boolean.dontKnow"/> : form.locationDescription}
                        </Summary.ItemValue>
                    ]
                    :
                    null
            }

            {
                claimType === claimTypesConstants.CAR
                    ?
                    <Summary.ItemValue label="summary.content.ableToDrive.label"
                                       refName={summaryRefConstants.INFO.MAPS_INTRO}
                                       formName={{name: flowConstants.steps.MAPS_INTRO.NAME}}>
                        {form => <Text label={`summary.content.ableToDrive.label.${form.repairServiceType === repairServiceTypeConstants.NOT_DRIVEABLE ? 'no' : 'yes'}`}/>}
                    </Summary.ItemValue>
                    :
                    null
            }

            {/* step 11*/}
            <Summary.ItemValue label="summary.content.parked.label"
                               refName={summaryRefConstants.INFO.PARKED}
                               formName={{name: flowConstants.steps.PARKED.NAME}}>
                {form => <Text label={`summary.content.parked.value.${form.parked ? 'yes' : 'no'}`}/>}
            </Summary.ItemValue>

            {/* step 12*/}
            <Summary.ItemValue label="summary.content.participantsNumber.multipleParticipants.label"
                               refName={summaryRefConstants.INFO.PARTICIPANTS_NUMBER.MULTIPLE_PARTICIPANTS}
                               formName={{name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}}
                               condition={form => !isUndefined(form.multipleParticipants)}>
                {form => (
                    <Text label={`summary.content.common.boolean.${form.multipleParticipants}`}/>
                )}
            </Summary.ItemValue>

            {/* step 12*/}
            <Summary.ItemValue label="summary.content.participantsNumber.count.label"
                               refName={summaryRefConstants.INFO.PARTICIPANTS_NUMBER.LENGTH}
                               formName={{name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}}
                               condition={form => form.multipleParticipants === booleanTypesConstants.YES}>
                {form => form.participants.length}
            </Summary.ItemValue>

            {/* step 13*/}
            <Summary.ItemValue label="summary.content.drawingIntro.label"
                               refName={summaryRefConstants.INFO.DRAWING_INTRO}
                               formName={{name: flowConstants.steps.DRAWING_INTRO.NAME}}
                               condition={form => form.wantDrawing === booleanTypesConstants.NO}>
                {() => <Text label="summary.content.drawingIntro.noDrawing.label"/>}
            </Summary.ItemValue>

            {/* step 14*/}
            <Summary.ItemValue label="summary.content.drawing.label"
                               refName={summaryRefConstants.INFO.DRAWING}
                               formName={{name: flowConstants.steps.DRAWING.NAME}}
                               condition={form => form.wantDrawing === booleanTypesConstants.YES}>
                {form => <SummaryDrawing sketch={form.sketch}/>}
            </Summary.ItemValue>

            {/* step 15*/}
            <Summary.ItemValue label="summary.content.eventDescription.label"
                               refName={summaryRefConstants.INFO.EVENT_DESCRIPTION}
                               formName={{name: flowConstants.steps.EVENT_DESCRIPTION.NAME}}>
                {form => form.description}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.casco.label"
                               formName={{name: flowConstants.steps.HAS_CASCO.NAME}}
                               refName={summaryRefConstants.HAS_CASCO.HAS_CASCO}
                               condition={form => form.hasCasco === booleanTypesConstants.NO || form.hasCasco === booleanTypesConstants.YES}>
                {form => (
                    <Text label={`summary.content.common.boolean.${form.hasCasco}`}/>
                )}
            </Summary.ItemValue>
        </Summary>
    );
}

function SummaryDrawing({sketch}) {
    return (
        <Resizeable className={styles.sketch}>
            {dimensions => dimensions
                ? <SketchCanvas {...sketch} isReadOnly={true}
                                dimensions={{width: dimensions.width, height: dimensions.width}}/>
                : null}
        </Resizeable>
    );
}

function SummaryVehicleDamage(props) {
    return (
        <div className={styles.damage}>
            <VehicleSvg {...props} />
        </div>
    );
}
