import React, {Component} from 'react';

import {isMobile} from "react-device-detect";
import {isEmpty} from "underscore";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import AutoCompleteField from "components/form/AutoCompleteField";
import MapForm from "components/form/MapForm";
import PickLocationMapField from "components/form/PickLocationMapField";
import TextField from "components/form/TextField";
import Country from "components/step/Country";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {MapContext} from "containers/context/MapContainer";
import close from "images/close.svg";
import {getAddress} from "selectors/selectedLocation";
import isUndefinedOrNull from "utility/utilityFunctions";
import claimTypesConstants from "constants/claimTypes";

import styles from "containers/step/accidentLocation/SelectedLocationText.module.scss";

export default class AccidentLocationSelectedLocation extends Component {
  static contextType = MapContext;

  fetchSuggestions = async (value) => {
    const {values: {country}} = this.props;
    const autocompleteService = new this.context.maps.places.AutocompleteService();

    const promise = new Promise((resolve) => {
      autocompleteService.getPlacePredictions({
        input: value,
        componentRestrictions: {country: country ? country.code : "SI"}
      }, resolve);
    });

    return await promise;
  };

  onSuggestionSelected = (suggestion) => {
    const {setFieldValue} = this.props;

    if (suggestion) {
      const geocoder = new this.context.maps.Geocoder();
      geocoder.geocode({address: suggestion.description}, results => {
        const value = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()};
        setFieldValue("focusLocation", value);
        setFieldValue("accidentLocation", value);
        setFieldValue("selectedLocation", suggestion.description);
        setFieldValue("selectedLocationAddress", getAddress(results[0].address_components));
      });
    }
  };

  onRemoveLocation = () => {
    const {setFieldValue} = this.props;

    setFieldValue("selectedLocation", null);
    setFieldValue("accidentLocation", null);
    setFieldValue("accidentSearch", null);
  };

  setCountry = async (value) => {
    const {setFieldValue} = this.props;

    setFieldValue("country", value);
    if (value == null) {
      return;
    }
    const geocoder = new this.context.maps.Geocoder();
    geocoder.geocode({address: value.name}, results => {
      const value = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()};
      setFieldValue("focusLocation", value);
    });
  };

  componentDidMount() {
    const {slovenia, setFieldValue} = this.props;
    if (isUndefinedOrNull(this.props.values.countryName)) {
      this.setCountry(slovenia);
      setTimeout(() => {
        setFieldValue("countryName", slovenia.name);
      }, 0);
    }
  };

  render() {
    const {values, onLocationClick, step, claimType} = this.props;

    const label = step.notDriveable === true ? 'accidentLocation.notDriveable' : 'accidentLocation';

    return (
      <Layout flex={1}>
        <Layout.Row>
          <Layout.Column spacing={10}>
            <Layout.OneRow>
              <Text element="p" variant="variant35" label={`${label}.content.location.abroad.label`}/>
            </Layout.OneRow>
            <Layout.OneColumn spacing={10}></Layout.OneColumn>
            <Country name="countryName"
                     setCountry={this.setCountry}
                     placeholder={claimType === claimTypesConstants.PERSON? `${label}.content.country.placeholder.person` : `${label}.content.country.placeholder`}
                     label={`${label}.content.country.label`} />
            <Layout.OneColumn spacing={10}></Layout.OneColumn>
            <Layout.OneRow flex={1}>
              <AutoCompleteField name="accidentSearch"
                                 placeholder={`${label}.content.location.placeholder`}
                                 label={`${label}.content.location.label`}
                                 fetchSuggestions={this.fetchSuggestions}
                                 onSuggestionSelected={this.onSuggestionSelected}
                                 renderSuggestionValue={suggestion => suggestion ? suggestion.description : ""}
                                 renderValue={suggestion => suggestion ? suggestion.description : ""}/>
            </Layout.OneRow>
            <Layout.OneColumn spacing={10}></Layout.OneColumn>
            <Layout.OneRow>
              <Text element="span" variant="variant3" label={`${label}.content.location.selectedLocation.label`}/>
            </Layout.OneRow>
            <Layout.OneColumn spacing={5}/>
            <Layout.OneRow minWidth={150} width="100%">
              <SelectedLocationText element="p"
                                    variant="variant25"
                                    value={values.selectedLocation}
                                    onButtonClick={this.onRemoveLocation}/>
            </Layout.OneRow>
            {isMobile
              ? (
                <MapForm buttonLabel={`${label}.content.openMap.label`}>
                  <PickLocationMapField name="accidentLocation"
                                        country={values.countryName}
                                        center={values.focusLocation}
                                        onLocationClick={onLocationClick}/>
                </MapForm>
              )
              : null
            }
          </Layout.Column>
          <Layout.Column spacing={10}>
            <Layout.OneRow flex={1}>
              <TextField name="locationDescription"
                         placeholder={`${label}.content.locationDescription.placeholder`}
                         label={`${label}.content.locationDescription.label`}
                         multiLine={true}
                         variant="variant1"
                         maxLength={fieldLengthConstants[flowConstants.steps.ACCIDENT_LOCATION.NAME].description + 1}/>
            </Layout.OneRow>
          </Layout.Column>
        </Layout.Row>
        <Layout.Column spacing={30}></Layout.Column>
      </Layout>
    );
  }
}

function SelectedLocationText({element, variant, value, onButtonClick}) {
  if (!isEmpty(value)) {
    return (
      <div className={styles.root}>
        <Text className={styles.text} element={element} variant={variant}>{value}</Text>
        <img className={styles.delete}
             src={close}
             alt="delete"
             onClick={onButtonClick}/>
      </div>
    );
  } else {
    return null;
  }
}
