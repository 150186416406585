export default {
  direction: {
    FORWARD: "forward",
    BACKWARD: "backward",
  },
  companies: {
    GENERALI: "generali",
    WIZ: "wiz",
  },
  steps: {
    INTRO: { // step 1
      NAME: "intro"
    },
    CLAIM_TYPE: { // step 2
      NAME: "claimType"
    },
    CAR_DAMAGE_TYPE: { // step 5
      NAME: "carDamageType",
    },
    MECHANICAL_DAMAGE: { // step 5.1
      NAME: "mechanicalDamage",
    },
    MECHANICAL_DAMAGE_OWN: { // step 5.2
      NAME: "mechanicalDamageOwn",
    },
    THEFT_TYPE: { // step 5.3
      NAME: "theftType",
    },
    ACCIDENT_DATE: { // step 6
      NAME: "accidentDate",
    },
    ACCIDENT_TIME: { // step 7
      NAME: "accidentTime",
    },
    ACCIDENT_LOCATION: { // step 8
      NAME: "accidentLocation",
    },
    VEHICLE_TYPE: { // step 9
      NAME: "vehicleType",
    },
    VEHICLE_DAMAGE_CURRENT: { // step 9a
      NAME: "vehicleDamageCurrent",
    },
    VEHICLE_DAMAGE_CURRENT_DESCRIPTION: { // 9d
      NAME: "vehicleDamageCurrentDescription",
    },
    PARKED: { // step 11
      NAME: "parked",
    },
    PARTICIPANTS_NUMBER: { // step 12
      NAME: "participantsNumber",
    },
    DRAWING_INTRO: { // step 13
      NAME: "drawingIntro",
    },
    DRAWING: { // step 14
      NAME: "drawing",
    },
    EVENT_DESCRIPTION: { // step 15
      NAME: "eventDescription",
    },
    PARTICIPANTS_INFO: { // step 16-18
      NAME: "participantsInfo",
    },
    OWN_VEHICLE_INFO_WAS_DRIVER: { // step 19
      NAME: "ownVehicleInfoWasDriver",
    },
    OWN_VEHICLE_INFO_DRIVER_HURT: { // step 20
      NAME: "ownVehicleInfoDriverHurt",
    },
    OWN_VEHICLE_INFO_REPORTED_POLICE: { // step 21
      NAME: "ownVehicleInfoReportedPolice",
    },
    OWN_VEHICLE_INFO_PASSENGERS: { // step 22
      NAME: "ownVehiclePassengers",
    },
    OWN_VEHICLE_INFO_PASSENGERS_HURT: { // step 23 - deleted
      NAME: "ownVehiclePassengersHurt",
    },
    OTHER_PARTICIPANTS_INFO: { // step 24
      NAME: "otherParticipantsInfo",
    },
    OTHER_PARTICIPANTS_DRIVER_HURT: { // step 25 - deleted
      NAME: "otherParticipantsDriverHurt",
    },
    OTHER_PARTICIPANTS_PASSENGERS: { // step 26 - deleted
      NAME: "otherParticipantsPassengers",
    },
    OTHER_PARTICIPANTS_PEDESTRIAN_HURT: { // step 27 - deleted
      NAME: "otherParticipantsPedestrianHurt",
    },
    RESPONSIBLE_PERSON: { // step 28
      NAME: "responsiblePerson",
    },
    DOCUMENTS: { // step 30
      NAME: "documents",
    },
    MAPS_INTRO: { // step 31
      NAME: "mapsIntro",
    },
    VEHICLE_DAMAGE_INSPECTION_POINT: { // step 32
      NAME: "vehicleDamageInspectionPoint",
    },
    VEHICLE_LOCATION: {
      NAME: "vehicleLocation",
    },
    INSURANCE_OWNER: { // step 33
      NAME: "insuranceOwner"
    },
    USER_IDENTIFICATION_TYPE: { // step 33
      NAME: "userIdentificationType"
    },
    APPLICANT_DATA: { // step 33
      NAME: "applicantData"
    },
    USER_LOGIN: {
      NAME: "userLogin"
    },
    USER_IDENTIFICATION: { // step 33
      NAME: "userIdentification",
    },
    BANK_ACCOUNTS: { // step 34
      NAME: "bankAccounts",
    },
    HAS_CASCO: { // step 34
      NAME: "hasCasco",
    },
    REPORT_WITH_OTHER_COMPANY: { // step 34
      NAME: "reportWithOtherCompany",
    },
    SUMMARY: { // step 35
      NAME: "summary",
    },
    PHONE_NUMBER: { // step 36
      NAME: "phoneNumber",
    },
    MESSAGE: { // step 37
      NAME: "message",
    },
    THANK_YOU: { // step 38
      NAME: "thankYou",
    },
  },
  homeSteps: {
    HOME_DAMAGE_TYPE: { // step 3
      NAME: "homeDamageType",
    },
    HOME_BURGLARY_WHO: { // step
      NAME: "homeBurglaryWho",
    },
    HOME_BURGLARY_PROPERTY_TYPE: { // step
      NAME: "homeBurglaryPropertyType",
    },
    HOME_BURGLARY_VIOLENT: { // step
      NAME: "homeBurglaryViolent",
    },
    HOME_DAMAGED_GOODS: { // step 4
      NAME: "homeDamagedGoods",
    },
    HOME_DAMAGED_GOODS_INFO: { // step 5
      NAME: "homeDamagedGoodsInfo",
    },
    HOME_VEHICLE_INFO: {
      NAME: "homeVehicleInfo",
    },
    HOME_REPORTED_POLICE: { // step 10
      NAME: "homeReportedPolice",
    },
    HOME_RESPONSIBLE_PERSON: { // step 11
      NAME: "homeResponsiblePerson",
    },
    HOME_SOMEONE_ELSE_RESPONSIBLE: { // step 11
      NAME: "homeSomeoneElseResponsible",
    },
    HOME_DOCUMENTS: { // step 13
      NAME: "homeDocuments",
    },
    HOME_CALCULATION_TYPE: { // step 14
      NAME: "homeCalculationType",
    },
  },
  personSteps: {
    CLAIM_COMPENSATION_VEHICLE: {
      NAME: "claimCompensationVehicle",
    },
    DECEASED_PERSON_INFORMATION: {
      NAME: "deceasedPersonInformation",
    },
    INJURED_PERSON_WORKING_STATUS: {
      NAME: "injuredPersonWorkingStatus",
    },
    CLAIM_COMPENSATION_PERSON: {
      NAME: "claimCompensationPerson",
    },
    MEDICAL_CARE_TYPE: {
      NAME: "medicalCareType",
    },
    PERSON_REPORTED_POLICE: {
      NAME: "personReportedPolice"
    },
    SUFFERED_MEDICAL_COSTS: {
      NAME: "sufferedMedicalCost",
    },
    MEDICAL_OPERATION_NEEDED: {
      NAME: "medicalOperationNeeded",
    },
    MEDICAL_PROBLEM_DESCRIPTION: {
      NAME: "medicalProblemDescription",
    },
    PAST_MEDICAL_CONDITIONS: {
      NAME: "pastMedicalConditions",
    },
    SICKNESS_DESCRIPTION: {
      NAME: "sicknessDescription",
    },
    HAD_OPERATION: {
      NAME: "hadOperation",
    },
    ACCIDENT_OCCURRENCE_TYPE: {
      NAME: "accidentOccurrenceType",
    },
    CAUSE_OF_DEATH: {
      NAME: "causeOfDeath",
    },
    WRITING_REPORT: {
      NAME: "writingReport",
    },
    WITNESSES_PRESENT: {
      NAME: "witnessesPresent",
    },
    PERSON_INSURANCE: {
      NAME: "personInsurance",
    },
    PERSON_RESPONSIBLE: {
      NAME: "personResponsible",
    },
    RESPONSIBLE_PERSON_RELATION: {
      NAME: "responsiblePersonRelation",
    },
    DAMAGE_DESCRIPTION: {
      NAME: "damageDescription",
    },
    DAMAGES_CLAIM_FILED: {
      NAME: "damagesClaimFiled",
    },
    PLACE_OF_DEATH: {
      NAME: "placeOfDeath",
    },
    RESPONSIBLE_PERSON_TYPE: {
      NAME: "responsiblePersonType",
    },
    ACCIDENT_VEHICLE_INFO: {
      NAME: "accidentVehicleInfo",
    },
    REFERRAL_NUMBERS: {
      NAME: "referralNumbers",
    },
    CONDOLENCES: {
      NAME: "condolences",
    },
    SUFFERED_EVENT_TYPE: {
      NAME: "sufferedEventType",
    },
    PERSON_DAMAGE: {
      NAME: "personDamage",
    },
    LIFE_INSURANCE_CLAIM: {
      NAME: "lifeInsuranceClaim",
    },
    PERSON_PARTICIPANTS_INFO: {
      NAME: "personParticipantsInfo",
    },
    PERSON_DOCUMENTS: {
      NAME: "personDocuments",
    },
    BENEFICIARY_PERSONS: {
      NAME: "beneficiaryPersons",
    },
    ACCIDENT_INVOLVED: {
      NAME: "accidentInvolved"
    }
  },
  generalSteps: {
    GENERAL_DOCUMENTS: { // step 10
      NAME: "generalDocuments",
    },
  }
};
