import React, {Component} from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import BankAccountForm from "components/form/BankAccountForm";
import DateField from "components/form/DateField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import {isSloAddress} from "utility/utilityFunctions";
import personTypesConstants from "constants/personTypes";
import ToggleButton from "components/form/ToggleButton";
import Switch, {Case,} from "react-switch-case";
import FadeView from "components/view/FadeView";
import _ from "lodash";

export default function BankAccountOtherPerson(props) {
    return (
        <RegistryContext.Consumer>
            {context => (
                <InnerBankAccountOtherPerson slovenia={context.registry.slovenia} {...props} name="otherPerson"/>
            )}
        </RegistryContext.Consumer>
    );
}

export class InnerBankAccountOtherPerson extends Component {

    onClick = (value, callback) => {
        callback();
    };

    render() {
        const {slovenia, name, values} = this.props;

        const {otherPersonType} = values;

        const address = _.get(this.props, "values.otherPerson.address", {});

        return (
            <Layout spacing={5} width="100%" maxWidth={440} justifyContent="center">
                <Layout.OneColumn spacing={25}/>
                <Layout.OneColumn>
                    <Text element="span" variant="variant2" label="bankAccounts.content.disclaimer"/>
                </Layout.OneColumn>
                <Layout.OneColumn spacing={25}/>
                <Layout.OneColumn spacing={0}>
                    <ToggleButton name="otherPersonType" onClick={this.onClick}>
                        <Layout.OneRow flex={1} spacing={8}>
                            <ToggleButton.Choice value={personTypesConstants.NATURAL}
                                                 label="bankAccounts.content.personType.button.natural"
                                                 variant="variant21"/>
                            <ToggleButton.Choice value={personTypesConstants.LEGAL}
                                                 label="bankAccounts.content.personType.button.legal"
                                                 variant="variant21"/>
                        </Layout.OneRow>
                    </ToggleButton>
                </Layout.OneColumn>
                <FadeView step={otherPersonType}>
                    <Switch condition={otherPersonType}>
                        <Case value={personTypesConstants.NATURAL}>
                            <Layout spacing={5} width="100%" maxWidth={440} justifyContent="center">
                                <Layout.OneColumn>
                                    <Layout.OneColumn spacing={20}/>
                                    <Text element="div" label="bankAccounts.content.other.person.title"
                                          variant="variant24"/>
                                    <Layout.OneColumn spacing={10}/>
                                    <Text element="div" label="bankAccounts.content.other.person.subtitle"
                                          variant="variant25"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={30}/>
                                <Layout.OneColumn>
                                    <Text element="div" label="bankAccounts.content.other.person.data"
                                          variant="variant2"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.name`}
                                               transform={capitalize}
                                               label="bankAccounts.content.name.label"
                                               placeholder="bankAccounts.content.name.placeholder"
                                               isRequired={true}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].name + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.surname`}
                                               transform={capitalize}
                                               label="bankAccounts.content.surname.label"
                                               placeholder="bankAccounts.content.surname.placeholder"
                                               isRequired={true}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].surname + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <DateField name={`${name}.birthDate`}
                                               label="bankAccounts.content.birthDate.label"
                                               placeholder="bankAccounts.content.birthDate.placeholder"
                                               modal={true}
                                               legalAgeField={true}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <Address name={`${name}.address`}
                                             isRequired={true}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.telephoneNumber`}
                                               label="bankAccounts.content.telephoneNumber.label"
                                               placeholder="bankAccounts.content.telephoneNumber.placeholder"
                                               isRequired={false}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].telephoneNumber + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.email`}
                                               label="bankAccounts.content.email.label"
                                               placeholder="bankAccounts.content.email.placeholder"
                                               isRequired={false}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].email + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TaxNumber name={`${name}.taxNumber`}
                                               placeholder="bankAccounts.content.taxNumber.placeholder"
                                               label="bankAccounts.content.taxNumber.label"
                                               isRequired={true}
                                               isSlovenia={isSloAddress(address, slovenia)}
                                               maxLength={isSloAddress(address, slovenia) ? fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumberSlo + 1 : fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumber + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={20}/>
                                <Layout.OneColumn>
                                    <Text element="div" label="bankAccounts.content.other.person.bank.account.data"
                                          variant="variant2"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={10}/>
                                <Layout.OneColumn spacing={0}>
                                    <BankAccountForm name={`${name}.bankAccount`} formName={name} isRequired={true}/>
                                </Layout.OneColumn>
                            </Layout>
                        </Case>
                        <Case value={personTypesConstants.LEGAL}>
                            <Layout spacing={5} width="100%" maxWidth={440} justifyContent="center">
                                <Layout.OneColumn>
                                    <Layout.OneColumn spacing={20}/>
                                    <Text element="div" label="bankAccounts.content.other.person.title.legal"
                                          variant="variant24"/>
                                    <Layout.OneColumn spacing={10}/>
                                    <Text element="div" label="bankAccounts.content.other.person.subtitle.legal"
                                          variant="variant25"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={30}/>
                                <Layout.OneColumn>
                                    <Text element="div" label="bankAccounts.content.other.person.data.legal"
                                          variant="variant2"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.legalPerson.name`}
                                               transform={capitalize}
                                               placeholder="bankAccounts.content.name.placeholder.legal"
                                               label="bankAccounts.content.name.label.legal"
                                               isRequired={true}
                                               maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].name + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <Address name={`${name}.legalPerson.address`}
                                             isRequired={true}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.legalPerson.telephoneNumber`}
                                               label="bankAccounts.content.telephoneNumber.label.legal"
                                               placeholder="bankAccounts.content.telephoneNumber.placeholder.legal"
                                               isRequired={false}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].telephoneNumber + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TextField name={`${name}.legalPerson.email`}
                                               label="bankAccounts.content.email.label.legal"
                                               placeholder="bankAccounts.content.email.placeholder.legal"
                                               isRequired={false}
                                               maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].email + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn>
                                    <TaxNumber name={`${name}.legalPerson.taxNumber`}
                                               placeholder="bankAccounts.content.taxNumber.placeholder.legal"
                                               label="bankAccounts.content.taxNumber.label.legal"
                                               isRequired={true}
                                               isSlovenia={isSloAddress(address, slovenia)}
                                               maxLength={isSloAddress(address, slovenia) ? fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumberSlo + 1 : fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumber + 1}/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={20}/>
                                <Layout.OneColumn>
                                    <Text element="div" label="bankAccounts.content.other.person.bank.account.data.legal"
                                          variant="variant2"/>
                                </Layout.OneColumn>
                                <Layout.OneColumn spacing={10}/>
                                <Layout.OneColumn spacing={0}>
                                    <BankAccountForm name={`${name}.legalPerson.bankAccount`} formName={`${name}.legalPerson`} isRequired={true}/>
                                </Layout.OneColumn>
                            </Layout>
                        </Case>
                    </Switch>
                </FadeView>
            </Layout>
        );
    }
}