import _ from "lodash";

export default {
  INFO: {
    REF: "00-CAR_DAMAGE",
    CLAIM_TYPE: "00-CLAIM_TYPE",
    CAR_DAMAGE_TYPE: "01-CAR_DAMAGE_TYPE",
    THEFT_TYPE: "02-THEFT_TYPE",
    ACCIDENT_DATE: "03-ACCIDENT_DATE",
    ACCIDENT_TIME: "04-ACCIDENT_TIME",
    ACCIDENT_LOCATION: "05-ACCIDENT_LOCATION",
    VEHICLE_LOCATION: "20-VEHICLE_LOCATION",
    VEHICLE_LOCATION_DESCRIPTION: "21-VEHICLE_LOCATION_DESCRIPTION",
    ACCIDENT_DESCRIPTION: "06-ACCIDENT_DESCRIPTION",
    VEHICLE_TYPE: "07-VEHICLE_TYPE",
    VEHICLE_DAMAGE: {
      CURRENT: "08-VEHICLE_DAMAGE_CURRENT",
      CURRENT_DESCRIPTION: "09-VEHICLE_DAMAGE_CURRENT_DESCRIPTION",
      HAS_PREVIOUS: "10-VEHICLE_DAMAGE_HAS_PREVIOUS",
      PREVIOUS: "11-VEHICLE_DAMAGE_PREVIOUS",
      PREVIOUS_DESCRIPTION: "12-VEHICLE_DAMAGE_PREVIOUS_DESCRIPTION",
    },
    PARKED: "14-PARKED",
    PARTICIPANTS_NUMBER: {
      MULTIPLE_PARTICIPANTS: "15-PARTICIPANTS_NUMBER_MULTIPLE_PARTICIPANTS",
      LENGTH: "16-PARTICIPANTS_NUMBER_LENGTH",
    },
    DRAWING_INTRO: "17-DRAWING_INTRO",
    DRAWING: "18-DRAWING",
    EVENT_DESCRIPTION: "19-EVENT_DESCRIPTION",
  },
  PARTICIPANTS: {
    REF: "01-PARTICIPANTS",
    PARTICIPANT: {
      REF: (index) => `${_.padStart(index, 2, 0)}-PARTICIPANT`,
      OWN_BIKE_INFO: {
        REF: "00-OWN_BIKE_INFO",
        DESCRIPTION: "00-DESCRIPTION",
        PURCHASE_YEAR: "01-OWN_BIKE_INFO_PURCHASE_YEAR",
        CURRENT_VALUE: "02-OWN_BIKE_INFO_CURRENT_VALUE",
        PHOTOS: "03-OWN_BIKE_INFO_PHOTOS",
      },
      COMMON_VEHICLE_INFO: {
        REF: "01-COMMON_VEHICLE_INFO",
        REGISTERED_IN_SLOVENIA: {
          REF: "00-REGISTERED_IN_SLOVENIA",
          REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
          MANUFACTURER: "01-MANUFACTURER",
          MODEL: "02-MODEL",
          INSURANCE_COMPANY: "03-INSURANCE_COMPANY",
          POLICY_NUMBER: "04-POLICY_NUMBER",
          CHASSIS_NUMBER: "05-CHASSIS_NUMBER",
        },
        REGISTERED_IN_SLOVENIA_ALTERNATIVE: {
          REF: "01-REGISTERED_IN_SLOVENIA_ALTERNATIVE",
          REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
          VEHICLE_MARK: "01-VEHICLE_MARK",
          VEHICLE_TYPE: "02-VEHICLE_TYPE",
        },
        REGISTERED_ABROAD: {
          REF: "02-REGISTERED_ABROAD",
          COUNTRY: "00-COUNTRY",
          REGISTRATION_NUMBER: "01-REGISTRATION_NUMBER",
          VEHICLE_MARK: "02-VEHICLE_MARK",
          VEHICLE_TYPE: "03-VEHICLE_TYPE",
          INSURANCE_COMPANY: "04-INSURANCE_COMPANY",
        },
        COMPANY_VEHICLE: {
          REF: "03-COMPANY_VEHICLE",
          VEHICLE_CHASSIS_NUMBER: "00-VEHICLE_CHASSIS_NUMBER",
          VEHICLE_MARK: "01-VEHICLE_MARK",
        },
        NOT_REGISTERED: {
          REF: "04-NOT_REGISTERED",
          DESCRIPTION: "00-DESCRIPTION",
        },
      },
      OWN_VEHICLE_INFO_WAS_DRIVER: {
        REF: "02-OWN_VEHICLE_INFO_WAS_DRIVER",
        WAS_DRIVER: "00-WAS_DRIVER",
        NAME: "01-NAME",
        SURNAME: "02-SURNAME",
        BIRTH_DATE: "03-BIRTH_DATE",
        SEX: "04-SEX",
        COUNTRY: "05-COUNTRY",
        CITY_AND_ZIP: "06-CITY_AND_ZIP",
        STREET_AND_NUMBER: "07-STREET_AND_NUMBER",
        TELEPHONE_NUMBER: "08-TELEPHONE_NUMBER",
        LICENCE_NUMBER: "09-LICENCE_NUMBER",
      },
      OWN_VEHICLE_INFO_DRIVER_HURT: {
        REF: "03-OWN_VEHICLE_INFO_DRIVER_HURT",
        WAS_DRIVER: "00-WAS_DRIVER",
        WAS_NOT_DRIVER: "01-WAS_NOT_DRIVER",
      },
      OWN_VEHICLE_INFO_REPORTED_POLICE: {
        REF: "04-OWN_VEHICLE_INFO_REPORTED_POLICE",
        REPORTED_POLICE: "00-REPORTED_POLICE",
        REPORTED_POLICE_STATION: "01-REPORTED_POLICE_STATION",
      },
      OWN_VEHICLE_INFO_PASSENGERS: {
        REF: "05-OWN_VEHICLE_INFO_PASSENGERS",
        EXISTS: "00-EXISTS",
        PASSENGERS: {
          REF: "01-PASSENGERS",
          PASSENGER: (index) => `${_.padStart(index, 2, 0)}-PASSENGER`,
        },
      },
      OWN_VEHICLE_INFO_PASSENGERS_HURT: "06-OWN_VEHICLE_INFO_PASSENGERS_HURT",
      OTHER_PARTICIPANTS_INFO_PARTICIPANT: "07-OTHER_PARTICIPANTS_INFO_PARTICIPANT",
      OTHER_PARTICIPANTS_INFO_INJURY_TYPE: "08-OTHER_PARTICIPANTS_INFO_INJURY_TYPE",
      OTHER_PARTICIPANTS_INFO_PASSENGERS: "09-OTHER_PARTICIPANTS_INFO_PASSENGERS",
      OTHER_PARTICIPANTS_INFO_PEDESTRIAN: {
        REF: "10-OTHER_PARTICIPANTS_INFO_PEDESTRIAN",
        NAME: "00-NAME",
        SURNAME: "01-SURNAME",
        BIRTH_DATE: "02-BIRTH_DATE",
        SEX: "03-SEX",
        ADDRESS: "04-ADDRESS",
        HEAVY_INJURY_TYPE: "05-HEAVY_INJURY_TYPE"
      },
    },
  },
  RESPONSIBLE_PERSON: {
    REF: "02-RESPONSIBLE_PERSON",
    RESPONSIBLE_PERSON: "00-RESPONSIBLE_PERSON",
    PERSON: {
      REF: (index) => `${_.padStart(index, 2, 0)}-PERSON`,
      REGISTERED_IN_SLOVENIA: {
        REF: "00-REGISTERED_IN_SLOVENIA",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        MANUFACTURER: "01-MANUFACTURER",
        MODEL: "02-MODEL",
        INSURANCE_COMPANY: "03-INSURANCE_COMPANY",
        POLICY_NUMBER: "04-POLICY_NUMBER",
        CHASSIS_NUMBER: "05-CHASSIS_NUMBER",
      },
      REGISTERED_IN_SLOVENIA_ALTERNATIVE: {
        REF: "01-REGISTERED_IN_SLOVENIA_ALTERNATIVE",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        VEHICLE_MARK: "01-VEHICLE_MARK",
        VEHICLE_TYPE: "02-VEHICLE_TYPE",
      },
      REGISTERED_ABROAD: {
        REF: "02-REGISTERED_ABROAD",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        VEHICLE_MARK: "01-VEHICLE_MARK",
        VEHICLE_TYPE: "02-VEHICLE_TYPE",
      },
      COMPANY_VEHICLE: {
        REF: "03-COMPANY_VEHICLE",
        VEHICLE_TYPE: "00-VEHICLE_TYPE",
        VEHICLE_CHASSIS_NUMBER: "01-VEHICLE_CHASSIS_NUMBER",
        VEHICLE_MARK: "02-VEHICLE_MARK",
      },
      NOT_REGISTERED: {
        REF: "04-NOT_REGISTERED",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        DESCRIPTION: "01-DESCRIPTION",
      },
      PEDESTRIAN: {
        REF: "05-PEDESTRIAN",
        NAME: "00-NAME",
        SURNAME: "01-SURNAME",
      }
    },
  },
  PHOTOS: "03-PHOTOS",
  DOCUMENTS: "04-DOCUMENTS",
  GENERAL_DOCUMENTS: "05-GENERAL_DOCUMENTS",
  INSPECTION_AND_REPAIR: {
    REF: "06-INSPECTION_AND_REPAIR",
    MAPS_INTRO: "00-MAPS_INTRO",
    NAME: "01-NAME",
    LOCATION: "02-LOCATION",
  },
  APPLICANT_DATA: {
    REF: "07-APPLICANT_DATA",
    INSURANCE_OWNER: "00-INSURANCE_OWNER",
    NAME: "01-NAME",
    SURNAME: "02-SURNAME",
    SEX: "03-SEX",
    BIRTH_DATE: "04-BIRTH_DATE",
    COUNTRY: "05-COUNTRY",
    CITY_AND_ZIP: "06-CITY_AND_ZIP",
    STREET_AND_NUMBER: "07-STREET_AND_NUMBER",
    TELEPHONE_NUMBER: "08-TELEPHONE_NUMBER",
    TAX_NUMBER: "09-TAX_NUMBER",
    LICENCE_NUMBER: "10-LICENCE_NUMBER",
    EMAIL: "11-EMAIL",
  },
  USER: {
    REF: "08-USER",
    NAME: "00-NAME",
    SURNAME: "01-SURNAME",
    SEX: "02-SEX",
    BIRTH_DATE: "03-BIRTH_DATE",
    COUNTRY: "04-COUNTRY",
    CITY_AND_ZIP: "05-CITY_AND_ZIP",
    STREET_AND_NUMBER: "06-STREET_AND_NUMBER",
    TELEPHONE_NUMBER: "07-TELEPHONE_NUMBER",
    EMAIL: "08-EMAIL",
    TAX_NUMBER: "09-TAX_NUMBER",
    LICENCE_NUMBER: "10-LICENCE_NUMBER",
    POLICY_NUMBER: "11-POLICY_NUMBER",
    RELATIONSHIP: "12-RELATIONSHIP",
    BANK_ACCOUNTS: {
      REF: "13-BANK_ACCOUNTS",
    },
    VEHICLE_OWNER: "14-VEHICLE_OWNER",
    IS_BENEFICIARY: "15-IS_BENEFICIARY",
    CONTACT_PERSON_NAME: "16-CONTACT_PERSON_NAME"
  },
  BANK_ACCOUNTS: {
    REF: "10-BANK_ACCOUNTS",
    BANK_ACCOUNT_REPAIR_SHOP: {
      REF: "00-BANK_ACCOUNT_REPAIR_SHOP",
      NAME: "00-NAME",
      ADDRESS: "01-ADDRESS",
    },
    BANK_ACCOUNT_OTHER_PERSON: {
      REF: "10-BANK_ACCOUNT_OTHER_PERSON",
      NAME: "00-NAME",
      SURNAME: "01-SURNAME",
      COUNTRY: "02-COUNTRY",
      STREET_NAME: "03-STREET_NAME",
      STREET_NUMBER: "04-STREET_NUMBER",
      CITY: "05-CITY",
      TAX_NUMBER: "06-TAX_NUMBER",
      BIRTH_DATE: "07-TAX_NUMBER",
      TELEPHONE_NUMBER: "08-TELEPHONE_NUMBER",
      EMAIL: "09-EMAIL",
      BANK_ACCOUNT: {
        REF: "10-BANK_ACCOUNT",
      },
      LEGAL_PERSON: {
        NAME: "00-NAME",
        COUNTRY: "02-COUNTRY",
        STREET_NAME: "03-STREET_NAME",
        STREET_NUMBER: "04-STREET_NUMBER",
        CITY: "05-CITY",
        TAX_NUMBER: "06-TAX_NUMBER",
        TELEPHONE_NUMBER: "08-TELEPHONE_NUMBER",
        EMAIL: "09-EMAIL",
        BANK_ACCOUNT: {
          REF: "10-BANK_ACCOUNT",
        }
      }
    },
    BANK_ACCOUNT_REGULAR: {
      REF: "02-BANK_ACCOUNT_REGULAR",
    },
    BANK_ACCOUNT_DONT_KNOW: {
      REF: "03-BANK_ACCOUNT_DONT_KNOW",
    },
  },
  HAS_CASCO: {
    REF: "11-HAS_CASCO",
    HAS_CASCO: "00-HAS_CASCO",
  },
  HOME_INFO: {
    REF: "00-HOME_INFO",
    HOME_DAMAGE_TYPE: "01-HOME_DAMAGE_TYPE",
    HOME_BURGLARY_WHO: "02-HOME_BURGLARY_WHO",
    HOME_BURGLARY_PROPERTY_TYPE: "03-HOME_BURGLARY_PROPERTY_TYPE",
    HOME_BURGLARY_VIOLENT: "04-HOME_BURGLARY_VIOLENT",
    HOME_BURGLARY_VIOLENT_DESCRIPTION: "05-HOME_BURGLARY_VIOLENT_DESCRIPTION",
    HOME_DAMAGED_GOODS: "06-HOME_DAMAGED_GOODS",
    ACCIDENT_DATE: "07-ACCIDENT_DATE",
    ACCIDENT_TIME: "08-ACCIDENT_TIME",
    ACCIDENT_LOCATION: "09-ACCIDENT_LOCATION",
    HOME_VEHICLE_INFO: {
      REF: "10-HOME_VEHICLE_INFO",
      REGISTERED_IN_SLOVENIA: {
        REF: "00-REGISTERED_IN_SLOVENIA",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        MANUFACTURER: "01-MANUFACTURER",
        MODEL: "02-MODEL",
        INSURANCE_COMPANY: "03-INSURANCE_COMPANY",
        POLICY_NUMBER: "04-POLICY_NUMBER",
        CHASSIS_NUMBER: "05-CHASSIS_NUMBER",
      },
      REGISTERED_IN_SLOVENIA_ALTERNATIVE: {
        REF: "01-REGISTERED_IN_SLOVENIA_ALTERNATIVE",
        REGISTRATION_NUMBER: "00-REGISTRATION_NUMBER",
        VEHICLE_MARK: "01-VEHICLE_MARK",
        VEHICLE_TYPE: "02-VEHICLE_TYPE",
      },
      REGISTERED_ABROAD: {
        REF: "02-REGISTERED_ABROAD",
        COUNTRY: "00-COUNTRY",
        REGISTRATION_NUMBER: "01-REGISTRATION_NUMBER",
        VEHICLE_MARK: "02-VEHICLE_MARK",
        VEHICLE_TYPE: "03-VEHICLE_TYPE",
        INSURANCE_COMPANY: "03-INSURANCE_COMPANY",
      },
      COMPANY_VEHICLE: {
        REF: "03-COMPANY_VEHICLE",
        VEHICLE_TYPE: "00-VEHICLE_TYPE",
        VEHICLE_CHASSIS_NUMBER: "01-VEHICLE_CHASSIS_NUMBER",
        VEHICLE_MARK: "02-VEHICLE_MARK",
      },
      NOT_REGISTERED: {
        REF: "04-NOT_REGISTERED",
        DESCRIPTION: "00-DESCRIPTION",
      },
      UNKNOWN: "05-UNKNOWN",
    },
    HOME_REPORTED_POLICE: "11-HOME_REPORTED_POLICE",
    HOME_REPORTED_POLICE_POLICE_STATION: "12-HOME_REPORTED_POLICE_POLICE_STATION",
    HOME_CALCULATION_TYPE: "13-HOME_CALCULATION_TYPE",
  },
  HOME_DAMAGED_GOODS_INFO: {
    REF: "01-HOME_DAMAGED_GOODS_INFO",
    DESCRIPTION: "00-DESCRIPTION",
    DAMAGE_ESTIMATE: "01-DAMAGE_ESTIMATE",
    DAMAGED_GOODS_INFO: {
      REF: "02-DAMAGED_GOODS_INFO",
      DAMAGED_GOOD_INFO: {
        REF: (index) => `${_.padStart(index, 2, 0)}-DAMAGED_GOODS`,
        NAME: "01-NAME",
        PURCHASE_YEAR: "02-PURCHASE_YEAR",
        PURCHASE_PRICE: "03-PURCHASE_PRICE",
      }
    },
  },
  //Photo 3
  //Documents 4
  HOME_WITNESSES:{
    REF: "05-HOME_WITNESSES",
    WITNESS: (index) => `${_.padStart(index, 2, 0)}-HOME_WITNESSES`,
  },
  HOME_RESPONSIBLE_PERSON: {
    REF: "06-HOME_RESPONSIBLE_PERSON",
    SOMEONE_ELSE_RESPONSIBLE: "00-SOMEONE_ELSE_RESPONSIBLE",
    NAME_AND_SURNAME: "01-NAME_AND_SURNAME",
    NAME: "02-NAME",
    SEX: "03-SEX",
    BIRTH_DATE: "04-BIRTH_DATE",
    STREET_AND_NUMBER: "05-STREET_AND_NUMBER",
    CITY: "06-CITY",
    ZIP: "07-ZIP",
    COUNTRY: "08-COUNTRY",
    POLICY_NUMBER: "09-POLICY_NUMBER",
    FROM_POLICY: "10-FROM_POLICY",
  },
  PERSON_INFO: {
    REF: "00-PERSON_INFO",
    MEDICAL_CARE_TYPE: "01-MEDICAL_CARE_TYPE",
    MEDICAL_CARE_TYPE_DESCRIPTION: "02-MEDICAL_CARE_TYPE_DESCRIPTION",
    SUFFERED_MEDICAL_COSTS: "03-SUFFERED_MEDICAL_COSTS",
    MEDICAL_OPERATION_NEEDED: "04-MEDICAL_OPERATION_NEEDED",
    MEDICAL_PROBLEM_DESCRIPTION: "05-MEDICAL_PROBLEM_DESCRIPTION",
    PAST_MEDICAL_CONDITIONS: "06-PAST_MEDICAL_CONDITIONS",
    ACCIDENT_DATE: "07-ACCIDENT_DATE",
    ACCIDENT_TIME: "08-ACCIDENT_TIME",
    SICKNESS_DESCRIPTION: "09-SICKNESS_DESCRIPTION",
    ACCIDENT_LOCATION: "10-ACCIDENT_LOCATION",
    ACCIDENT_DESCRIPTION: "11-ACCIDENT_DESCRIPTION",
    PLACE_OF_DEATH: "12-PLACE_OF_DEATH",
    PERSON_DAMAGE: "13-PERSON_DAMAGE",
    EVENT_DESCRIPTION: "14-EVENT_DESCRIPTION",
    ACCIDENT_OCCURRENCE_TYPE: "15-ACCIDENT_OCCURRENCE_TYPE",
    CAUSE_OF_DEATH: "16-CAUSE_OF_DEATH",
    PERSON_REPORTED_POLICE: "17-PERSON_REPORTED_POLICE",
    PERSON_REPORTED_POLICE_POLICE_STATION: "18-PERSON_REPORTED_POLICE_POLICE_STATION",
    WITNESSES_PRESENTS: "19-WITNESSES_PRESENT",
    HAD_OPERATION: "20-HAD_OPERATION",
    INJURED_PERSON_WORKING_STATUS: "21-INJURED_PERSON_WORKING_STATUS",
    PERSON_INSURANCE: "22-PERSON_INSURANCE",
    INSURANCE_OWNERS: "23-INSURANCE_OWNERS",
    PERSON_RESPONSIBLE: "24-PERSON_RESPONSIBLE",
    CLAIM_COMPENSATION_VEHICLE: "25-CLAIM_COMPENSATION_VEHICLE",
    CLAIM_COMPENSATION_PERSON: "26-CLAIM_COMPENSATION_PERSON",
    RESPONSIBLE_PERSON_TYPE: {
      REF: "27-RESPONSIBLE_PERSON_TYPE",
      NATURAL_PERSON: (index) => `${_.padStart(index, 2, 0)}-NATURAL_PERSONS`,
      LEGAL_PERSON: "00-LEGAL_PERSON"
    },
    RESPONSIBLE_PERSON_RELATION: "28-RESPONSIBLE_PERSON_RELATION",
    SUFFERED_EVENT_TYPE: "29-SUFFERED_EVENT_TYPE",
    DAMAGE_DESCRIPTION: "30-DAMAGE_DESCRIPTION",
    DAMAGE_DESCRIPTION_AMOUNT: "31-DAMAGE_DESCRIPTION_AMOUNT",
    DAMAGES_CLAIM_FILED: "32-DAMAGES_CLAIM_FILED",
    ACCIDENT_INVOLVED: "33-ACCIDENT_INVOLVED",
    PARTICIPANTS_NUMBER: {
      MULTIPLE_PARTICIPANTS: "34-PARTICIPANTS_NUMBER_MULTIPLE_PARTICIPANTS",
      LENGTH: "35-PARTICIPANTS_NUMBER_LENGTH",
    },
    REFERRAL_NUMBERS: "36-REFERRAL_NUMBERS",
    REFERRAL_NUMBERS_CARD: "37-REFERRAL_NUMBERS_CARD",
    LIFE_INSURANCE_CLAIM: "38-LIFE_INSURANCE_CLAIM",
    LIFE_INSURANCE_CLAIM_ZDA: "39-LIFE_INSURANCE_CLAIM_ZDA",
    LIFE_INSURANCE_CLAIM_SLOVENIA: "40-LIFE_INSURANCE_CLAIM_SLOVENIA",
    LIFE_INSURANCE_CLAIM_TAX: "41-LIFE_INSURANCE_CLAIM_TAX"
  },
  PERSON_WITNESSES: {
    REF: "05-PERSON_WITNESSES",
    WITNESS: (index) => `${_.padStart(index, 2, 0)}-PERSON_WITNESSES`,
  },
  DECEASED_PERSON_INFORMATION: {
    REF: "06-DECEASED_PERSON_INFORMATION",
    NAME: "00-NAME",
    SURNAME: "01-SURNAME",
    SEX: "02-SEX",
    BIRTH_DATE: "03-BIRTH_DATE",
    COUNTRY: "04-COUNTRY",
    CITY_AND_ZIP: "05-CITY_AND_ZIP",
    STREET_AND_NUMBER: "06-STREET_AND_NUMBER",
    POLICY_NUMBER: "07-POLICY_NUMBER",
    TAX_NUMBER: "08-TAX_NUMBER",
  },
  BENEFICIARY_PERSONS: {
    REF: "09-BENEFICIARY_PERSONS",
    BENEFICIARY_PERSON: (index) => `${_.padStart(index, 2, 0)}-BENEFICIARY_PERSONS`,
  },
  //UserInfo 7
  //BankAcc 8
  COMMON: {
    BANK_ACCOUNT: {
      REF: (index) => `${_.padStart(index, 2, 0)}-BANK_ACCOUNT`,
      NAME: "00-NAME",
      SWIFT: "01-SWIFT",
      BANK_NAME: "02-BANK_NAME",
    },
  }
}
