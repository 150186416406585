import fontsConstants from "constants/font";

export default {
  FLOOD_WATER: {
    NAME: "floodWater",
    ICON: fontsConstants.TOCA_POPLAVA_STEKLO_ICON.FLOOD_WATER,
    CHILDREN: {
      BURST: {
        NAME: "burst",
      },
      OPEN_FAUCET: {
        NAME: "openFaucet",
      },
      CLOGGING: {
        NAME: "clogging",
      },
      DURABILITY: {
        NAME: "durability",
      },
      DEVICE_FAILURE: {
        NAME: "deviceFailure",
      },
      INTRUSION_OF_WATER: {
        NAME: "intrusionOfWater",
      },
      OTHER_WATER: {
        NAME: "otherWater",
      },
    },
  },
  FIRE: {
    NAME: "fire",
    ICON: fontsConstants.ASDOM_ICON.FIRE,
  },
  DESTRUCTION: {
    NAME: "destruction",
    ICON: fontsConstants.ASDOM_ICON.DESTRUCTION,
  },
  BURGLARY: {
    NAME: "burglary",
    ICON: fontsConstants.ASDOM_ICON.BURGLARY,
  },
  NATURAL_DISASTER: {
    NAME: "naturalDisaster",
    ICON: fontsConstants.ASDOM_ICON.NATURAL_DISASTER,
    CHILDREN: {
      STORM: {
        NAME: "storm",
      },
      LIGHTNING: {
        NAME: "lightning",
        CHILDREN: {
          LIGHTNING_DIRECT: {
            NAME: "lightningDirect",
          },
          LIGHTNING_INDIRECT: {
            NAME: "lightningIndirect",
          },
        },
      },
      HAIL: {
        NAME: "hail",
      },
      FLOOD: {
        NAME: "flood",
      },
      STORM_WATER_INTRUSION: {
        NAME: "stormWaterIntrusion",
      },
      SNOW_WEIGHT: {
        NAME: "snowWeight",
      },
      SLEET: {
        NAME: "sleet",
      },
      LAND_SLIDE: {
        NAME: "landSlide",
      },
      TREE_FALL: {
        NAME: "treeFall",
      },
    },
  },
  BREAKDOWN: {
    NAME: "breakdown",
    ICON: fontsConstants.ASDOM_ICON.BREAKDOWN,
  },
  DAMAGE_SOMEONE_ELSE_RESPONSIBLE: {
    NAME: "damageSomeoneElseResponsible",
    ICON: fontsConstants.ASDOM_ICON.DAMAGE_SOMEONE_ELSE_RESPONSIBLE,
  },
  VEHICLE_IMPACT: {
    NAME: "vehicleImpact",
    ICON: fontsConstants.ASDOM_ICON.VEHICLE_IMPACT,
  },
  EARTHQUAKE: {
    NAME: "earthquake",
    ICON: fontsConstants.ASDOM_ICON.EARTHQUAKE,
  },
  OTHER: {
    NAME: "other",
    ICON: fontsConstants.ASDOM_ICON.OTHER,
  },
}