import _, {isEmpty} from "lodash";
import {difference, filter, findIndex, isArray, isEqual, isUndefined, some} from "underscore";

import bankAccountTypeConstants from "constants/bankAccountTypes";
import booleanTypesConstants from "constants/booleanTypes";
import carDamageTypeConstants from "constants/carDamageTypes";
import flowConstants from "constants/flow";
import repairServiceTypes from "constants/repairServiceTypes";
import responsiblePersonTypeConstants from "constants/responsiblePerson";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import vehicleTypeConstants from "constants/vehicleTypes";
import {calculateStepWithConfiguration} from "selectors/flow/getSteps";
import {getFilterData} from "selectors/flow";
import {arraysContainSameElements} from "selectors/flow/preCalculateFormState/preCalculateUtilityFunctions";
import medicalCareDescriptionTypes from "../../constants/medicalCareDescriptionTypes";

export function determineFormsForReset(flowState) {
  const result = [];

  //step 9
  if (shouldResetVehicleDamage(flowState)) {
    result.push({name: flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME})
  }

  //step 11
  if (shouldResetParked(flowState)) {
    result.push({name: flowConstants.steps.PARKED.NAME})
  }

  //step 14
  if (shouldResetDrawing(flowState)) {
    result.push({name: flowConstants.steps.DRAWING.NAME})
  }

  // step 16-18
  result.push(...shouldResetParticipantsInfo(flowState));

  // step 20
  if (shouldResetOwnVehicleInfoDriverHurt(flowState)) {
    result.push({name: flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME});
  }

  // step 24
  result.push(...shouldResetOtherParticipantsInfo(flowState));

  //step 28
  if (shouldResetResponsiblePerson(flowState)) {
    result.push({name: flowConstants.steps.RESPONSIBLE_PERSON.NAME});
  }

  //step 29
  result.push(...shouldResetVehicleDamageInspectionPoint(flowState));

  if (shouldResetUserLogin(flowState)) {
    result.push({name: flowConstants.steps.USER_LOGIN.NAME})
  }

  if (shouldResetHasCasco(flowState)) {
    result.push({name: flowConstants.steps.HAS_CASCO.NAME})
  }

  if (shouldResetUserIdentificationType(flowState)) {
    result.push({name: flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME})
  }

  if (shouldResetApplicantData(flowState)) {
    result.push({name: flowConstants.steps.APPLICANT_DATA.NAME})
  }

  if (shouldResetUserIdentification(flowState)) {
    result.push({name: flowConstants.steps.USER_IDENTIFICATION.NAME})
  }

  //step 34
  if (shouldResetBankAccounts(flowState)) {
    result.push({name: flowConstants.steps.BANK_ACCOUNTS.NAME});
  }

  // homeStep 4
  if (shouldResetDamagedGoods(flowState)) {
    result.push({name: flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME});
  }
  // homeStep 5
  if (shouldResetDamagedGoodsInfo(flowState)) {
    result.push({name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME});
  }

  if (shouldResetPersonDocuments(flowState)) {
    result.push({name: flowConstants.personSteps.PERSON_DOCUMENTS.NAME});
  }

  if(shouldResetBeneficiaryPersons(flowState)) {
    result.push({name: flowConstants.personSteps.BENEFICIARY_PERSONS.NAME});
  }

  return result;
}

function shouldResetVehicleDamage(flowState) {
  const step = flowState.step;

  const carDamageTypeForm = flowState.forms[flowConstants.steps.CAR_DAMAGE_TYPE.NAME] || {};
  const vehicleTypeForm = flowState.forms[flowConstants.steps.VEHICLE_TYPE.NAME] || {};

  const bikeAsVehicle = vehicleTypeForm.finalVehicleType === vehicleTypeConstants.BIKE;
  const carAccidentNotCarDamageType = carDamageTypeForm.carDamageType !== carDamageTypeConstants.CAR_ACCIDENT;

  if (step.name === flowConstants.steps.CAR_DAMAGE_TYPE.NAME) {
    if (bikeAsVehicle && carAccidentNotCarDamageType) {
      return true;
    }
  }

  if (step.name === flowConstants.steps.VEHICLE_TYPE.NAME) {
    const oldVehicleTypeForm = flowState.previousForms[flowConstants.steps.VEHICLE_TYPE.NAME] || {};
    return vehicleTypeForm.finalVehicleType !== oldVehicleTypeForm.finalVehicleType;
  }

  return false;
}

function shouldResetParked(flowState) {
  const step = flowState.step;

  if (isUndefined(flowState.previousForms)) {
    return false;
  }

  const carDamageTypeForm = flowState.forms[flowConstants.steps.CAR_DAMAGE_TYPE.NAME] || {};

  const oldCarDamageTypeForm = flowState.previousForms[flowConstants.steps.CAR_DAMAGE_TYPE.NAME] || {};

  const isParkedForms = [carDamageTypeConstants.THEFT, carDamageTypeConstants.MALICIOUS_ACTS, carDamageTypeConstants.KEY_LOSS, carDamageTypeConstants.DAMAGE_PARKING_LOT];

  if (step.name === flowConstants.steps.CAR_DAMAGE_TYPE.NAME) {
    return isParkedForms.indexOf(oldCarDamageTypeForm.carDamageType) === -1
      && isParkedForms.indexOf(carDamageTypeForm.carDamageType) === -1;
  }
}

function shouldResetDrawing(flowState) {
  const step = flowState.step;

  const drawingIntroForm = flowState.forms[flowConstants.steps.DRAWING_INTRO.NAME] || {};
  const drawingForm = flowState.forms[flowConstants.steps.DRAWING.NAME] || {};

  if (step.name === flowConstants.steps.DRAWING_INTRO.NAME) {
    if (drawingIntroForm.wantDrawing === booleanTypesConstants.YES && isUndefined(drawingForm)) {
      return true;
    }
  }
  return false;
}

function shouldResetParticipantsInfo(flowState) {
  let result = [];

  const step = flowState.step;

  const carDamageTypeForm = flowState.forms[flowConstants.steps.CAR_DAMAGE_TYPE.NAME] || {};
  const vehicleTypeForm = flowState.forms[flowConstants.steps.VEHICLE_TYPE.NAME] || {};
  const participantsNumberForm = flowState.forms[flowConstants.steps.PARTICIPANTS_NUMBER.NAME] || {};
  const participantsInfoForm = flowState.forms[flowConstants.steps.PARTICIPANTS_INFO.NAME] || [];
  const previousVehicleType = _.at(flowState, "previousForms.vehicleType.finalVehicleType")[0];
  const isMechanicalDamage = carDamageTypeForm.carDamageType === carDamageTypeConstants.MECHANICAL_DAMAGE;
  const isMotorcycle = vehicleTypeForm.vehicleType === vehicleTypeConstants.MOTORCYCLE;
  const isCompanyVehicle = !isUndefined(participantsInfoForm[0]) &&
    !isUndefined(participantsInfoForm[0].participantInfo) &&
    participantsInfoForm[0].participantInfo.inputType === vehicleInfoInputTypesConstants.COMPANY_VEHICLE;
  const isNewVehicle = vehicleTypeForm.finalVehicleType !== previousVehicleType;

  if ([flowConstants.steps.CAR_DAMAGE_TYPE.NAME, flowConstants.steps.VEHICLE_TYPE.NAME].indexOf(step.name) !== -1) {
    if ((isMotorcycle && isCompanyVehicle) || isNewVehicle || isMechanicalDamage) {
      result.push({name: flowConstants.steps.PARTICIPANTS_INFO.NAME, index: 0})
    }
  }

  if (step.name === flowConstants.steps.PARTICIPANTS_NUMBER.NAME) {
    participantsNumberForm.finalParticipants.forEach(participantNumber => {
      const participantInfo = participantsInfoForm[participantNumber.index];

      if (!isUndefined(participantInfo) && participantInfo.info.isVehicle !== participantNumber.isVehicle) {
        result.push({name: flowConstants.steps.PARTICIPANTS_INFO.NAME, index: participantNumber.index});
      }
    });
  }

  return result;
}

function shouldResetOwnVehicleInfoDriverHurt(flowState) {
  const step = flowState.step;

  const ownVehicleInfoWasDriverForm = flowState.forms[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
  const ownVehicleInfoDriverHurtForm = flowState.forms[flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME] || {};

  const ownVehicleWasDriver = ownVehicleInfoWasDriverForm.wasDriver === booleanTypesConstants.YES;
  const driverHurtUndefined = ownVehicleWasDriver
    ? isUndefined(_.at(ownVehicleInfoDriverHurtForm, "driverHurt.wasDriver")[0])
    : isUndefined(_.at(ownVehicleInfoDriverHurtForm, "driverHurt.wasNotDriver")[0]);

  if (step.name === flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME) {
    if (driverHurtUndefined) {
      return true;
    }
  }

  return false;
}

function shouldResetOtherParticipantsInfo(flowState) {
  let result = [];

  const step = flowState.step;

  const otherParticipantsInfoForm = flowState.forms[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME] || [];

  if ([flowConstants.steps.CAR_DAMAGE_TYPE.NAME, flowConstants.steps.PARKED.NAME].indexOf(step.name) !== -1) {
    otherParticipantsInfoForm.forEach((otherParticipantInfo, i) => {
      if (isUndefined(otherParticipantInfo)) {
        result.push({name: flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME, index: i});
      }
    });
  }

  return result;
}

function shouldResetResponsiblePerson(flowState) {
  const step = flowState.step;

  const participantsNumberForm = flowState.forms[flowConstants.steps.PARTICIPANTS_NUMBER.NAME] || {};
  const participantsInfoForm = flowState.forms[flowConstants.steps.PARTICIPANTS_INFO.NAME] || [];
  const responsiblePersonForm = flowState.forms[flowConstants.steps.RESPONSIBLE_PERSON.NAME] || {};

  const responsiblePerson = _.at(responsiblePersonForm, "responsiblePerson")[0];

  let shouldResetResponsiblePerson = false;
  const participantsAsResponsiblePerson = isArray(responsiblePerson);

  if (step.name === flowConstants.steps.PARTICIPANTS_NUMBER.NAME) {

    participantsNumberForm.finalParticipants.forEach(participantNumber => {
      const participantInfo = participantsInfoForm[participantNumber.index];

      if (!isUndefined(participantInfo) && participantInfo.info.isVehicle !== participantNumber.isVehicle) {
        shouldResetResponsiblePerson |= true;
      } else if (isUndefined(participantInfo)) {
        shouldResetResponsiblePerson |= true;
      }
    });

    if (participantsAsResponsiblePerson) {
      shouldResetResponsiblePerson |= some(
        responsiblePersonForm.responsiblePerson || [],
        person => findIndex(participantsNumberForm.finalParticipants, participant => participant.index === person) === -1
      );
    }
  }

  if (step.name === flowConstants.steps.PARTICIPANTS_INFO.NAME) {

    if (participantsAsResponsiblePerson) {
      shouldResetResponsiblePerson |= some(
        responsiblePersonForm.responsiblePerson || [],
        person => findIndex(participantsNumberForm.finalParticipants, participant => participant.index === person && !participant.skip) === -1
      );

      const participants = filter(participantsNumberForm.participants, participant => participant.skip !== true);
      shouldResetResponsiblePerson = participants.length === 0 ? false : shouldResetResponsiblePerson;
    }
  }

  return shouldResetResponsiblePerson;
}

function shouldResetVehicleDamageInspectionPoint(flowState) {
  const result = [];
  const step = flowState.step;
  const mapsIntroForm = flowState.forms[flowConstants.steps.MAPS_INTRO.NAME] || {};
  const vehicleDamageInspectionPointForm = flowState.forms[flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME] || {};
  const repairServiceType = mapsIntroForm.repairServiceType;

  if (step.name === flowConstants.steps.MAPS_INTRO.NAME) {
    if ([repairServiceTypes.VEHICLE_DAMAGE_INSPECTION_POINT, repairServiceTypes.BUSINESS_UNIT].includes(repairServiceType) && !vehicleDamageInspectionPointForm[repairServiceType]) {
      result.push({name: flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME, repairServiceType})
    }
  }

  return result;
}

function shouldResetBankAccounts(flowState) {
  const step = flowState.step;

  const mapsIntroForm = flowState.forms[flowConstants.steps.MAPS_INTRO.NAME] || {};
  const vehicleDamageInspectionPointForm = flowState.forms[flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME] || {};
  const bankAccountsForm = flowState.forms[flowConstants.steps.BANK_ACCOUNTS.NAME] || {};
  const userIdentificationForm = flowState.forms[flowConstants.steps.USER_IDENTIFICATION.NAME] || {};

  let shouldResetBankAccounts = false;
  const repairShopAsBankAccount = bankAccountsForm.bankAccountType === bankAccountTypeConstants.REPAIR_SHOP;

  if (step.name === flowConstants.steps.MAPS_INTRO.NAME) {
    const {repairServiceType} = mapsIntroForm;
    const changedRepairServiceType = repairServiceType !== bankAccountsForm.repairServiceType;

    shouldResetBankAccounts |= changedRepairServiceType;
  }

  if (step.name === flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME) {
    const changedVehicleDamageInspectionPoint = !isEqual(vehicleDamageInspectionPointForm.vehicleDamageInspectionPoint, bankAccountsForm.vehicleDamageInspectionPoint);

    shouldResetBankAccounts |= changedVehicleDamageInspectionPoint;
  }

  if (step.name === flowConstants.steps.USER_IDENTIFICATION.NAME) {
    const previousUserIdentificationForm = _.at(flowState, "previousForms.userIdentification")[0] || {};

    const userIdentificationChanged = !isEqual(userIdentificationForm.finalUserInfo, previousUserIdentificationForm.finalUserInfo);
    const loggedIn = userIdentificationForm.userIdentificationType === booleanTypesConstants.YES;

    shouldResetBankAccounts |= userIdentificationChanged && !(loggedIn && repairShopAsBankAccount);
  }

  return shouldResetBankAccounts;
}

function shouldResetDamagedGoods(flowState) {
  const step = flowState.step;

  const homeDamageTypeForm = flowState.forms[flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME] || {};
  const homeDamagedGoodsForm = flowState.forms[flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME] || {};

  if (step.name === flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME) {
    return !arraysContainSameElements(homeDamageTypeForm.finalSelected, homeDamagedGoodsForm.damageType);
  }

  return false;
}

function shouldResetDamagedGoodsInfo(flowState) {
  const step = flowState.step;

  const homeDamagedGoodsForm = flowState.forms[flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME] || {};
  const homeDamagedGoodsInfoForm = flowState.forms[flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME] || {};

  if (step.name === flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME) {
    const addedItems = difference(homeDamagedGoodsForm.homeDamagedGoods.selected, homeDamagedGoodsInfoForm.selected || []).length > 0;
    const removedItems = difference(homeDamagedGoodsInfoForm.selected || [], homeDamagedGoodsForm.homeDamagedGoods.selected).length > 0;

    return addedItems || removedItems;
  }

  return false;
}

function shouldResetUserIdentificationType(flowState) {
  const step = flowState.step;
  if (step.name === flowConstants.steps.INSURANCE_OWNER.NAME) {
    return true;
  }
  return false;
}

function shouldResetUserLogin(flowState) {
  const step = flowState.step;

  const userIdentificationTypeForm = flowState.forms[flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME] || {};

  const previousUserIdentificationTypeForm = _.at(flowState, "previousForms.userIdentificationType")[0] || {};

  if (step.name === flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME) {
    const userIdentificationTypeChanged = userIdentificationTypeForm.userIdentificationType !== previousUserIdentificationTypeForm.userIdentificationType;

    return userIdentificationTypeChanged && userIdentificationTypeForm.userIdentificationType !== userIdentificationTypeConstants.MANUAL;
  }

  return false;

}

function shouldResetPersonDocuments(flowState) {
  const medicalCareTypeForm = flowState.forms[flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME] || {};
  const previousMedicalCareTypeForm = _.at(flowState, "previousForms.medicalCareType")[0] || {};

  if (medicalCareTypeForm.medicalCareTypeDescription !== previousMedicalCareTypeForm.medicalCareTypeDescription) {
    return true;
  }

  return false;
}

function shouldResetApplicantData(flowState) {

  const step = flowState.step;

  if (step.name === flowConstants.steps.INSURANCE_OWNER.NAME) {
    return true;
  }

  const ownVehicleInfoWasDriverForm = flowState.forms[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};

  const previousOwnVehicleInfoWasDriverForm = _.at(flowState, "previousForms.ownVehicleInfoWasDriver")[0] || {};

  if (step.name === flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME) {
    return ownVehicleInfoWasDriverForm.wasDriver !== previousOwnVehicleInfoWasDriverForm.wasDriver;
  }

  return false;
}

function shouldResetUserIdentification(flowState) {
  const step = flowState.step;

  const insuranceOwnerForm = flowState.forms[flowConstants.steps.INSURANCE_OWNER.NAME] || {};
  const userIdentificationTypeForm = flowState.forms[flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME] || {};
  const userLoginForm = flowState.forms[flowConstants.steps.USER_LOGIN.NAME] || {};
  const ownVehicleInfoWasDriverForm = flowState.forms[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};
  const medicalCareTypeForm = flowState.forms[flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME] || {};

  const previousInsuranceOwnerForm = _.at(flowState, "previousForms.insuranceOwner")[0] || {};
  const previousUserIdentificationTypeForm = _.at(flowState, "previousForms.userIdentificationType")[0] || {};
  const previousUserLoginForm = _.at(flowState, "previousForms.userLogin")[0] || {};
  const previousOwnVehicleInfoWasDriverForm = _.at(flowState, "previousForms.ownVehicleInfoWasDriver")[0] || {};

  if (medicalCareTypeForm.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH) {
    return true;
  }

  if (step.name === flowConstants.steps.INSURANCE_OWNER.NAME) {
    const ownerChanged = previousInsuranceOwnerForm.owner ? insuranceOwnerForm.owner !== previousInsuranceOwnerForm.owner : true;

    return ownerChanged;
  } else if (step.name === flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME) {
    const userIdentificationTypeChanged = previousUserIdentificationTypeForm.userIdentificationType ? userIdentificationTypeForm.userIdentificationType !== previousUserIdentificationTypeForm.userIdentificationType : true;
    const isGeneraliLogin = userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.GENERALI
    return userIdentificationTypeChanged || isGeneraliLogin;
  } else if (step.name === flowConstants.steps.USER_LOGIN.NAME) {

    const policyNumberChanged = previousUserLoginForm.policyNumber ? userLoginForm.policyNumber !== previousUserLoginForm.policyNumber : true;
    const taxNumberChanged = previousUserLoginForm.taxNumber ? userLoginForm.taxNumber !== previousUserLoginForm.taxNumber : true;

    if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      return policyNumberChanged || taxNumberChanged;
    } else if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.GENERALI) {
      return true;
    }
  } else if (step.name === flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME) {
    const wasDriverChanged = previousOwnVehicleInfoWasDriverForm.wasDriver ? ownVehicleInfoWasDriverForm.wasDriver !== previousOwnVehicleInfoWasDriverForm.wasDriver : true;

    return wasDriverChanged;
  }

  return false;
}

function shouldResetHasCasco(flowState) {
  const {forms, previousForms} = flowState;
  const shouldShowCascoCurrent = shouldShowCasco(forms);

  return shouldShowCascoCurrent !== 0 || shouldShowCascoCurrent !== shouldShowCasco(previousForms);
}

function shouldShowCasco(forms = {}) {
  const steps = flowConstants.steps;
  const responsiblePersonForm = forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const userIdentificationForm = forms[steps.USER_IDENTIFICATION.NAME] || {};
  const participantsInfoForm = forms[steps.PARTICIPANTS_INFO.NAME] || {};
  const participantsNumberForm = forms[steps.PARTICIPANTS_NUMBER.NAME] || {};
  const reporter = participantsInfoForm[0] || {};
  const registeredInSlovenia = !isUndefined(reporter.participantInfo) && reporter.participantInfo.inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA;

  const responsiblePersonKnown = participantsNumberForm.finalMultipleParticipants === booleanTypesConstants.NO
    || (responsiblePersonForm.responsiblePerson !== responsiblePersonTypeConstants.CANT_AGREE
      && responsiblePersonForm.responsiblePerson !== responsiblePersonTypeConstants.DONT_KNOW
      && (isArray(responsiblePersonForm.responsiblePerson) && responsiblePersonForm.responsiblePerson.indexOf(0) !== -1));

  if (userIdentificationForm.hasCasco) {
    if (responsiblePersonKnown) {
      const responsiblePerson = participantsInfoForm[0];
      if (registeredInSlovenia) {
        if (!responsiblePerson.participantInfo.vehicleData.generaliPolicy) {
          return 1;
        }
      } else {
        return 2;
      }
    } else if (participantsNumberForm.finalMultipleParticipants === booleanTypesConstants.YES) {
      return 3
    }
  }
  return 0;
}

function shouldResetBeneficiaryPersons(flowState) {
  const userIdentificationForm = flowState.forms[flowConstants.steps.USER_IDENTIFICATION.NAME] || {};

  return !isUndefined(userIdentificationForm.hasBeneficiaryPersons) && userIdentificationForm.hasBeneficiaryPersons === booleanTypesConstants.YES;
}
