import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "../../../constants/booleanTypes";

export default function FromPolicyToggleButton({name}) {
    return (
        <Layout width="100%" spacing={10} maxWidth={450}>
            <Layout.OneColumn>
                <Text element="h5" variant="variant2" label="fromPolicy.title"/>
            </Layout.OneColumn>
            <Layout.RowChildren>
                <ToggleButton name={`${name}.fromPolicy`}>
                    <Layout.OneRow spacing={5} flex={1}>
                        <ToggleButton.Choice label="button.yes"
                                             value={booleanTypesConstants.YES}
                                             variant="variant11"/>
                        <ToggleButton.Choice label="button.no"
                                             value={booleanTypesConstants.NO}
                                             variant="variant11"/>
                    </Layout.OneRow>
                </ToggleButton>
            </Layout.RowChildren>
        </Layout>
    );
}
