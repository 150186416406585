import React, {Component} from "react";

import axios from "axios";
import {Formik} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import FormErrorMessage from "components/form/FormErrorMessage";
import TextField from "components/form/TextField";
import {ConfigContext} from "containers/context/ConfigContainer"

import styles from "containers/Login.module.scss";

export default class ReportAuthentication extends Component {
  static contextType = ConfigContext;

  state = {
    invalidCredentials: false,
  };

  componentDidMount() {
    document.body.onbeforeunload = "return false";
  }

  onSubmit = async (values) => {

    const response = await axios.post(this.context.url.report, values);

    const data = response.data;

    if (data === undefined || !data.success) {
      this.setState({
        invalidCredentials: "report.authentication.failed",
      });
    } else {
      this.setState({
        invalidCredentials: "",
      });

      document.getElementById('reportId').value = values.id;
      document.getElementById('reportPassword').value = values.password;
      document.getElementById('reportDownloadForm').submit();
    }

  };

  onEnterPressed = formProps => async () => {
    formProps.submitForm();
  };

  render() {
    const {id} = this.props;
    return (
        <>
          <Formik initialValues={{id : id}} onSubmit={this.onSubmit}>
            {(formProps) => (
                <div className={styles.root}>
                  <div className={styles.inner}>
                    <Layout width="100%" maxWidth={450} spacing={5}>
                      <Layout.OneColumn>
                        <Text element="p" label="report.authentication.sidebar.title" variant="variant36"/>
                      </Layout.OneColumn>
                      <Layout.OneColumn spacing={10}/>
                      <Layout.OneColumn>
                        <Text element="div" label="report.authentication.sidebar.subtitle"/>
                      </Layout.OneColumn>
                      <Layout.OneColumn>
                        <TextField name="password"
                                   type="password"
                                   placeholder="report.authentication.password.placeholder"
                                   label="report.authentication.password.label"
                                   password={true}
                                   onEnterPressed={this.onEnterPressed(formProps)}/>
                      </Layout.OneColumn>
                      <Layout.OneColumn>
                        <FormErrorMessage error={this.state.invalidCredentials}
                                          showError={this.state.invalidCredentials.length > 0}/>
                      </Layout.OneColumn>
                      <Layout.OneColumn spacing={20}/>
                      <TextField name="id" hidden />
                      <Layout.OneColumn textAlign="right">
                        <Button label="report.authentication.button" onClick={formProps.submitForm}/>
                      </Layout.OneColumn>
                    </Layout>
                  </div>
                </div>
            )}
          </Formik>
        </>
    );
  }
}