import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import bankAccountTypeConstants from "constants/bankAccountTypes"
import claimTypesConstants from "constants/claimTypes";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import BankAccountOtherPerson from "containers/step/bankAccounts/BankAccountOtherPerson";
import BankAccountOwn from "containers/step/bankAccounts/BankAccountOwn";
import BankAccountRepairShop from "containers/step/bankAccounts/BankAccountRepairShop";
import isUndefinedOrNull from "utility/utilityFunctions";

export default class BankAccountsRight extends Component {
   render() {
    const {claimType, onNextStep, isValid, values} = this.props;
    const {repairServiceType, vehicleDamageInspectionPoint, personType} = values;
    let selectedRepairShop = false;

    if (repairServiceType === repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT && !isUndefinedOrNull(vehicleDamageInspectionPoint)) {
      selectedRepairShop = vehicleDamageInspectionPoint;
    }

    return (
      <Layout width="100%" spacing={10} justifyContent="center">
        <Layout.OneColumn width="100%" justifyContent="center">
          <Text element="h3" variant="variant1" label="bankAccounts.content.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}/>
        <Layout.OneColumn textAlign="right">
          <NextStepButton component={ToggleButton}
                          onNextStep={onNextStep}
                          isValid={isValid}
                          condition={value => value === bankAccountTypeConstants.DONT_KNOW}
                          buttonProps={{name: "bankAccountType"}}>
            <Layout.OneRow spacing={10} flex="1" rowClassName="full-width-mobile">
              <ToggleButton.Choice value={bankAccountTypeConstants.OWN}
                                   label={`bankAccounts.content.accountType.own.${personType}`} />
              <ToggleButton.Choice value={bankAccountTypeConstants.OTHER_PERSON}
                                   label="bankAccounts.content.accountType.otherPerson"/>
              <ToggleButton.Choice
                value={selectedRepairShop ? bankAccountTypeConstants.REPAIR_SHOP : bankAccountTypeConstants.OTHER_LEGAL_PERSON}
                label={claimType === claimTypesConstants.PERSON ? "bankAccounts.content.person.accountType.repairShop" : "bankAccounts.content.accountType.repairShop"}/>
              <ToggleButton.Choice value={bankAccountTypeConstants.DONT_KNOW}
                                   label="bankAccounts.content.accountType.dontKnow"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <BankAccountSelection selectedRepairShop={selectedRepairShop} {...this.props}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

function BankAccountSelection(props) {
  const {selectedRepairShop, values: {bankAccountType}} = props;

  return (
    <FadeView step={bankAccountType}>
      <Switch condition={bankAccountType}>
        <Case value={bankAccountTypeConstants.OWN}>
          <BankAccountOwn {...props}/>
        </Case>
        <Case value={bankAccountTypeConstants.OTHER_PERSON}>
          <BankAccountOtherPerson {...props}/>
        </Case>
        <Case value={bankAccountTypeConstants.REPAIR_SHOP}>
          <BankAccountRepairShop {...props}/>
        </Case>
        <Case value={bankAccountTypeConstants.OTHER_LEGAL_PERSON}>
            <BankAccountRepairShop {...props}/>
        </Case>
        <Case value={bankAccountTypeConstants.DONT_KNOW}>
          {null}
        </Case>
        <Default>
          {null}
        </Default>
      </Switch>
    </FadeView>
  );
}