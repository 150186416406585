import React from "react";

import * as dateFns from "date-fns";
import {isEmpty} from "underscore";

import Text from "components/common/Text";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import summaryConstants from "constants/summary";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryHomeResponsiblePerson() {
    return (
        <Summary title="summary.content.responsiblePerson.title"
                 onePart={true}
                 refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.REF}>

            <Summary.ItemValue label="summary.content.homeSomeoneElseResponsible.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.SOMEONE_ELSE_RESPONSIBLE}
                               formName={{name: flowConstants.homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME}}>
                {form => <Text label={`summary.content.common.boolean.${form.someoneElseResponsible}`}/>}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.nameAndSurname.naturalPerson.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.NAME_AND_SURNAME}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}
                               condition={form => form.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON}>
                {form => form.responsiblePerson.nameAndSurname}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.nameAndSurname.legalPerson.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.NAME}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}
                               condition={form => form.responsiblePersonChoice === responsiblePersonTypes.LEGAL_PERSON}>
                {form => form.responsiblePerson.nameAndSurname}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.birthDate.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.BIRTH_DATE}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}
                               condition={form => form.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON}>
                {form => form.responsiblePerson.birthDate === null
                    ? <Text label="summary.content.common.boolean.dontKnow"/>
                    : dateFns.format(new Date(form.responsiblePerson.birthDate), summaryConstants.DATE_FORMAT)
                }
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.sex.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.SEX}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}
                               condition={form => form.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON}>
                {form => isEmpty(form.responsiblePerson.sex)
                    ? <Text label="summary.content.common.boolean.dontKnow"/>
                    : <Text label={`sex.${form.responsiblePerson.sex}`}/>}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.streetAndNumber.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.STREET_AND_NUMBER}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => form.responsiblePerson.streetAndNumber}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.city.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.CITY}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => form.responsiblePerson.city}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.zip.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.ZIP}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => form.responsiblePerson.zip}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.country.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.COUNTRY}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => form.responsiblePerson.country}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.policyNumber.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.POLICY_NUMBER}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => {
                    const policyNumber = form.responsiblePerson.policyNumber;

                    return !isEmpty(policyNumber) ? policyNumber :
                        <Text label="summary.content.common.boolean.dontKnow"/>;
                }}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.fromPolicy.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.FROM_POLICY}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}>
                {form => isEmpty(form.responsiblePerson.fromPolicy)
                    ? <Text label="summary.content.common.boolean.dontKnow"/>
                    : <Text label={`summary.content.common.boolean.${form.responsiblePerson.fromPolicy}`}/>}
            </Summary.ItemValue>

            <Summary.ItemValue label="summary.content.homeResponsiblePerson.taxNumber.label"
                               refName={summaryRefConstants.HOME_RESPONSIBLE_PERSON.POLICY_NUMBER}
                               formName={{name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}}
                               condition={form => form.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON}>
                {form => form.responsiblePerson.taxNumber}
            </Summary.ItemValue>
        </Summary>
    );
}