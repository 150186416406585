import _ from "lodash";
import {isArray, isEqual, isUndefined,} from "underscore";

import accidentDateTypeConstants from "constants/accidentDateTypes";
import booleanTypeConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypeConstants from "constants/medicalCareDescriptionTypes";
import personTypesConstants from "constants/personTypes";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import vehicleInfoInputTypeConstants from "constants/vehicleInfoInputTypes";
import vehicleTypeConstants from "constants/vehicleTypes";
import {getAllFieldConfigurations} from "selectors/flow";
import {getSteps, hasGeneraliInsurancePolicy} from "selectors/flow/getSteps";
import {ownVehicle} from "selectors/participantsNumber";
import calculateSummary from "selectors/summary";
import {formatCustomerData, formatCustomerDataLegal, formatPolicyData} from "utility/customerData";
import isUndefinedOrNull, {formatDate} from "utility/utilityFunctions";
import customerDataStatusTypeConstants from "../../../constants/customerDataStatusTypes";
import personTypeConstants from "constants/personTypes";


const steps = flowConstants.steps;
const homeSteps = flowConstants.homeSteps;
const personSteps = flowConstants.personSteps;
export function preCalculateAccidentDate(forms) {
  const accidentDateForm = forms[steps.ACCIDENT_DATE.NAME] || {};

  return {
    accidentDateType: accidentDateTypeConstants.CALENDAR,
    ...accidentDateForm,
  };
}

export function preCalculateAccidentTime(forms) {
  const accidentDateForm = forms[steps.ACCIDENT_DATE.NAME] || {};
  const accidentTimeForm = forms[steps.ACCIDENT_TIME.NAME] || {};

  return {
    accidentTimeRange: false,
    ...accidentTimeForm,
    accidentDateType: accidentDateForm.accidentDateType,
  };
}

export function preCalculateHomeAccidentTime(forms) {
  const accidentDateForm = forms[steps.ACCIDENT_DATE.NAME] || {};
  const accidentTimeForm = forms[steps.ACCIDENT_TIME.NAME] || {};

  return {
    accidentTimeRange: false,
    ...accidentTimeForm,
    accidentDateType: accidentDateForm.accidentDateType,
  };
}

export function preCalculatePersonAccidentTime(forms) {
  const accidentDateForm = forms[steps.ACCIDENT_DATE.NAME] || {};
  const accidentTimeForm = forms[steps.ACCIDENT_TIME.NAME] || {};

  return {
    accidentTimeRange: false,
    ...accidentTimeForm,
    accidentDateType: accidentDateForm.accidentDateType,
  };
}

export function preCalculateBankAccounts(forms, configuration) {
  const mapsIntroForm = forms[steps.MAPS_INTRO.NAME] || {};
  const userIdentificationForm = forms[steps.USER_IDENTIFICATION.NAME] || {};
  const vehicleDamageInspectionForm = forms[steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME] || {};
  const bankAccountsForm = forms[steps.BANK_ACCOUNTS.NAME] || {};
  const userIdentificationTypeForm = forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
  const insuranceOwnerForm = forms[steps.INSURANCE_OWNER.NAME] || {};
  const medicalCareTypeForm = forms[personSteps.MEDICAL_CARE_TYPE.NAME] || {};

  const {repairServiceType} = mapsIntroForm;
  const {vehicleDamageInspectionPoint} = vehicleDamageInspectionForm;
  const bankAccounts = bankAccountsForm.bankAccounts || [];
  const ownBankAccounts = [];
  const addedBankAccounts = [];
  const medicalCareTypeDescription = medicalCareTypeForm ? medicalCareTypeForm.medicalCareTypeDescription : null;

  bankAccounts.map(bankAccount => !isUndefinedOrNull(bankAccount.added) ? addedBankAccounts.push(bankAccount) : ownBankAccounts.push(bankAccount));

  const userIdentificationType = insuranceOwnerForm.owner === booleanTypeConstants.NO || medicalCareTypeDescription === medicalCareDescriptionTypeConstants.DEATH
    ? userIdentificationTypeConstants.MANUAL : userIdentificationTypeForm.userIdentificationType;

  const personType = _.at(userIdentificationForm, "personType")[0];

  var otherPersonType = bankAccountsForm.otherPersonType;
  if (!otherPersonType) {
    otherPersonType = personTypeConstants.NATURAL;
  }

  const bankAccountsChanged = !isEqual(ownBankAccounts, _.at(userIdentificationForm, "finalUserInfo.bankAccounts")[0]);

  return {
    ...bankAccountsForm,
    bankAccountTypeMojAs: bankAccountsChanged ? "" : bankAccountsForm.bankAccountTypeMojAs,
    vehicleDamageInspectionPoint: vehicleDamageInspectionPoint,
    repairServiceType,
    userIdentificationType,
    personType,
    otherPersonType,
    bankAccounts: [
      ...(_.at(userIdentificationForm, "finalUserInfo.bankAccounts")[0] || []),
      ...addedBankAccounts,
    ],
  };
}

export function preCalculateHasCasco(forms) {

  const hasCascoForm = forms[steps.HAS_CASCO.NAME] || {};

  return {
    accidentDate: getAccidentDate(forms),
    chassisNumber: getChassisNumber(forms),
    hasCasco: hasCascoForm ? hasCascoForm.hasCasco : null,
  };

}

export function preCalculateReportWithOtherCompany(forms) {

  const responsiblePersonForm = forms[steps.RESPONSIBLE_PERSON.NAME] || {};
  const participantsInfoForm = forms[steps.PARTICIPANTS_INFO.NAME] || [];

  let responsibleParticipantIndex = 0;

  if (isArray(responsiblePersonForm.responsiblePerson)) {
    for (let i = 0; i < responsiblePersonForm.responsiblePerson.length; i++) {

      const responsiblePersonIndex = responsiblePersonForm.responsiblePerson[i];
      const person = participantsInfoForm[responsiblePersonIndex];

      if (!hasGeneraliInsurancePolicy(person)) {
        responsibleParticipantIndex = responsiblePersonIndex;
        break;
      }

    }
  }

  const {vehicleData} = participantsInfoForm[responsibleParticipantIndex].participantInfo;

  return {
    insuranceCompany: vehicleData.insuranceCompany,
  };
}

export function preCalculateSummary(forms, configuration) {
  const activeSteps = configuration ? getSteps({forms, configuration}) : null;
  const fieldConfigurations = getAllFieldConfigurations(forms, configuration);

  return {
    ...calculateSummary(forms, activeSteps, fieldConfigurations),
  };
}

export function preCalculateUserIdentificationDeath(forms) {
  const userIdentificationForm = forms[steps.USER_IDENTIFICATION.NAME] || {};
  const userIdentificationTypeForm = forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
  const userLoginForm = forms[steps.USER_LOGIN.NAME] || {};
  const userIdentificationTypeChanged = userIdentificationTypeForm.userIdentificationType !== userIdentificationForm.userIdentificationType;

  if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
    const policyNumberChanged = userIdentificationForm.policyNumber ? userLoginForm.policyNumber !== userIdentificationForm.policyNumber : true;
    const taxNumberChanged = userIdentificationForm.taxNumber ? userLoginForm.taxNumber !== userIdentificationForm.taxNumber : true;

    if (taxNumberChanged || policyNumberChanged || userIdentificationTypeChanged) {
      return {
        ...insuranceInfoDataFormat(userIdentificationTypeForm, userLoginForm, booleanTypeConstants.NO),
        personType: personTypesConstants.NATURAL,
        accidentDate: getAccidentDate(forms),
      };
    } else {
      return {
        ...userIdentificationForm,
        wasDriver: booleanTypeConstants.NO,
        personType: personTypesConstants.NATURAL,
        accidentDate: getAccidentDate(forms),
        chassisNumber: getChassisNumber(forms),
      };
    }
  } else if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.GENERALI) {

    const crsIdChanged = userIdentificationForm.crsId ? userLoginForm.userData.crsId !== userIdentificationForm.crsId : true;

    if (crsIdChanged || userIdentificationTypeChanged) {
      return {
        ...generaliDataFormat(userIdentificationTypeForm, userLoginForm),
        personType: personTypesConstants.NATURAL,
        accidentDate: getAccidentDate(forms),
      };
    } else {
      return {
        ...userIdentificationForm,
        wasDriver: booleanTypeConstants.NO,
        personType: personTypesConstants.NATURAL,
        accidentDate: getAccidentDate(forms),
        chassisNumber: getChassisNumber(forms),
      };
    }
  } else {
    return {
      ...userIdentificationForm,
      wasDriver: booleanTypeConstants.NO,
      personType: personTypesConstants.NATURAL,
      accidentDate: getAccidentDate(forms),
      chassisNumber: getChassisNumber(forms),
    };
  }
}

export function preCalculateUserIdentification(forms) {
  const insuranceOwnerForm = forms[steps.INSURANCE_OWNER.NAME] || {};
  const userIdentificationTypeForm = forms[steps.USER_IDENTIFICATION_TYPE.NAME] || {};
  const userLoginForm = forms[steps.USER_LOGIN.NAME] || {};
  const userIdentificationForm = forms[steps.USER_IDENTIFICATION.NAME] || {};
  const ownVehicleInfoWasDriverForm = forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};

  if (isUndefined(userIdentificationForm.personType)) {
    if (insuranceOwnerForm.owner === booleanTypeConstants.NO || userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.MANUAL) {
      return {
        personType: personTypesConstants.NATURAL,
        userIdentificationType: userIdentificationTypeForm.userIdentificationType,
        wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
        accidentDate: getAccidentDate(forms),
        chassisNumber: getChassisNumber(forms),
      };
    } else if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
      return {
        ...insuranceInfoDataFormat(userIdentificationTypeForm, userLoginForm, ownVehicleInfoWasDriverForm.wasDriver),
        accidentDate: getAccidentDate(forms),
      };
    } else if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.GENERALI) {
      return {
        ...generaliDataFormat(userIdentificationTypeForm, userLoginForm, ownVehicleInfoWasDriverForm),
        accidentDate: getAccidentDate(forms),
      };
    } else {
      return {
        ...mobileAppDataFormat(userLoginForm, ownVehicleInfoWasDriverForm),
        accidentDate: getAccidentDate(forms),
      };
    }
  } else {
    const userIdentificationTypeChanged = userIdentificationTypeForm.userIdentificationType !== userIdentificationForm.userIdentificationType;

    if (insuranceOwnerForm.owner === booleanTypeConstants.YES) {
      if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.INSURANCE_INFO) {
        const policyNumberChanged = userIdentificationForm.policyNumber ? userLoginForm.policyNumber !== userIdentificationForm.policyNumber : true;
        const taxNumberChanged = userIdentificationForm.taxNumber ? userLoginForm.taxNumber !== userIdentificationForm.taxNumber : true;

        if (taxNumberChanged || policyNumberChanged || userIdentificationTypeChanged) {
          return {
            ...insuranceInfoDataFormat(userIdentificationTypeForm, userLoginForm, ownVehicleInfoWasDriverForm.wasDriver),
            accidentDate: getAccidentDate(forms),
          };
        } else {
          return {
            ...userIdentificationForm,
            wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
            accidentDate: getAccidentDate(forms),
            chassisNumber: getChassisNumber(forms),
          };
        }
      } else if (userIdentificationTypeForm.userIdentificationType === userIdentificationTypeConstants.GENERALI) {

        const crsIdChanged = userIdentificationForm.crsId ? userLoginForm.userData.crsId !== userIdentificationForm.crsId : true;

        if (crsIdChanged || userIdentificationTypeChanged) {
          return {
            ...generaliDataFormat(userIdentificationTypeForm, userLoginForm, ownVehicleInfoWasDriverForm),
            accidentDate: getAccidentDate(forms),
          };
        } else {
          return {
            ...userIdentificationForm,
            wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
            accidentDate: getAccidentDate(forms),
            chassisNumber: getChassisNumber(forms),
          };
        }
      } else {
        return {
          ...userIdentificationForm,
          wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
          accidentDate: getAccidentDate(forms),
          chassisNumber: getChassisNumber(forms),
        };
      }
    } else {
      return {
        ...userIdentificationForm,
        wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
        accidentDate: getAccidentDate(forms),
        chassisNumber: getChassisNumber(forms),
      };
    }
  }
}

function generaliDataFormat(userIdentificationTypeForm, userLoginForm, ownVehicleInfoWasDriverForm) {
  if (userLoginForm.userData.customerDataStatus === customerDataStatusTypeConstants.FOUND) {
    return {
      personType: personTypesConstants.NATURAL,
      userIdentificationType: userIdentificationTypeForm.userIdentificationType,
      userInfo: {
        ...formatCustomerData(userLoginForm.userData),
      },
      policies: formatPolicyData(userLoginForm.userData),
      wasDriver: ownVehicleInfoWasDriverForm ? ownVehicleInfoWasDriverForm.wasDriver : undefined,
      crsId: userLoginForm.userData.crsId,
    };
  } else {
    return {
      personType: personTypesConstants.NATURAL,
      userIdentificationType: userIdentificationTypeForm.userIdentificationType,
      policies: formatPolicyData(userLoginForm.userData),
      wasDriver: ownVehicleInfoWasDriverForm ? ownVehicleInfoWasDriverForm.wasDriver : undefined,
    };

  }
}

function insuranceInfoDataFormat(userIdentificationTypeForm, userLoginForm, wasDriver) {
  if (userLoginForm.userData.customerDataStatus === customerDataStatusTypeConstants.FOUND) {

    if (userLoginForm.userData.personType === personTypesConstants.NATURAL) {
      return {
        personType: personTypesConstants.NATURAL,
        userIdentificationType: userIdentificationTypeForm.userIdentificationType,
        userInfo: {
          ...formatCustomerData(userLoginForm.userData),
          policyNumber: userLoginForm.policyNumber,
          taxNumber: userLoginForm.taxNumber,
        },
        policyNumber: userLoginForm.policyNumber,
        taxNumber: userLoginForm.taxNumber,
        wasDriver: wasDriver,
        crsId: userLoginForm.userData.crsId,
      };
    }
    else {
      return {
        personType: personTypesConstants.LEGAL,
        userIdentificationType: userIdentificationTypeForm.userIdentificationType,
        legalPerson: {
          ...formatCustomerDataLegal(userLoginForm.userData),
          policyNumber: userLoginForm.policyNumber,
          taxNumber: userLoginForm.taxNumber,
        },
        policyNumber: userLoginForm.policyNumber,
        taxNumber: userLoginForm.taxNumber,
        wasDriver: wasDriver,
        crsId: userLoginForm.userData.crsId,
      };
    }
  } else {
    return {
      personType: personTypesConstants.NATURAL,
      userIdentificationType: userIdentificationTypeForm.userIdentificationType,
      policyNumber: userLoginForm.policyNumber,
      taxNumber: userLoginForm.taxNumber,
      wasDriver: wasDriver,
    };

  }
}

function mobileAppDataFormat(userLoginForm, ownVehicleInfoWasDriverForm) {
  if (userLoginForm.userData.customerDataStatus === customerDataStatusTypeConstants.FOUND) {
    return {
      personType: personTypesConstants.NATURAL,
      userInfo: {
        ...formatCustomerData(userLoginForm.userData),
      },
      policies: formatPolicyData(userLoginForm.userData),
      wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
      crsId: userLoginForm.userData.crsId,
    };
  } else {
    return {
      personType: personTypesConstants.NATURAL,
      policies: formatPolicyData(userLoginForm.userData),
      wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
    };

  }
}

export function preCalculateHomeVehicleInfo(forms) {
  const homeVehicleInfoForm = forms[homeSteps.HOME_VEHICLE_INFO.NAME] || {};

  const inputType = _.at(homeVehicleInfoForm, "vehicleInfo.inputType")[0];
  const unknownVehicle = inputType === vehicleInfoInputTypeConstants.UNKNOWN;

  return {
    info: {
      ...ownVehicle(vehicleTypeConstants.CAR),
    },
    ...homeVehicleInfoForm,
    vehicleInfo: {
      ...homeVehicleInfoForm.vehicleInfo,
      inputType: unknownVehicle ? "" : inputType,
    }
  };
}

export function arraysContainSameElements(a1, a2) {
  return !isUndefined(a1) && !isUndefined(a2) && _.difference(a1, a2).length === 0;
}

export function preCalculateApplicantData(forms) {
  const applicantDataForm = forms[steps.APPLICANT_DATA.NAME] || {};
  const ownVehicleInfoWasDriverForm = forms[steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME] || {};

  return {
    ...applicantDataForm,
    wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
  };
}

export function preCalculateUserLogin(forms) {
  const claimTypeForm = forms[steps.CLAIM_TYPE.NAME] || {};
  const userLoginForm = forms[steps.USER_LOGIN.NAME] || {};

  return {
    ...userLoginForm,
    claimType: claimTypeForm.choiceType,
    accidentDate: getAccidentDate(forms),
  };
}

function getAccidentDate(forms) {
  const accidentDateForm = forms[steps.ACCIDENT_DATE.NAME] || {};

  return formatDate(accidentDateForm);
}

function getChassisNumber(forms) {
  const chassisNumber = _.at(forms, "participantsInfo[0].participantInfo.vehicleData.chassisNumber")[0];
  return chassisNumber;
}