import React from "react";
import Switch, {Case, Default} from "react-switch-case";

import flowConstants from "constants/flow";
import ThankYouStepContainer from "containers/step/thankYou/ThankYouStepContainer";
import CarDamageTypeStepContainer from "containers/step/carDamageType/CarDamageTypeStepContainer";
import MechanicalDamageStepContainer from "containers/step/mechanicalDamage/MechanicalDamageStepContainer";
import MechanicalDamageOwnStepContainer from "containers/step/mechanicalDamage/MechanicalDamageOwnStepContainer";
import TheftTypeStepContainer from "containers/step/theftType/TheftTypeStepContainer";
import AccidentDateStepContainer from "containers/step/accidentDate/AccidentDateStepContainer";
import AccidentTimeStepContainer from "containers/step/accidentTime/AccidentTimeStepContainer";
import AccidentLocationStepContainer from "containers/step/accidentLocation/AccidentLocationStepContainer";
import VehicleTypeStepContainer from "containers/step/vehicleType/VehicleTypeStepContainer";
import VehicleDamageCurrentStepContainer from "containers/step/vehicleDamage/VehicleDamageCurrentStepContainer";
import VehicleDamageCurrentDescriptionStepContainer from "containers/step/vehicleDamage/VehicleDamageCurrentDescriptionStepContainer";
import ParkedStepContainer from "containers/step/parked/ParkedStepContainer";
import EventDescriptionStepContainer from "containers/step/eventDescription/EventDescriptionStepContainer";
import ParticipantsNumberStepContainer from "containers/step/participantsNumber/ParticipantsNumberStepContainer";
import ParticipantsInfoStepContainer from "containers/step/participantsInfo/ParticipantsInfoStepContainer";
import OwnVehicleInfoWasDriverStepContainer from "containers/step/ownVehicleInfo/OwnVehicleInfoWasDriverStepContainer";
import OwnVehicleInfoDriverHurtStepContainer
  from "containers/step/ownVehicleInfo/OwnVehicleInfoDriverHurtStepContainer";
import OwnVehicleInfoReportedPoliceStepContainer
  from "containers/step/ownVehicleInfo/OwnVehicleInfoReportedPoliceStepContainer";
import OtherParticipantsInfoStepContainer
  from "containers/step/otherParticipantsInfo/OtherParticipantsInfoStepContainer";
import ResponsiblePersonStepContainer from "containers/step/responsiblePerson/ResponsiblePersonStepContainer";
import DocumentsStepContainer from "containers/step/documents/DocumentsStepContainer";
import MapsIntroStepContainer from "containers/step/mapsIntro/MapsIntroStepContainer";
import VehicleDamageInspectionPointStepContainer
  from "containers/step/vehicleDamageInspectionPoint/VehicleDamageInspectionPointStepContainer"
import PhoneNumberStepContainer from "containers/step/phoneNumber/PhoneNumberStepContainer";
import DrawingIntroStepContainer from "containers/step/drawingIntro/DrawingIntroStepContainer";
import DrawingStepContainer from "containers/step/drawing/DrawingStepContainer";
import InsuranceOwnerStepContainer from "containers/step/insuranceOwner/InsuranceOwnerStepContainer";
import UserIdentificationStepContainer from "containers/step/userIdentification/UserIdentificationStepContainer";
import HasCascoStepContainer from "containers/step/hasCasco/HasCascoStepContainer";
import ReportWithOtherCompanyStepContainer from "containers/step/reportWithOtherCompany/ReportWithOtherCompanyStepContainer";
import BankAccountsStepContainer from "containers/step/bankAccounts/BankAccountsStepContainer";
import SummaryStepContainer from "containers/step/summary/SummaryStepContainer";
import OwnVehiclePassengersStepContainer from "containers/step/ownVehiclePassengers/OwnVehiclePassengersStepContainer";
import UserIdentificationTypeStepContainer from "./step/userIdentificationType/UserIdentificationTypeStepContainer";
import ApplicantDataStepContainer from "./step/applicantData/ApplicantDataStepContainer";
import UserLoginStepContainer from "./step/userLogin/UserLoginStepContainer";

export default function StateRouterCarContainer({step}) {
  return (
    <Switch condition={step.name}>
      <Case value={flowConstants.steps.CAR_DAMAGE_TYPE.NAME}>{/* step 5 */}
        <CarDamageTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.MECHANICAL_DAMAGE.NAME}>{/* step 5.1 */}
        <MechanicalDamageStepContainer/>
      </Case>
      <Case value={flowConstants.steps.MECHANICAL_DAMAGE_OWN.NAME}>{/* step 5.2 */}
        <MechanicalDamageOwnStepContainer/>
      </Case>
      <Case value={flowConstants.steps.THEFT_TYPE.NAME}>{/* step 5.3 */}
        <TheftTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_DATE.NAME}>{/* step 6 */}
        <AccidentDateStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_TIME.NAME}>{/* step 7 */}
        <AccidentTimeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_LOCATION.NAME}>{/* step 8 */}
        <AccidentLocationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.VEHICLE_LOCATION.NAME}>
        <AccidentLocationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.VEHICLE_TYPE.NAME}>{/* step 9a */}
        <VehicleTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME}>{/* step 9a */}
        <VehicleDamageCurrentStepContainer/>
      </Case>
      <Case value={flowConstants.steps.VEHICLE_DAMAGE_CURRENT_DESCRIPTION.NAME}>{/* step 9d */}
        <VehicleDamageCurrentDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PARKED.NAME}>{/* step 11 */}
        <ParkedStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PARTICIPANTS_NUMBER.NAME}>{/* step 12 */}
        <ParticipantsNumberStepContainer/>
      </Case>
      <Case value={flowConstants.steps.DRAWING_INTRO.NAME}>{/* step 13 */}
        <DrawingIntroStepContainer/>
      </Case>
      <Case value={flowConstants.steps.DRAWING.NAME}>{/* step 14 */}
        <DrawingStepContainer/>
      </Case>
      <Case value={flowConstants.steps.EVENT_DESCRIPTION.NAME}>{/* step 15 */}
        <EventDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PARTICIPANTS_INFO.NAME}>{/* step 16 - 18 */}
        <ParticipantsInfoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME}>{/* step 19 */}
        <OwnVehicleInfoWasDriverStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME}>{/* step 20 */}
        <OwnVehicleInfoDriverHurtStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME}>{/* step 21 */}
        <OwnVehicleInfoReportedPoliceStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME}>{/* step 22 */}
        <OwnVehiclePassengersStepContainer/>
      </Case>
      <Case value={flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME}>{/* step 24 */}
        <OtherParticipantsInfoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.RESPONSIBLE_PERSON.NAME}>{/* step 28 */}
        <ResponsiblePersonStepContainer/>
      </Case>
      <Case value={flowConstants.steps.DOCUMENTS.NAME}>{/* step 30 */}
        <DocumentsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.MAPS_INTRO.NAME}>{/* step 31 */}
        <MapsIntroStepContainer/>
      </Case>
      <Case value={flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME}>{/* step 32 */}
        <VehicleDamageInspectionPointStepContainer/>
      </Case>
      <Case value={flowConstants.steps.INSURANCE_OWNER.NAME}>{/* step 33 */}
        <InsuranceOwnerStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME}>{/* step 33 */}
        <UserIdentificationTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.APPLICANT_DATA.NAME}>{/* step 33 */}
        <ApplicantDataStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_LOGIN.NAME}>{/* step 33 */}
        <UserLoginStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION.NAME}>{/* step 33 */}
        <UserIdentificationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.HAS_CASCO.NAME}>{/* step 34 */}
        <HasCascoStepContainer/>
      </Case>
      <Case value={flowConstants.steps.REPORT_WITH_OTHER_COMPANY.NAME}>{/* step 34 */}
        <ReportWithOtherCompanyStepContainer/>
      </Case>
      <Case value={flowConstants.steps.BANK_ACCOUNTS.NAME}>{/* step 34 */}
        <BankAccountsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.SUMMARY.NAME}>{/* step 35 */}
        <SummaryStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PHONE_NUMBER.NAME}>{/* step 36 */}
        <PhoneNumberStepContainer/>
      </Case>
      <Case value={flowConstants.steps.THANK_YOU.NAME}>{/* step 37 */}
        <ThankYouStepContainer/>
      </Case>
      <Default>
        <div>
          Unknown step: {step.name}.
        </div>
      </Default>
    </Switch>
  );
}
