import React, {Component} from "react";

import Layout from "components/common/Layout";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import ReferralNumbersData from "components/step/ReferralNumbers";
import isUndefinedOrNull from "utility/utilityFunctions";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const arrayObjectValidationSchema = Yup.object().shape({
  name: (getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.REFERRAL_NUMBERS.NAME].referralNumber)),
});

const validationSchema = Yup.object().shape({
    referralNumbers: Yup.array().min(1).of(arrayObjectValidationSchema),
    card: Yup.string()
        .test("telephoneNumbersEqual", "validation.card", function (card) {
            const referralNumbers = this.parent.referralNumbers;
            var referralNumbersEntered = false;
            if (referralNumbers) {
                for (var i = 0; i < referralNumbers.length; i++) {
                    const referralNumber = referralNumbers[i];
                    if (!isUndefinedOrNull(referralNumber) && !isUndefinedOrNull(referralNumber.name) && referralNumber.name !== '') {
                        referralNumbersEntered = true;
                        break;
                    }
                }
            }
            if (!referralNumbersEntered) {
                return true;
            }
            return !isUndefinedOrNull(card);
        }),
});

export default class ReferralNumbersStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="referralNumbers.sidebar.title"/>
        <Text element="div" label="referralNumbers.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <Layout flex={1}>
          <Layout.OneColumn>
            <ReferralNumbersData/>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
