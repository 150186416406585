import React, {Component} from "react"
import {connect} from "react-redux";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import CheckBoxField from "components/form/CheckBox/CheckBoxField";
import ChoiceButton from "components/form/ChoiceButton";
import DateField from "components/form/DateField";
import SelectField from "components/form/SelectField";
import SexField from "components/form/SexField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber"
import booleanTypes from "constants/booleanTypes";
import claimTypes from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import wasDriverTypesConstants from "constants/wasDriverTypes";
import RelationshipToggleButton from "containers/step/userIdentification/RelationshipToggleButton";
import {capitalize, leaveNumbersAndLetters} from "utility/textTransform";
import isUndefinedOrNull, {isSloAddress} from "utility/utilityFunctions";
import dl from "images/stevilka-vozniskega-dovoljenja.jpg";

import _ from "lodash";

class UserIdentificationInfo extends Component {

  onSelectPolicy = () => {
    const {name, setFieldValue, values} = this.props;

    if (values.userInfo.policyNumber) {
      setFieldValue(`${name}.policyNumber`, "");
    }

  };

  onTypePolicy = () => {

    const {name, setFieldValue, values} = this.props;

    if (values.userInfo.policyNumberSelected) {
      setFieldValue(`${name}.policyNumberSelected`, "");
    }
  };

  render() {
    const {
      values,
      name,
      slovenia,
      loggedInFromCounter,
      titleLabel,
      claimType,
      insuranceOwner,
      mobileUserId,
      isValid,
      userIdentification,
      inSummary,
      medicalCareTypeDescription
    } = this.props;
    const address = values.userIdentificationType === booleanTypes.YES
      ? isUndefinedOrNull(values.login) || isUndefinedOrNull(values.login.customData) ? null : values.login.customData.address
      : isUndefinedOrNull(values.userInfo) ? null : values.userInfo.address;
    const hasPolicies = values.policies && values.policies.length > 0;
    const isGeneraliOrMobileAppUser = values.userIdentificationType === userIdentificationTypeConstants.GENERALI || mobileUserId;
    const canAddBeneficiaries = !isUndefinedOrNull(userIdentification.beneficiaryPersons) && userIdentification.beneficiaryPersons === booleanTypes.YES ? booleanTypes.NO : booleanTypes.YES;
    const label = medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH ? "userIdentification.content.death.title" : titleLabel;

    return (
      <Layout spacing={5} width="100%" maxWidth={450} justifyContent="center">
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <Text element="h4" label={label} variant="variant2"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}/>
        {medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH ? <Layout.OneColumn>
          <CheckBoxField name={`${name}.isBeneficiary`} label="upravičenec" variant="variant16"/>
        </Layout.OneColumn> : null}
        <Layout.OneColumn>
          <TextField name={`${name}.name`}
                     transform={capitalize}
                     placeholder="userIdentification.content.name.placeholder"
                     label="userIdentification.content.name.label"
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].name + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`${name}.surname`}
                     transform={capitalize}
                     placeholder="userIdentification.content.surname.placeholder"
                     label="userIdentification.content.surname.label"
                     isRequired={true}
                     maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].surname + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <DateField name={`${name}.birthDate`}
                     isRequired={!loggedInFromCounter}
                     placeholder="userIdentification.content.birthDate.placeholder"
                     label="userIdentification.content.birthDate.label"
                     legalAgeField={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <SexField name={`${name}.sex`} isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Address name={`${name}.address`}
                   isRequired={!loggedInFromCounter}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name={`${name}.telephoneNumber`}
                     placeholder="userIdentification.content.telephoneNumber.placeholder"
                     label="userIdentification.content.telephoneNumber.label"
                     maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].telephoneNumber + 1} />
        </Layout.OneColumn>
          {insuranceOwner !== 'no' && (isUndefinedOrNull(values.userInfo) || isUndefinedOrNull(values.userInfo.telephoneNumber) || values.userInfo.telephoneNumber === '') ?
              <Layout.OneColumn>
                  <Text element="span" variant="variant8" label="userIdentification.content.telephoneNumber.info" />
              </Layout.OneColumn>
              :
              null
          }
        <Layout.OneColumn>
          <TextField name={`${name}.email`}
                     placeholder="userIdentification.content.email.placeholder"
                     label="userIdentification.content.email.label"
                     maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email + 1} />
        </Layout.OneColumn>
          {insuranceOwner !== 'no' && (isUndefinedOrNull(values.userInfo) || isUndefinedOrNull(values.userInfo.email) || values.userInfo.email === '') ?
              <Layout.OneColumn>
                  <Text element="span" variant="variant8" label="userIdentification.content.email.info" />
              </Layout.OneColumn>
              :
              null
          }
        <Layout.OneColumn>
          <TaxNumber name={`${name}.taxNumber`}
                     placeholder="userIdentification.content.taxNumber.placeholder"
                     label="userIdentification.content.taxNumber.label"
                     isRequired={!loggedInFromCounter}
                     isSlovenia={isSloAddress(address, slovenia)}/>
        </Layout.OneColumn>

        {values.wasDriver === wasDriverTypesConstants.YES
          ? [
            <Layout.OneColumn>
            <TaxNumber name={`${name}.licenceNumber`}
                       placeholder="userIdentification.content.licenceNumber.placeholder"
                       label="userIdentification.content.licenceNumber.label"
                       transform={leaveNumbersAndLetters}
                       isRequired={true}
                       maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].licenceNumber + 1}/>
          </Layout.OneColumn>,
          <Layout.OneColumn>
            <div style={{ paddingTop: 16 }}>
              <img src={dl} alt="Drivers license" style={{ maxWidth: "100%" }} />
            </div>
          </Layout.OneColumn>
                ]
          : null}

        {hasPolicies && isGeneraliOrMobileAppUser
          ? <Layout.OneColumn>
            <SelectField name={`${name}.policyNumberSelected`}
                         placeholder="userIdentification.content.policyNumber.placeholder"
                         label="userIdentification.content.policyNumber.label"
                         onValueChange={this.onSelectPolicy}>
              <SelectField.Option value="" label="userIdentification.content.policyNumber.label" disabled hidden/>
              {values.policies.map(policy =>
                <SelectField.Option value={policy.policyNumber}
                                    title={policy.policyName}
                                    label={`${policy.policyNumber} - ${policy.policyName} (${policy.dateFrom ? policy.dateFrom : "ni znano"} - ${policy.dateTo ? policy.dateTo : "ni znano"})`}/>
              )}
            </SelectField>
          </Layout.OneColumn> : null}

        <Layout.OneColumn>
          <TextField name={`${name}.policyNumber`}
                     placeholder={hasPolicies && isGeneraliOrMobileAppUser ? "userIdentification.content.policyNumberManual.mobile.placeholder" : "userIdentification.content.policyNumberManual.placeholder"}
                     label={hasPolicies && isGeneraliOrMobileAppUser ? "userIdentification.content.policyNumberManual.mobile.label" : "userIdentification.content.policyNumberManual.label"}
                     maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].policyNumber + 1}
                     onValueChange={this.onTypePolicy}/>
        </Layout.OneColumn>
        {claimType === claimTypes.PERSON && (insuranceOwner === booleanTypes.NO || medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH)
          ? <Layout.OneColumn>
            <RelationshipToggleButton name={name} label="userIdentification"/>
          </Layout.OneColumn>
          : null
        }
        <Layout.OneColumn spacing={30}></Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <Text element="span" variant="variant8" label="userIdentification.content.check"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}></Layout.OneColumn>
        {
          loggedInFromCounter
            ? <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
              <Text element="span" variant="variant8" label="userIdentification.taxNumber.info"/>
            </Layout.OneColumn>
            : null
        }
        {
          medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH ? <Layout.OneColumn>
            <BeneficiariesButtons isValid={isValid} userIdentification={userIdentification}
                                  onNextStep={this.props.onNextStep}/>
          </Layout.OneColumn> : null
        }
        <Layout.OneColumn spacing={40}></Layout.OneColumn>
      </Layout>
    );
  }
}

class BeneficiariesButtons extends Component {
  render() {
    const {isValid, userIdentification} = this.props;
    const hasBeneficiaryPersons = !isUndefinedOrNull(userIdentification.hasBeneficiaryPersons) && userIdentification.hasBeneficiaryPersons === booleanTypes.YES ? booleanTypes.NO : booleanTypes.YES;
    const editBeneficiaryPersons = userIdentification.hasBeneficiaryPersons === booleanTypes.YES ? booleanTypes.YES : booleanTypes.NO;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn spacing={30}></Layout.OneColumn>
        <Layout.Row>
          <Layout.Column width={50}>
            <NextStepButton onNextStep={this.props.onNextStep}
                            isValid={isValid}
                            component={ChoiceButton}
                            buttonProps={{
                              name: "hasBeneficiaryPersons",
                              isDisabled: !isValid,
                              label: userIdentification.hasBeneficiaryPersons === booleanTypes.YES ? "userIdentification.content.button.beneficiaryPersons.remove" : "userIdentification.content.button.beneficiaryPersons.add",
                              value: hasBeneficiaryPersons,
                            }}/>
          </Layout.Column>
          {userIdentification.hasBeneficiaryPersons === booleanTypes.YES ?
            <Layout.Column width={50}>
              <NextStepButton onNextStep={this.props.onNextStep}
                              isValid={isValid}
                              component={ChoiceButton}
                              buttonProps={{
                                name: "editBeneficiaryPersons",
                                isDisabled: !isValid,
                                label: "userIdentification.content.button.beneficiaryPersons.edit",
                                value: editBeneficiaryPersons,
                              }}/>
            </Layout.Column> : null}
        </Layout.Row>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => ({
  medicalCareTypeDescription: _.get(state, "flow.forms.medicalCareType.medicalCareTypeDescription") || "",
  userIdentification: _.get(state, "flow.forms.userIdentification") || {},
  inSummary: state.flow.inSummary || false
});

export default connect(mapStateToProps)(UserIdentificationInfo);
