import React, {Component} from "react";

import {connect} from "react-redux";

import {getCascoData} from "actions/flow";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import customerDataStatusTypeConstants from "constants/customerDataStatusTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import personTypeConstants from "constants/personTypes";
import wasDriverTypeConstants from "constants/wasDriverTypes";
import {ConfigContext} from "containers/context/ConfigContainer";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import UserIdentificationRight from "containers/step/userIdentification/UserIdentificationRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import {isSloAddress} from "utility/utilityFunctions";
import isUndefinedOrNull from "utility/utilityFunctions";

const taxNumberValidationSchema = (loggedInFromCounter, slovenia) => Yup.string().when("address", {
  is: address => isSloAddress(address, slovenia),
  then: getMax(loggedInFromCounter ? Yup.string().validateSloTaxNumber("validation.taxNumber.format") : Yup.string().trim().required("validation.taxNumber").validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumberSlo),
  otherwise: getMax(loggedInFromCounter ? Yup.string() : Yup.string().trim().required("validation.taxNumber"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumber),
});

const birthDateValidationSchema = (loggedInFromCounter) => Yup.mixed().when("name", {
  is: name => !loggedInFromCounter,
  then: Yup.mixed().nullable().required("validation.required")
});

const addressValidationSchema = Yup.object().shape({
  countryName: Yup.string().trim().required("validation.address.country"),
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].zip),
    city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].city),
  }),
});

const noAsValidationSchema = (slovenia, loggedInFromCounter, wasDriver) => Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.surname"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].surname),
  birthDate: birthDateValidationSchema(loggedInFromCounter),
  sex: Yup.string().trim().required("validation.required"),
  address: addressValidationSchema,
  telephoneNumber: getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.format"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].telephoneNumber),
  email: getMax(Yup.string().email("validation.email"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email),
  taxNumber: taxNumberValidationSchema(loggedInFromCounter, slovenia),
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].policyNumber),
  licenceNumber: Yup.mixed().when("name", {
    is: name => wasDriver,
    then: getMax(Yup.string().trim().required("validation.licenceNumber"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].licenceNumber),
  }),
  beneficiaryPersons: Yup.boolean(),
});

const legalPersonValidationSchema = (slovenia, loggedInFromCounter) => Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].name),
  contactPersonName: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].contactPersonName),
  address: addressValidationSchema,
  telephoneNumber: getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.format"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].telephoneNumber),
  email: getMax(Yup.string().email("validation.email"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email),
  taxNumber: taxNumberValidationSchema(loggedInFromCounter, slovenia),
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].policyNumber),
});

const userIdentificationValidationSchema = (slovenia, loggedInFromCounter) => Yup.object().shape({
  userInfo: Yup.object()
    .when(["personType", "wasDriver"], {
      is: (personType, wasDriver) => personType === personTypeConstants.NATURAL && wasDriver === wasDriverTypeConstants.YES,
      then: noAsValidationSchema(slovenia, loggedInFromCounter, true),
    })
    .when(["personType", "wasDriver"], {
      is: (personType, wasDriver) => personType === personTypeConstants.NATURAL && wasDriver !== wasDriverTypeConstants.YES,
      then: noAsValidationSchema(slovenia, loggedInFromCounter, false),
    }),
  legalPerson: Yup.object().when("personType", {
    is: personType => personType === personTypeConstants.LEGAL,
    then: legalPersonValidationSchema(slovenia, loggedInFromCounter),
  }),
});

// step 33
class UserIdentificationStepContainer extends Component {
  static contextType = RegistryContext;

  left = ({claimType, insuranceOwner, userIdentificationType, fromSummary, userDataStatus}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        {userDataStatus === customerDataStatusTypeConstants.FOUND
          ? <Text element="h2"
                  label={`userIdentification.sidebar.${claimType}.${insuranceOwner}.${userIdentificationType}.title`}/>
          : <Text element="h2" label="userIdentification.sidebar.insuranceInfo.error.title"/>
        }
        <Text element="div" label="userIdentification.sidebar.subtitle" />
      </SplitViewContainer.Left>
    );
  };


  right = (props) => {
    const {mobileUserId} = this.props;

    return (
      <SplitViewContainer.Right {...props}>
        <UserIdentificationRight mobileUserId={mobileUserId} {...props}/>
      </SplitViewContainer.Right>
    );
  };

  handleSubmit = (url) => async (values) => {

    const data = {
      chassisNumber: values.chassisNumber,
      accidentDate: values.accidentDate,
    };
    this.props.getCascoData(url.cascoData, data);
  }

  validationSchema = ({loggedInFromCounter}) =>
    userIdentificationValidationSchema(this.context.registry.slovenia, loggedInFromCounter);

  render() {
    return (
        <ConfigContext.Consumer>
          {context =>
              <SplitViewContainer left={this.left}
                                  right={this.right}
                                  validationSchema={this.validationSchema}
                                  onSubmit={this.handleSubmit(context.url)}/>
          }
        </ConfigContext.Consumer>
    );
  }

}

const mapStateToProps = (state) => ({
  step: state.flow.step,
  mobileUserId: state.flow.mobileUserId,
  inSummary: state.flow.inSummary || false,
  beneficiaryPersons: state.flow.forms.beneficiaryPersons || {},
  dirtySteps: state.flow.dirtySteps
});

const mapDispatchToProps = (dispatch) => ({
  getCascoData: (url, policyData) => dispatch(getCascoData(url, policyData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserIdentificationStepContainer);