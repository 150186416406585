import React from "react";

import {FieldArray} from "formik";
import {MobileView} from "react-device-detect";

import Layout from "components/common/Layout";
import ConditionalField from "components/form/ConditionalField";
import FormErrorMessage from "components/form/FormErrorMessage";
import UploadField from "components/form/UploadField";
import IcoMoon from "components/icon/IcoMoon";
import Documents from "components/step/Documents";
import Photos from "components/step/Photos";
import fontConstants from "constants/font";
import {UploadContext} from "containers/context/UploadContainer"
import Text from "components/common/Text";
import repairServiceTypeConstants from "constants/repairServiceTypes";

export default class DocumentsRight extends React.Component {
    render() {
        const {values, saveId, repairServiceType} = this.props;
        const {licence = [], eas = [], police = [], other = [], photos = [], photos_mobile = [], photosVehicle = [], photosDamage = [], photosKm = [], photosVin = []} = values;
        const acceptedTypes = ["doc", "docx", "pdf", "jpg", "jpeg", "bmp", "tiff", "png", "xlsx", "xls"].map(x => `.${x}`).join(", ");
        const photoSelected = repairServiceType === repairServiceTypeConstants.PHOTO;

        return (
            <Layout spacing={10} width="100%" maxWidth={450} justifyContent="center">
                <ConditionalField name="photos">
                    {
                        photoSelected === true
                            ?
                            [
                                <Layout.OneColumn>
                                    <UploadField name="photosVehicle"
                                                 label="documents.button.photosUpload.car.vehicle"
                                                 saveId={saveId}
                                                 icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                                 accept="image/*, video/mp4, video/quicktime"/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <FieldArray name="photosVehicle" render={arrayHelpers => (
                                        <Documents arrayHelpers={arrayHelpers}
                                                   documents={photosVehicle}/>
                                    )}/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <UploadField name="photosDamage"
                                                 label="documents.button.photosUpload.car.damage"
                                                 saveId={saveId}
                                                 icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                                 accept="image/*, video/mp4, video/quicktime"/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <FieldArray name="photosDamage" render={arrayHelpers => (
                                        <Documents arrayHelpers={arrayHelpers}
                                                   documents={photosDamage}/>
                                    )}/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <UploadField name="photosKm"
                                                 label="documents.button.photosUpload.car.km"
                                                 saveId={saveId}
                                                 icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                                 accept="image/*, video/mp4, video/quicktime"/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <FieldArray name="photosKm" render={arrayHelpers => (
                                        <Documents arrayHelpers={arrayHelpers}
                                                   documents={photosKm}/>
                                    )}/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <UploadField name="photosVin"
                                                 label="documents.button.photosUpload.car.vin"
                                                 saveId={saveId}
                                                 icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                                 accept="image/*, video/mp4, video/quicktime"/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <FieldArray name="photosVin" render={arrayHelpers => (
                                        <Documents arrayHelpers={arrayHelpers}
                                                   documents={photosVin}/>
                                    )}/>
                                </Layout.OneColumn>
                            ]
                            :
                            [
                                <Layout.OneColumn>
                                    <UploadField name="photos"
                                                 label="documents.button.photosUpload"
                                                 saveId={saveId}
                                                 icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                                 accept="image/*, video/mp4, video/quicktime"/>
                                </Layout.OneColumn>,
                                <Layout.OneColumn>
                                    <FieldArray name="photos" render={arrayHelpers => (
                                        <Photos arrayHelpers={arrayHelpers}
                                                photos={photos}
                                                group="homePhotosGroup"/>
                                    )}/>
                                </Layout.OneColumn>
                            ]
                    }
                    <MobileView>
                        <Layout.OneColumn spacing={10} width="100%" maxWidth={450} justifyContent="center">
                            <UploadField name="photos_mobile"
                                         label="documents.button.takePhoto"
                                         saveId={saveId}
                                         icon={<IcoMoon icon={fontConstants.ICOMOON.CAMERA}/>}
                                         accept="image/*, video/mp4, video/quicktime"/>
                        </Layout.OneColumn>
                        <Layout.OneColumn spacing={10} width="100%" maxWidth={450} justifyContent="center">
                            <FieldArray name="photos_mobile" render={arrayHelpers => (
                                <Photos arrayHelpers={arrayHelpers}
                                        photos={photos_mobile}
                                        group="homePhotosGroup"/>
                            )}/>
                        </Layout.OneColumn>
                    </MobileView>
                </ConditionalField>
                <ConditionalField name="documents">
                    <Layout.OneColumn>
                        <UploadField name="licence"
                                     label="documents.button.uploadLicence"
                                     saveId={saveId}
                                     icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                     accept={acceptedTypes}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <FieldArray name="licence" render={arrayHelpers => (
                            <Documents arrayHelpers={arrayHelpers}
                                       documents={licence}/>
                        )}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <UploadField name="eas"
                                     label="documents.button.uploadEas"
                                     saveId={saveId}
                                     icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                     accept={acceptedTypes}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <FieldArray name="eas" render={arrayHelpers => (
                            <Documents arrayHelpers={arrayHelpers}
                                       documents={eas}/>
                        )}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <UploadField name="police"
                                     label="documents.button.uploadPolice"
                                     saveId={saveId}
                                     icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                     accept={acceptedTypes}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <FieldArray name="police" render={arrayHelpers => (
                            <Documents arrayHelpers={arrayHelpers}
                                       documents={police}/>
                        )}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <UploadField name="other"
                                     label="documents.button.uploadOther"
                                     saveId={saveId}
                                     icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                                     accept={acceptedTypes}/>
                    </Layout.OneColumn>
                    <Layout.OneColumn>
                        <FieldArray name="other" render={arrayHelpers => (
                            <Documents arrayHelpers={arrayHelpers}
                                       documents={other}/>
                        )}/>
                    </Layout.OneColumn>
                </ConditionalField>
                <UploadContext.Consumer>
                    {context => (<FormErrorMessage error={context.error} showError={context.error.length > 0}/>)}
                </UploadContext.Consumer>
                {(photoSelected === true && (photosVin.length === 0 || licence.length === 0 || photosVehicle.length === 0 || photosDamage.length === 0 || photosKm.length === 0)) ?
                    <Layout.OneColumn>
                        <Text element="span" variant="variant8" label="documents.label.notAllDocuments.prefix" />
                        {photosVehicle.length === 0 ?
                            <Text element="span" variant="variant8" label="documents.label.notAllDocuments.photosVehicle" parameters={' '}/>
                            :
                            null
                        }
                        {photosDamage.length === 0 ?
                            <Text element="span" variant="variant8" label="documents.label.notAllDocuments.photosDamage" parameters={photosVehicle.length === 0 ? [", "] : [" "]}/>
                            :
                            null
                        }
                        {photosKm.length === 0 ?
                            <Text element="span" variant="variant8" label="documents.label.notAllDocuments.photosKm" parameters={photosVehicle.length === 0  || photosDamage.length === 0 ? [", "] : [" "]}/>
                            :
                            null
                        }
                        {photosVin.length === 0 ?
                            <Text element="span" variant="variant8" label="documents.label.notAllDocuments.photosVin" parameters={photosVehicle.length === 0  || photosDamage.length === 0 || photosKm.length === 0 ? [", "] : [" "]}/>
                            :
                            null
                        }
                        {licence.length === 0 ?
                            <Text element="span" variant="variant8" label="documents.label.notAllDocuments.licence" parameters={photosVehicle.length === 0  || photosDamage.length === 0 || photosKm.length === 0 || photosVin.length === 0 ? [", "] : [" "]}/>
                            :
                            null
                        }
                        <Text element="span" variant="variant8" label="documents.label.notAllDocuments.sufix" />
                    </Layout.OneColumn>
                    :
                    null
                }
            </Layout>
        );
    }
}