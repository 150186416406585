import React from "react";

import Text from "components/common/Text";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryApplicant() {
  return (
    <Summary title="summary.content.applicantData.title"
             onePart={true}
             refName={summaryRefConstants.APPLICANT_DATA.REF}>

      <Summary.ItemValue label="summary.content.applicantData.insuranceOwner"
                         formName={{name: flowConstants.steps.INSURANCE_OWNER.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.INSURANCE_OWNER}>
        {form => <Text label={`summary.content.common.boolean.${form.owner}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.name.driver"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.NAME}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.name}
      </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.applicantData.name.general"
                           formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                           refName={summaryRefConstants.APPLICANT_DATA.NAME}
                           condition={form => !form.wasDriver}>
            {form => form.applicantData.name}
        </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.surname"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.SURNAME}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.surname}
      </Summary.ItemValue>

      <Summary.ItemValue label="sex.summary.label"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.SEX}
                         condition={form => form.wasDriver}>
          {form => <Text label={`sex.${form.applicantData.sex}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.birthDate"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.BIRTH_DATE}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.birthDate}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.country"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.COUNTRY}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.country}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.cityAndZip"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.CITY_AND_ZIP}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.cityAndZip}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.streetAndStreetNo"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.STREET_AND_NUMBER}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.streetAndStreetNo}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.telephoneNumber"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.TELEPHONE_NUMBER}>
        {form => form.applicantData.telephoneNumber}
      </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.applicantData.email"
                           formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                           refName={summaryRefConstants.APPLICANT_DATA.EMAIL}>
            {form => form.applicantData.email}
        </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.applicantData.licenceNumber"
                         formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                         refName={summaryRefConstants.APPLICANT_DATA.LICENCE_NUMBER}
                         condition={form => form.wasDriver}>
        {form => form.applicantData.licenceNumber}
      </Summary.ItemValue>
        <Summary.ItemValue label="summary.content.applicantData.taxNumber"
                           formName={{name: flowConstants.steps.APPLICANT_DATA.NAME}}
                           refName={summaryRefConstants.APPLICANT_DATA.TAX_NUMBER}>
            {form => form.applicantData.taxNumber}
        </Summary.ItemValue>
    </Summary>
  );
}