import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";

export default function BankAccountRepairShop(props) {
    return (
        <Layout spacing={5} width="100%" maxWidth={440} justifyContent="center">
            <Layout.OneColumn spacing={25}/>
            <Layout.OneColumn>
                <Text element="span" variant="variant2" label="bankAccounts.content.disclaimer" />
            </Layout.OneColumn>
        </Layout>
    );
}