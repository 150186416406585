import React, {Component} from "react";

import Asdom from "components/icon/Asdom";
import CheckBoxTreeField from "components/form/CheckBox/CheckBoxTreeField";
import homeDamageTypeConstants from "constants/homeDamageTypes";
import Layout from "components/common/Layout";
import floodIcon from "images/flood-icon.svg";

import {isIE} from 'react-device-detect';

import styles from "containers/step/homeDamageType/HomeDamageTypeRight.module.scss";

export default class HomeDamageTypeRight extends Component {
  render() {
    return (
      <Layout>
        {isIE
        ?
          <Layout.OneColumn rowClassName={styles.ieLgScreen} />
        : null
        }
        <Layout.OneColumn flex={1}>
          <Layout.OneRow spacing={10} width="100%" maxWidth={490} justifyContent="center">
            <CheckBoxTreeField name="homeDamageType" multipleOptionChildren={true}>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.NAME}`}
                name={homeDamageTypeConstants.FLOOD_WATER.NAME}
                icon={<img src={floodIcon} style={{width: "37px", height:"37px", position:"relative", top: "3px"}} alt="flood icon" />}>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.BURST.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.BURST.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.OPEN_FAUCET.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.OPEN_FAUCET.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.CLOGGING.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.CLOGGING.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.DURABILITY.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.DURABILITY.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.DEVICE_FAILURE.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.DEVICE_FAILURE.NAME}/>
                <CheckBoxTreeField.Option
                    label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.INTRUSION_OF_WATER.NAME}`}
                    name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.INTRUSION_OF_WATER.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FLOOD_WATER.CHILDREN.OTHER_WATER.NAME}`}
                  name={homeDamageTypeConstants.FLOOD_WATER.CHILDREN.OTHER_WATER.NAME}/>
              </CheckBoxTreeField.Option>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.FIRE.NAME}`}
                name={homeDamageTypeConstants.FIRE.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.FIRE.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.DESTRUCTION.NAME}`}
                name={homeDamageTypeConstants.DESTRUCTION.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.DESTRUCTION.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.BURGLARY.NAME}`}
                name={homeDamageTypeConstants.BURGLARY.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.BURGLARY.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.NAME}`}
                name={homeDamageTypeConstants.NATURAL_DISASTER.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.NATURAL_DISASTER.ICON}/>}>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.STORM.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.STORM.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.NAME}>
                  <CheckBoxTreeField.Option
                    label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_DIRECT.NAME}`}
                    name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_DIRECT.NAME}/>
                  <CheckBoxTreeField.Option
                    label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_INDIRECT.NAME}`}
                    name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LIGHTNING.CHILDREN.LIGHTNING_INDIRECT.NAME}/>
                </CheckBoxTreeField.Option>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.HAIL.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.HAIL.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.FLOOD.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.FLOOD.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.STORM_WATER_INTRUSION.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.STORM_WATER_INTRUSION.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.SNOW_WEIGHT.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.SNOW_WEIGHT.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.SLEET.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.SLEET.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LAND_SLIDE.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.LAND_SLIDE.NAME}/>
                <CheckBoxTreeField.Option
                  label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.TREE_FALL.NAME}`}
                  name={homeDamageTypeConstants.NATURAL_DISASTER.CHILDREN.TREE_FALL.NAME}/>
              </CheckBoxTreeField.Option>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.BREAKDOWN.NAME}`}
                name={homeDamageTypeConstants.BREAKDOWN.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.BREAKDOWN.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.VEHICLE_IMPACT.NAME}`}
                name={homeDamageTypeConstants.VEHICLE_IMPACT.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.VEHICLE_IMPACT.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.EARTHQUAKE.NAME}`}
                name={homeDamageTypeConstants.EARTHQUAKE.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.EARTHQUAKE.ICON}/>}/>
              <CheckBoxTreeField.Option
                label={`homeDamageType.content.checkBoxTree.${homeDamageTypeConstants.OTHER.NAME}`}
                name={homeDamageTypeConstants.OTHER.NAME}
                icon={<Asdom icon={homeDamageTypeConstants.OTHER.ICON}/>}/>
            </CheckBoxTreeField>
          </Layout.OneRow>
        </Layout.OneColumn>
      </Layout>
    )
  }
}
