import fontsConstants from "constants/font";

export default {
  HOUSES_AND_BUILDINGS: {
    NAME: "housesAndBuildings",
    ICON: fontsConstants.ASDOM_ICON.HOUSE,
    CHILDREN: {
      ROOF: {
        NAME: "roof",
      },
      FACADE: {
        NAME: "facade",
      },
      WINDOWS_AND_DOORS: {
        NAME: "windowsAndDoors",
      },
      HOUSE_WALLS: {
        NAME: "houseWalls",
      },
      HOUSE_INSTALLATIONS: {
        NAME: "houseInstallations",
      },
      SOLAR_COLLECTORS: {
        NAME: "solarCollectors",
      },
      SHADES: {
        NAME: "shades",
      },
      FLOOR_COVERING: {
        NAME: "floorCovering"
      },
      HOUSE_OTHER: {
        NAME: "houseOther",
      },
    },
  },
  AUXILIARY: {
    NAME: "auxiliary",
    ICON: fontsConstants.ASDOM_ICON.SWIMMING_POOL,
    CHILDREN: {
      STORAGE: {
        NAME: "storage",
      },
      SUMMER_KITCHEN: {
        NAME: "summerKitchen",
      },
      SWIMMING_POOL: {
        NAME: "swimmingPool",
      },
      AUXILIARY_WALLS: {
        NAME: "auxiliaryWall",
      },
      WINTER_GARDENS: {
        NAME: "winterGardens",
      },
      ACCOMMODATION: {
        NAME: "accommodation",
      },
      AUXILIARY_OTHER: {
        NAME: "auxiliaryOther",
      },
    },
  },
  FURNISHINGS: {
    NAME: "furnishings",
    ICON: fontsConstants.ASDOM_ICON.SOFA,
    CHILDREN: {
      FURNITURE: {
        NAME: "furniture",
      },
      HOUSING_OBJECTS: {
        NAME: "housingObjects",
      },
      FURNISHINGS_SANITARY_CERAMICS: {
        NAME: "furnishingsSanitaryCeramics",
      },
      ART: {
        NAME: "art",
      },
      VALUABLES: {
        NAME: "valuables",
      },
      FURNISHINGS_DEVICES: {
        NAME: "furnishingsDevices",
      },
      STOCKS_OF_LEGAL_ENTITIES: {
        NAME: "stocksOfLegalEntities",
      },
      FURNISHINGS_OTHER: {
        NAME: "furnishingsOther",
      },
    },
  },
  PERSONAL_ITEMS: {
    NAME: "personalItems",
    ICON: fontsConstants.OSEBA_ICON.BOX,
    CHILDREN: {
      PERSONAL_ITEMS_VALUABLES: {
        NAME: "personalItemsValuables",
      },
      CLOTHES: {
        NAME: "clothes",
      },
      SPORT_EQUIPMENT: {
        NAME: "sportEquipment"
      },
      PERSONAL_ITEMS_OTHER: {
        NAME: "personalItemsOther",
      }
    }
  },
  FARM: {
    NAME: "farm",
    ICON: fontsConstants.ASDOM_ICON.FARM,
    CHILDREN: {
      AGRICULTURE_OBJECTS: {
        NAME: "agricultureObjects",
      },
      AGRICULTURE: {
        NAME: "agriculture",
      },
      AGRICULTURAL_EQUIPMENT: {
        NAME: "agriculturalEquipment",
      },
      ANIMALS: {
        NAME: "animals",
      },
      MACHINERY: {
        NAME: "machinery"
      },
      HAY: {
        NAME: "hay"
      },
      FARM_OTHER: {
        NAME: "farmOther",
      },
    },
  },
  GLASS_AND_CERAMICS: {
    NAME: "glassAndCeramics",
    ICON: fontsConstants.ASDOM_ICON.WINDOW,
    CHILDREN: {
      GLASS_WINDOW_DOOR: {
        NAME: "glassWindowDoor",
      },
      INDUCTION_PLATE: {
        NAME: "inductionPlate",
      },
      GLASS_SANITARY_CERAMICS: {
        NAME: "glassSanitaryCeramics",
      },
      GLASS_FURNITURE: {
        NAME: "glassFurniture",
      },
      GLASS_PARTITIONS: {
        NAME: "glassPartitions",
      },
      GLASS_OTHER: {
        NAME: "glassOther",
      },
    },
  },
  DEVICES: {
    NAME: "devices",
    ICON: fontsConstants.ASDOM_ICON.WASHING_MACHINE,
    CHILDREN: {
      APPLIANCES: {
        NAME: "appliances",
      },
      ELECTRONIC_EQUIPMENT: {
        NAME: "electronicEquipment",
      },
      HEATING_SYSTEMS: {
        NAME: "heatingSystems",
      },
      AIR_CONDITIONING: {
        NAME: "airConditioning",
      },
      INSTALLATIONS: {
        NAME: "installations",
      },
      DEVICES_OTHER: {
        NAME: "devicesOther",
      },
    },
  },
  PERSON: {
    NAME: "person",
    ICON: fontsConstants.ASDOM_ICON.PERSON,
  },
  OTHER: {
    NAME: "other",
    ICON: fontsConstants.ASDOM_ICON.OTHER,
  },
}
