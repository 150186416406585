import React from "react";

import cx from "classnames";
import styles from "components/icon/IcoMoon.module.scss";

export default function IcoMoon({ icon, variant }) {
  const className = cx({
    [styles.root]: true,
    [styles.variant1]: variant === "variant1",
  });

  return <div className={className}>{icon}</div>;
}
