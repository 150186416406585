import * as dateFns from "date-fns/esm";
import _ from "lodash";
import {contains, filter, find, isArray, isEmpty, isUndefined, keys, some} from "underscore";

import accidentDateTypesConstants from "constants/accidentDateTypes";
import booleanTypesConstants from "constants/booleanTypes";
import carDamageTypes from "constants/carDamageTypes";
import flowConstants from "constants/flow";
import homeDamagedGoodsInfoTypes from "constants/homeDamagedGoodsInfoTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import medicalCareTypes from "constants/medicalCareTypes";
import otherPersonRelationshipTypes from "constants/otherPersonRelationshipTypes";
import personTypesConstants from "constants/personTypes";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import responsiblePersonConstants from "constants/responsiblePerson";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import sexTypeConstants from "constants/sex";
import summaryConstants from "constants/summary";
import vehicleTypesConstants from "constants/vehicleTypes";
import wasDriverTypeConstants from "constants/wasDriverTypes";
import isUndefinedOrNull from "utility/utilityFunctions"
import {getAccidentDate} from "selectors/flow/getSteps";
import filterParticipantsInfo, {
  formatParticipantAddress,
  formatParticipantNameAndSurname,
  formatParticipantsData
} from "selectors/participantsInfo";

export default function calculateSummary(forms, steps = null, fieldConfigurations = {}) {
  const summary = {
    // step 2
    ...forForm(forms, flowConstants.steps.CLAIM_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.CLAIM_TYPE.NAME},
      type: form.choiceType, // Kaj je poškodovano?
      otherChoice: form.otherChoice,
    })),

    // step 5
    ...forForm(forms, flowConstants.steps.CAR_DAMAGE_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.CAR_DAMAGE_TYPE.NAME},
      type: form.carDamageType, // Kaj se je zgodilo?
      otherName: form.otherName,
    })),

    // step 5.3
    ...forForm(forms, flowConstants.steps.THEFT_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.THEFT_TYPE.NAME},
      theftType: form.theftType, // Kakšna tatvina se je zgodila?
    })),

    // step 6
    ...forForm(forms, flowConstants.steps.ACCIDENT_DATE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.ACCIDENT_DATE.NAME},
      date: accidentDate(form), // Datum nesreče
    })),

    // step 7
    ...forForm(forms, [flowConstants.steps.ACCIDENT_TIME.NAME, flowConstants.steps.ACCIDENT_DATE.NAME])(
      ([accidentTimeForm, accidentDateForm]) => ({
        jumpTo: {
          name: flowConstants.steps.ACCIDENT_TIME.NAME,
          accidentDateType: accidentDateForm.accidentDateType,
        },
        ...accidentTime(accidentTimeForm), // Čas nesreče
      })),

    // step 8
    ...forForm(forms, flowConstants.steps.ACCIDENT_LOCATION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.ACCIDENT_LOCATION.NAME},
      ...accidentLocation(form),
    })),

    ...forForm(forms, flowConstants.steps.VEHICLE_LOCATION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.VEHICLE_LOCATION.NAME},
      ...accidentLocation(form),
    })),

    // step 9
    ...forForm(forms, flowConstants.steps.VEHICLE_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.VEHICLE_TYPE.NAME},
      vehicleType: form.vehicleType,
    })),

    // step 9
    ...forForm(forms, flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.VEHICLE_DAMAGE_CURRENT.NAME},
      ...vehicleDamage(form),
    })),

    // step 9
    ...forForm(forms, flowConstants.steps.VEHICLE_DAMAGE_CURRENT_DESCRIPTION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.VEHICLE_DAMAGE_CURRENT_DESCRIPTION.NAME},
      vehicleDamageCurrentDescription: form.vehicleDamageCurrentDescription,
    })),

    // step 11
    ...forForm(forms, flowConstants.steps.PARKED.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.PARKED.NAME},
      parked: form.parked, // Je bilo vozilo parkirano ali ste se vozili?
    })),

    // step 12
    ...forForm(forms, flowConstants.steps.PARTICIPANTS_NUMBER.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME},
      ...form,
    })),

    // step 13
    ...forForm(forms, flowConstants.steps.DRAWING_INTRO.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.DRAWING_INTRO.NAME},
      ...form,
    })),

    // step 14
    ...forForm(forms, [flowConstants.steps.DRAWING.NAME, flowConstants.steps.DRAWING_INTRO.NAME])(
      ([drawingForm, drawingIntroForm]) => ({
        jumpTo: {name: flowConstants.steps.DRAWING.NAME},
        sketch: drawingForm.sketch, // Prikaz nesreče
        wantDrawing: drawingIntroForm.wantDrawing,
      })),

    // step 15
    ...forForm(forms, flowConstants.steps.EVENT_DESCRIPTION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.EVENT_DESCRIPTION.NAME},
      description: form.description, // Opis dogodka
    })),

    // step 16-18
    ...forForm(forms, [flowConstants.steps.PARTICIPANTS_INFO.NAME, flowConstants.steps.PARTICIPANTS_NUMBER.NAME, flowConstants.steps.ACCIDENT_DATE.NAME, flowConstants.steps.CAR_DAMAGE_TYPE.NAME])(
      ([participantsInfoForm, participantsNumberForm, accidentDateForm, carDamageTypeForm]) => (
        participantsInfo(flowConstants.steps.PARTICIPANTS_INFO.NAME, participantsInfoForm, participantsNumberForm,
          accidentDateForm, carDamageTypeForm)
      )),

    ...forForm(forms, [flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME, flowConstants.steps.PARTICIPANTS_NUMBER.NAME,
      flowConstants.steps.ACCIDENT_DATE.NAME, flowConstants.personSteps.DAMAGES_CLAIM_FILED.NAME, flowConstants.personSteps.ACCIDENT_INVOLVED.NAME])(
      ([personParticipantsInfoForm, participantsNumberForm, accidentDateForm, damagesClaimFiledForm, accidentInvolvedForm]) => (
        personParticipantsInfo(flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME, personParticipantsInfoForm,
          participantsNumberForm, accidentDateForm, damagesClaimFiledForm, accidentInvolvedForm)
      )),

    // step 19,
    ...forForm(forms, [flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME, flowConstants.steps.PARTICIPANTS_INFO.NAME])(
      ([ownVehicleInfoWasDriverForm, participantsInfoForm]) => ({
        jumpTo: {
          name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME,
          participant: ownParticipant(participantsInfoForm)
        },
        wasDriver: ownVehicleInfoWasDriverForm.wasDriver, // Ali ste bili ob prometni nesreči vi voznik navedenega vozila?
        ...getOtherDriverInfo(ownVehicleInfoWasDriverForm),
      })),

    ...forForm(forms, [flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME, flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME,
      flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])(
      ([ownVehicleInfoWasDriverForm, personParticipantsInfoForm, medicalCareTypeForm]) => ({
        jumpTo: {
          name: flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME,
          medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription,
          participant: ownParticipant(personParticipantsInfoForm)
        },
        wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
        ...getOtherDriverInfo(ownVehicleInfoWasDriverForm),
      })),

    // step 20,
    ...forForm(forms,
      [
        flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME,
        flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME,
        flowConstants.steps.PARTICIPANTS_INFO.NAME
      ]
    )(
      ([ownVehicleInfoDriverHurtForm, ownVehicleInfoWasDriverForm, participantsInfoForm]) => ({
        jumpTo: {
          name: flowConstants.steps.OWN_VEHICLE_INFO_DRIVER_HURT.NAME,
          participant: ownParticipant(participantsInfoForm)
        },
        wasDriver: ownVehicleInfoWasDriverForm.wasDriver,
        driverHurt: ownVehicleInfoDriverHurtForm.driverHurt, // Ali ste bili kot voznik telesno poškodovani v prometni nesreči?
      })),

    // step 21,
    ...forForm(forms, [flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME, flowConstants.steps.PARTICIPANTS_INFO.NAME])(
      ([ownVehicleInfoReportedPoliceForm, participantsInfoForm]) => ({
        jumpTo: {
          name: flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME,
          participant: ownParticipant(participantsInfoForm)
        },
        reportedPolice: ownVehicleInfoReportedPoliceForm.reportedPolice, // Ali ste nesrečo prijavili policiji?
        reportedPoliceStation: ownVehicleInfoReportedPoliceForm.reportedPoliceStation,
      })),

    // step 22,
    ...forForm(forms, [flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME, flowConstants.steps.PARTICIPANTS_INFO.NAME])(
      ([ownVehicleInfoPassengersForm, participantsInfoForm]) => ({
        jumpTo: {
          name: flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME,
          participant: ownParticipant(participantsInfoForm)
        },
        exist: ownVehicleInfoPassengersForm.exist, // Ali so bili v vašem vozilu sopotniki?
        passengers: ownVehicleInfoPassengersForm.finalPassengers.map(formatParticipantNameAndSurname),
        ...determineHurt(ownVehicleInfoPassengersForm.finalPassengers),
      })),

    // step 24
    ...forForm(
      forms,
      [
        flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME,
        flowConstants.steps.PARTICIPANTS_NUMBER.NAME,
        flowConstants.steps.PARTICIPANTS_INFO.NAME,
      ]
    )(
      ([otherParticipantsInfoForm, participantsNumberForm, participantsInfoForm]) => (
        otherParticipantsInfo(otherParticipantsInfoForm, participantsNumberForm, participantsInfoForm, fieldConfigurations)
      )),

    ...forForm(
      forms,
      [
        flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME,
        flowConstants.steps.PARTICIPANTS_NUMBER.NAME,
        flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME,
      ]
    )(
      ([otherParticipantsInfoForm, participantsNumberForm, participantsInfoForm]) => (
        otherParticipantsInfo(otherParticipantsInfoForm, participantsNumberForm, participantsInfoForm, fieldConfigurations)
      )),

    // step 28,
    ...forForm(forms, [flowConstants.steps.RESPONSIBLE_PERSON.NAME, flowConstants.steps.PARTICIPANTS_INFO.NAME, flowConstants.steps.PARTICIPANTS_NUMBER.NAME])(
      ([responsiblePersonForm, participantsInfoForm, participantsNumberForm]) => ({
        jumpTo: {name: flowConstants.steps.RESPONSIBLE_PERSON.NAME, participant: ownParticipant(participantsInfoForm)},
        responsiblePerson: responsiblePerson(participantsInfoForm, responsiblePersonForm.responsiblePerson), // POVZROČITELJ NESREČE
        multipleParticipants: participantsNumberForm.finalMultipleParticipants,
      })),

    ...forForm(forms, [flowConstants.steps.RESPONSIBLE_PERSON.NAME, flowConstants.personSteps.PERSON_PARTICIPANTS_INFO.NAME, flowConstants.steps.PARTICIPANTS_NUMBER.NAME])(
      ([responsiblePersonForm, personParticipantsInfoForm, participantsNumberForm]) => ({
        jumpTo: {
          name: flowConstants.steps.RESPONSIBLE_PERSON.NAME,
          participant: ownParticipant(personParticipantsInfoForm)
        },
        responsiblePerson: responsiblePerson(personParticipantsInfoForm, responsiblePersonForm.responsiblePerson),
        multipleParticipants: participantsNumberForm.finalMultipleParticipants,
      })),

    // step 30,
    ...forForm(forms, flowConstants.steps.DOCUMENTS.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.DOCUMENTS.NAME},
      documents: [
        ...(form.licence || []),
        ...(form.eas || []),
        ...(form.police || []),
        ...(form.other || []),
      ],
      photos: [
        ...(form.photos || []),
        ...(form.photos_mobile || []),
        ...(form.photosVehicle || []),
        ...(form.photosDamage || []),
        ...(form.photosKm || []),
        ...(form.photosVin || [])
      ],
    })),

    ...forForm(forms, flowConstants.generalSteps.GENERAL_DOCUMENTS.NAME)(form => ({
      jumpTo: {name: flowConstants.generalSteps.GENERAL_DOCUMENTS.NAME},
      documents: [
        ...(form.documents || []),
      ],
      photos: form.photos || [],
    })),

    // step 31,
    ...forForm(forms, flowConstants.steps.MAPS_INTRO.NAME)(form => {

      return {
        jumpTo: {name: flowConstants.steps.MAPS_INTRO.NAME},
        repairServiceType: form.repairServiceType,
      }

    }),

    // step 32,
    ...forForm(forms, [flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME, flowConstants.steps.MAPS_INTRO.NAME])(([vehicleDamageInspectionPointForm, mapsIntroForm]) => {
      const {repairServiceType} = mapsIntroForm;

      return {
        jumpTo: {
          name: flowConstants.steps.VEHICLE_DAMAGE_INSPECTION_POINT.NAME,
          repairServiceType,
        },
        name: _.at(vehicleDamageInspectionPointForm, `${repairServiceType}.name`), // Ime servisa
        address: locationAddress(vehicleDamageInspectionPointForm[repairServiceType] || {}), // Naslov
        phoneNumber: _.at(vehicleDamageInspectionPointForm, `${repairServiceType}.phoneNumber`)[0],
        email: _.at(vehicleDamageInspectionPointForm, `${repairServiceType}.email`)[0],
        url: _.at(vehicleDamageInspectionPointForm, `${repairServiceType}.url`)[0],
      }
    }),

    ...forForm(forms, flowConstants.steps.INSURANCE_OWNER.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.INSURANCE_OWNER.NAME},
      owner: form.owner,
    })),

    ...forForm(forms, flowConstants.steps.APPLICANT_DATA.NAME)(form => {
      const wasDriver = form.wasDriver === wasDriverTypeConstants.YES;

      return {
        jumpTo: {name: flowConstants.steps.APPLICANT_DATA.NAME},
        wasDriver,
        applicantData: formatApplicantData(form, wasDriver),
      }
    }),

    ...forForm(forms, flowConstants.steps.HAS_CASCO.NAME)(form => {
      const hasCasco = form.hasCasco;

      return {
        jumpTo: {name: flowConstants.steps.HAS_CASCO.NAME},
        hasCasco,
      }
    }),

    // step 33,
    ...forForm(forms, flowConstants.steps.USER_IDENTIFICATION.NAME)(form => {
      const wasDriver = form.wasDriver === wasDriverTypeConstants.YES;
      const finalUserInfo = form.finalUserInfo;
      const policyNumber = finalUserInfo.policyNumberSelected ? finalUserInfo.policyNumberSelected
        : finalUserInfo.policyNumber;

      return {
        jumpTo: {name: flowConstants.steps.USER_IDENTIFICATION.NAME},
        wasDriver,
        isLegalPerson: form.personType === personTypesConstants.LEGAL,
        ...finalUserInfo,
        email: setUnknownValue(finalUserInfo.email),
        telephoneNumber: setUnknownValue(finalUserInfo.telephoneNumber),
        contactPersonName: setUnknownValue(finalUserInfo.contactPersonName),
        taxNumber: setUnknownValue(finalUserInfo.taxNumber),
        policyNumber: setUnknownValue(policyNumber),
        relationship: !isEmpty(finalUserInfo.relationship) ? finalUserInfo.relationship : "unknown",
      }
    }),

    // step 34,
    ...forForm(forms, flowConstants.steps.BANK_ACCOUNTS.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.BANK_ACCOUNTS.NAME},
      ...repairShopNameAndAddress(form),
      ...form
    })),

    // homeStep 3
    ...forForm(forms, flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_DAMAGE_TYPE.NAME},
      finalSelected: form.finalSelected,
    })),

    // homeStep 4
    ...forForm(forms, flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME},
      selected: form.homeDamagedGoods.selected,
    })),

    // homeStep
    ...forForm(forms, flowConstants.homeSteps.HOME_BURGLARY_WHO.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_BURGLARY_WHO.NAME},
      description: form.description,
    })),

    // homeStep
    ...forForm(forms, flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME},
      description: form.description,
    })),

    // homeStep
    ...forForm(forms, flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME},
      description: form.description,
      tracesOfBurglary: form.tracesOfBurglary,
    })),

    // homeStep 5
    ...forForm(forms, flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME},
      homeDamagedGoodsInfoType: form.homeDamagedGoodsInfoType,
      description: form.homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypes.ESTIMATE ? form.estimateDescription : form.listDescription,
      damagedGoods: form.damagedGoods,
      damageEstimate: form.damageEstimate,
    })),

    // homeStep 6
    ...forForm(forms, flowConstants.steps.ACCIDENT_DATE.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.ACCIDENT_DATE.NAME},
      date: accidentDate(form),
    })),

    // homeStep 7
    ...forForm(forms, [flowConstants.steps.ACCIDENT_TIME.NAME, flowConstants.steps.ACCIDENT_DATE.NAME])(
      ([accidentTimeForm, accidentDateForm]) => ({
        jumpTo: {
          name: flowConstants.steps.ACCIDENT_TIME.NAME,
          accidentDateType: accidentDateForm.accidentDateType,
        },
        ...accidentTime(accidentTimeForm), // Čas nesreče
      })),

    // homeStep 8
    ...forForm(forms, flowConstants.steps.ACCIDENT_LOCATION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.ACCIDENT_LOCATION.NAME},
      ...accidentLocation(form),
    })),

    // homeStep 8
    ...forForm(forms, flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME},
      ...form,
    })),

    // homeStep 10
    ...forForm(forms, flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME},
      reportedToPolice: form.reportedToPolice,
      policeStation: form.policeStation,
      witnessesPresent: form.witnessesPresent,
      witnesses: form.witnesses.map(formatWitnessData),
    })),

    // homeStep
    ...forForm(forms, flowConstants.homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME},
      ...form,
    })),

    // homeStep 11
    ...forForm(forms, flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME},
      responsiblePersonChoice: form.responsiblePersonChoice,
      responsiblePerson: formatHomeResponsiblePerson(form),
    })),

    // homeStep 13
    ...forForm(forms, flowConstants.homeSteps.HOME_DOCUMENTS.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_DOCUMENTS.NAME},
      documents: [
        ...(form.receipts || []),
        ...(form.police || []),
        ...(form.listOfDamagedItems || []),
        ...(form.other || []),
      ],
      photos: form.photos || [],
    })),

    // homeStep 14
    ...forForm(forms, flowConstants.homeSteps.HOME_CALCULATION_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.homeSteps.HOME_CALCULATION_TYPE.NAME},
      calculationType: form.calculationType,
    })),

    ...forForm(forms, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME},
      medicalCareType: form.medicalCareType,
      medicalCareTypeDescription: form.medicalCareTypeDescription
    })),

    ...forForm(forms, flowConstants.personSteps.SUFFERED_MEDICAL_COSTS.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.SUFFERED_MEDICAL_COSTS.NAME},
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.personSteps.MEDICAL_OPERATION_NEEDED.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.MEDICAL_OPERATION_NEEDED.NAME},
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME},
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME},
      pastMedicalConditions: form.pastMedicalConditions,
      description: form.description,
    })),

    ...forForm(forms, [flowConstants.steps.ACCIDENT_DATE.NAME, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])
    (([accidentDateForm, medicalCareTypeForm]) => ({
      jumpTo: {
        name: flowConstants.steps.ACCIDENT_DATE.NAME,
        medicalCareType: medicalCareTypeForm.medicalCareType,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      },
      date: accidentDate(accidentDateForm),
      medicalCareType: medicalCareTypeForm.medicalCareType,
      medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
    })),

    ...forForm(forms, [flowConstants.steps.ACCIDENT_TIME.NAME,
      flowConstants.steps.ACCIDENT_DATE.NAME,
      flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])
    (([accidentTimeForm, accidentDateForm, medicalCareTypeForm]) => ({
      jumpTo: {
        name: flowConstants.steps.ACCIDENT_TIME.NAME,
        accidentDateType: accidentDateForm.accidentDateType
      },
      ...accidentTime(accidentTimeForm),
      medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
    })),

    ...forForm(forms, flowConstants.personSteps.SICKNESS_DESCRIPTION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.SICKNESS_DESCRIPTION.NAME},
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.steps.ACCIDENT_LOCATION.NAME)(form => ({
      jumpTo: {name: flowConstants.steps.ACCIDENT_LOCATION.NAME},
      ...accidentLocation(form),
    })),

    ...forForm(forms, flowConstants.personSteps.PLACE_OF_DEATH.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.PLACE_OF_DEATH.NAME},
      placeOfDeath: form.placeOfDeath,
      description: form.description
    })),

    ...forForm(forms, flowConstants.personSteps.PERSON_DAMAGE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.PERSON_DAMAGE.NAME},
      personDamage: form.personDamage
    })),

    ...forForm(forms, flowConstants.personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME},
      accidentOccurrenceType: form.accidentOccurrenceType,
    })),

    ...forForm(forms, flowConstants.personSteps.CAUSE_OF_DEATH.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.CAUSE_OF_DEATH.NAME},
      causeOfDeath: form.causeOfDeath,
      description: form.description,
    })),

    ...forForm(forms, flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME},
      reportedToPolice: form.reportedToPolice,
      policeStation: setUnknownValue(form.policeStation),
    })),

    ...forForm(forms, flowConstants.personSteps.WITNESSES_PRESENT.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.WITNESSES_PRESENT.NAME},
      witnessesPresent: form.witnessesPresent,
      witnesses: form.witnesses.map(formatWitnessData),
    })),

    ...forForm(forms, flowConstants.personSteps.HAD_OPERATION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.HAD_OPERATION.NAME},
      hadOperation: form.hadOperation,
    })),

    ...forForm(forms, flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME},
      ...formatDeceasedPersonInformation(form),
    })),

    ...forForm(forms, flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME},
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.personSteps.PERSON_INSURANCE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.PERSON_INSURANCE.NAME},
      personInsurance: form.personInsurance,
      insuranceOwners: form.insuranceOwners.map(formatInsuranceOwnersData).filter(insuranceOwner => insuranceOwner),
    })),

    ...forForm(forms, flowConstants.personSteps.PERSON_RESPONSIBLE.NAME)(form => ({
      jumpTo: {
        name: flowConstants.personSteps.PERSON_RESPONSIBLE.NAME,
        personResponsibleType: form.personResponsibleType,
      },
      isOtherPersonFault: form.isOtherPersonFault,
    })),

    ...forForm(forms, flowConstants.personSteps.CLAIM_COMPENSATION_VEHICLE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.CLAIM_COMPENSATION_VEHICLE.NAME},
      claimCompensationVehicle: form.claimCompensationVehicle,
    })),

    ...forForm(forms, flowConstants.personSteps.CLAIM_COMPENSATION_PERSON.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.CLAIM_COMPENSATION_PERSON.NAME},
      claimCompensationPerson: form.claimCompensationPerson,
    })),

    ...forForm(forms, [flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])
    (([responsiblePersonTypeForm, medicalCareTypeForm]) => ({
      jumpTo: {
        name: flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      },
      responsiblePersonChoice: responsiblePersonTypeForm.responsiblePersonChoice,
      responsiblePerson: responsiblePersonTypeForm.responsiblePersonChoice === responsiblePersonTypes.LEGAL_PERSON ?
        formatLegalPerson(responsiblePersonTypeForm.legalPerson) :
        responsiblePersonTypeForm.naturalPersons.map(formatNaturalPersons),
    })),

    ...forForm(forms, flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME},
      responsiblePersonRelation: form.responsiblePersonRelation,
      description: form.description,
    })),

    ...forForm(forms, flowConstants.personSteps.SUFFERED_EVENT_TYPE.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.SUFFERED_EVENT_TYPE.NAME},
      sufferedEventTypes: form.sufferedEventTypes,
    })),

    ...forForm(forms, flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME},
      amount: setUnknownValue(form.amount),
      description: setUnknownValue(form.description),
    })),

    ...forForm(forms, flowConstants.personSteps.DAMAGES_CLAIM_FILED.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.DAMAGES_CLAIM_FILED.NAME},
      isDamagesClaimFiled: form.isDamagesClaimFiled,
    })),

    ...forForm(forms, [flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])
    (([accidentVehicleInfoForm, medicalCareTypeForm]) => ({
      jumpTo: {
        name: flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      },
      registrationNumber: setUnknownValue(accidentVehicleInfoForm.registrationNumber),
      seatType: setUnknownValue(accidentVehicleInfoForm.seatType),
    })),

    ...forForm(forms, flowConstants.personSteps.REFERRAL_NUMBERS.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.REFERRAL_NUMBERS.NAME},
      referralNumbers: form.referralNumbers.map(referralNumber => setUnknownValue(referralNumber.name)),
      card: setUnknownValue(form.card)
    })),

    ...forForm(forms, [flowConstants.personSteps.BENEFICIARY_PERSONS.NAME, flowConstants.steps.USER_IDENTIFICATION.NAME])(([beneficiaryPersonsForm, userIdentificationForm]) => (
      {
        jumpTo: {name: flowConstants.personSteps.BENEFICIARY_PERSONS.NAME},
        hasBeneficiaryPersons: userIdentificationForm.hasBeneficiaryPersons,
        beneficiaryPersons: beneficiaryPersonsForm.beneficiaryPersons.map(beneficiaryPerson => formatBeneficiaryPersonData(beneficiaryPerson)),
        isApplicantBeneficiary: userIdentificationForm.userInfo.isBeneficiary
      })),

    ...forForm(forms, [flowConstants.personSteps.PERSON_DOCUMENTS.NAME, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])
    (([personDocumentsForm, medicalCareTypeForm]) => ({
      jumpTo: {
        name: flowConstants.personSteps.PERSON_DOCUMENTS.NAME,
        medicalCareType: medicalCareTypeForm.medicalCareType,
        medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
      },
      documents: formatPersonDocuments(personDocumentsForm, medicalCareTypeForm),
    })),

    ...forForm(forms, flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME)(form => ({
      jumpTo: {name: flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME},
      ...form,
      taxInfos: form.taxInfos.map(taxInfo => ({
        country: taxInfo.addressName,
        taxNumber: taxInfo.taxNumber
      }))
    })),

    ...forForm(forms, [flowConstants.personSteps.ACCIDENT_INVOLVED.NAME, flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME])(
      ([accidentInvolvedForm, medicalCareTypeForm]) => (
        {
          jumpTo: {
            name: flowConstants.personSteps.ACCIDENT_INVOLVED.NAME,
            medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription
          },
          ...accidentInvolvedForm
        }
      ))
  }

  return steps ? filterSummary(summary, steps) : summary;
}

function forForm(forms, names) {
  if (typeof names === "string") {
    return f => forms[names] ? {[names]: f(forms[names])} : null;
  } else {
    const selected = names.map(name => forms[name]);

    if (some(selected, isUndefined)) {
      return f => null;
    } else {
      return f => ({[names[0]]: f(selected)});
    }
  }
}

function forField(fieldConfigurations, formName, fieldName) {
  const key = `${formName}#${fieldName}`;
  const visible = fieldConfigurations[key];

  return value => {
    return visible ? {[fieldName]: value} : {};
  }
}

function filterSummary(summary, steps) {
  const result = {};

  keys(summary).forEach(key => {
    const value = summary[key];

    if (isArray(value)) {
      const filteredValue = [];

      value.forEach((item, index) => {
        if (!isUndefinedOrNull(item) && isSummaryItemVisible(item, steps)) {
          filteredValue[index] = item;
        }
      });

      if (filteredValue.length > 0) {
        result[key] = filteredValue;
      }
    } else if (!isUndefinedOrNull(value) && isSummaryItemVisible(value, steps)) {
      result[key] = value;
    }
  });

  return result;
}

function isSummaryItemVisible({jumpTo}, steps) {
  return some(
    steps,
    step => step.name === jumpTo.name && step.index === jumpTo.index && !step.skip,
  );
}

function accidentDate(form) {
  switch (form.accidentDateType) {
    case accidentDateTypesConstants.TODAY:
      return formatDate(getDateToday(form));
    case accidentDateTypesConstants.YESTERDAY:
      return formatDate(dateFns.subDays(getDateToday(form), 1));
    case accidentDateTypesConstants.CALENDAR:
      return formatDate(form.accidentDate);
    case accidentDateTypesConstants.RANGE:
      return `${formatDate(form.accidentDateFrom)}-${formatDate(form.accidentDateTo)}`
    default:
      return '';
  }
}

function getDateToday(form) {
  return typeof form.dateToday === 'string' ? dateFns.parseISO(form.dateToday) : form.dateToday;
}

function accidentTime(form) {
  return {
    time: form.accidentTimeRange ? `${form.accidentTimeFrom}-${form.accidentTimeTo}` : `${form.accidentTimeHour}:${form.accidentTimeMinute}`,
    choice: form.choice,
  }
}

function accidentLocation(form) {
  return {
    selectedLocation: form.selectedLocation, // Kje se je zgodila nesreča?
    locationDescription: form.locationDescription, // Opis kraja dogotka
  }
}

function vehicleDamage(form) {
  const vehicleType = form.vehicleType;

  const fieldMapping = {
    [vehicleTypesConstants.CAR]: "carDamage",
    [vehicleTypesConstants.SEMI_TRUCK]: "semiTruckDamage",
    [vehicleTypesConstants.MOTORCYCLE]: "motorcycleDamage",
    [vehicleTypesConstants.BIKE]: "bikeDamage",
  };

  return {
    type: vehicleType,
    damage: form[fieldMapping[vehicleType]],
  };
}

function participantsInfo(stepName, participantsInfoForm, participantsNumberForm, accidentDateForm, carDamageTypeForm) {
  const result = [];

  filterParticipantsInfo(participantsNumberForm.finalParticipants, participantsInfoForm)
    .map(participantInfo => ({
      jumpTo: {
        name: stepName,
        index: participantInfo.info.index,
        accidentDate: getAccidentDate(accidentDateForm),
        participant: participantInfo.info,
      },
      participant: participantInfo,
      carDamageType: carDamageTypeForm.carDamageType,
    }))
    .forEach(participantInfo => {
      result[participantInfo.participant.info.index] = participantInfo;
    });
  return result;
}

function personParticipantsInfo(stepName, participantsInfoForm, participantsNumberForm, accidentDateForm, damagesClaimFiledForm, accidentInvolvedForm) {
  const result = [];

  filterParticipantsInfo(participantsNumberForm.finalParticipants, participantsInfoForm, accidentInvolvedForm)
    .map(participantInfo => ({
      jumpTo: {
        name: stepName,
        index: participantInfo.info.index,
        accidentDate: getAccidentDate(accidentDateForm),
        participant: participantInfo.info,
      },
      participant: participantInfo,
      carDamageType: carDamageTypes.CAR_ACCIDENT,
    }))
    .forEach(participantInfo => {
      if (damagesClaimFiledForm.isDamagesClaimFiled !== booleanTypesConstants.YES
        || participantInfo.participant.info.isOwnVehicle === true) {
        result[participantInfo.participant.info.index] = participantInfo;
      }
    });

  return result;
}

function formatDate(date) {
  return isUndefinedOrNull(date) || !dateFns.isValid(new Date(date)) ? "" : dateFns.format(new Date(date), summaryConstants.DATE_FORMAT);
}

function formatSex(sex) {
  const dontKnowString = "Ni znano, ";
  const maleString = "Moški, ";
  const femaleString = "Ženski, ";

  switch (sex) {
    case sexTypeConstants.FEMALE:
      return femaleString;
    case sexTypeConstants.MALE:
      return maleString;
    default:
      return dontKnowString;
  }
}

function formatRelationship(relationship) {
  const dontKnowString = "Ni znano";
  const parentString = "Starš";
  const childString = "Otrok";
  const partnerString = "Partner";
  const otherString = "Ostalo";

  switch (relationship) {
    case otherPersonRelationshipTypes.PARENT:
      return parentString;
    case otherPersonRelationshipTypes.CHILD:
      return childString;
    case otherPersonRelationshipTypes.PARTNER:
      return partnerString;
    case otherPersonRelationshipTypes.OTHER:
      return otherString;
    default:
      return dontKnowString;
  }
}

export function locationAddress(form) {
  if (isUndefined(form.address) || isUndefined(form.city) || isUndefined(form.zip)) {
    return null;
  }

  return `${form.address}, ${form.city} ${form.zip}`;
}

function determineHurt(passengers) {
  const yes = passengers.filter(passenger => _.at(passenger, "injuryType")[0] === booleanTypesConstants.YES);
  const no = passengers.filter(passenger => _.at(passenger, "injuryType")[0] === booleanTypesConstants.NO);
  const dontKnow = passengers.filter(passenger => _.at(passenger, "injuryType")[0] === booleanTypesConstants.DONT_KNOW);

  return {
    hurt: yes.length > 0
      ? {
        label: "summary.content.participantsInfo.ownVehiclePassengers.hurt.yes",
        parameters: [
          yes.map(formatParticipantNameAndSurname).join(", ")
        ]
      }
      : null,
    notHurt: no.length > 0
      ? {
        label: "summary.content.participantsInfo.ownVehiclePassengers.hurt.no",
        parameters: [
          no.map(formatParticipantNameAndSurname).join(", ")
        ]
      }
      : null,
    dontKnow: dontKnow.length > 0
      ? {
        label: "summary.content.participantsInfo.ownVehiclePassengers.hurt.dontKnow",
        parameters: [
          dontKnow.map(formatParticipantNameAndSurname).join(", ")
        ]
      }
      : null,
  }
}

function repairShopNameAndAddress(form) {
  if (form.repairServiceType === repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT && form.vehicleDamageInspectionPoint) {
    return {
      repairShopName: form.vehicleDamageInspectionPoint.name,
      repairShopAddress: form.vehicleDamageInspectionPoint.address
    }
  }
}

function responsiblePerson(participantsInfoForm, responsiblePerson) {
  if (responsiblePerson === responsiblePersonConstants.CANT_AGREE || responsiblePerson === responsiblePersonConstants.DONT_KNOW) {
    return responsiblePerson;
  }

  const found = filter(
    participantsInfoForm,
    participant => !isUndefined(participant) && contains(responsiblePerson, participant.info.index)
  );

  return found ? found : responsiblePersonConstants.DONT_KNOW;
}

function ownParticipant(participantsInfoForm) {
  return find(
    participantsInfoForm,
    participant => participant.info.isOwnVehicle,
  );
}

function otherParticipantsInfo(otherParticipantsInfoForm, participantsNumberForm, participantsInfoForm, fieldConfigurations = {}) {
  const filtered = filterParticipantsInfo(participantsNumberForm.finalParticipants, participantsInfoForm)
    .filter(participantInfo => !isUndefinedOrNull(otherParticipantsInfoForm[participantInfo.info.index]));

  const result = [];

  filtered.forEach(participantInfo => {
    const otherParticipant = otherParticipantsInfoForm[participantInfo.info.index];

    if (!isEmpty(otherParticipant)) {
      const {finalInfo} = otherParticipant;

      const hasParticipant = otherParticipant.otherParticipantsInfoType === booleanTypesConstants.YES;
      const participant = hasParticipant ? formatParticipantsData(finalInfo) : "";

      const jumpTo = {
        name: flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME,
        index: participantInfo.info.index,
        participant: participantInfo,
      };

      result[participantInfo.info.index] = {
        jumpTo,
        otherParticipantsInfoType: otherParticipant.otherParticipantsInfoType,
        ...forField(fieldConfigurations, "otherParticipantsInfo", "injuryType")({
          jumpTo,
          injuryType: finalInfo.injuryType,
        }),
        ...forField(fieldConfigurations, "otherParticipantsInfo", "passengers")({
          jumpTo,
          passengers: finalInfo.passengers,
        }),
        participant: participant,
        sex: otherParticipant.otherParticipantsInfoType === booleanTypesConstants.YES ? otherParticipant.otherParticipantsInfo.sex : "",
      }
    }
  });

  return result;
}

function formatAddress(props) {
  if (isUndefined(props[0])) {
    return {
      country: "",
      cityAndZip: "",
      streetAndStreetNo: "",
    };
  }

  const {city, address, country} = props[0];

  if (isUndefinedOrNull(country) || isUndefined(address) || isUndefined(city)) {
    return {
      country: "",
      cityAndZip: "",
      streetAndStreetNo: "",
    };
  } else {
    const cityName = city.city;
    const countryName = country.name;
    const {zip} = city;
    const {streetName, streetNumber} = address;

    return {
      country: countryName,
      cityAndZip: cityName + ", " + zip,
      streetAndStreetNo: streetName + ", " + streetNumber,
    };
  }
}

function getOtherDriverInfo(otherDriver) {
  const wasDriver = otherDriver.wasDriver === booleanTypesConstants.YES;

  if (wasDriver) {
    return {};
  }

  return {
    name: _.at(otherDriver, "otherDriver.name"),
    surname: _.at(otherDriver, "otherDriver.surname"),
    birthDate: formatDate(_.at(otherDriver, "otherDriver.birthDate")[0]),
    sex: _.at(otherDriver, "otherDriver.sex"),
    ...formatAddress(_.at(otherDriver, "otherDriver.address")),
    telephoneNumber: _.at(otherDriver, "otherDriver.phoneNumber")[0],
    licenceNumber: _.at(otherDriver, "otherDriver.licenceNumber")[0],
  };
}

function getUnknownValue(object, path) {
  let value = _.get(object, path, "");

  return setUnknownValue(value);
}

function setUnknownValue(value) {
  let newValue = value;
  if (isEmpty(value)) {
    newValue = "Ni znano"
  }

  return newValue;
}

function formatHomeResponsiblePerson(responsiblePersonForm) {
  const {responsiblePersonChoice, legalPerson, naturalPerson} = responsiblePersonForm;
  const isNaturalPerson = responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON;
  const responsiblePerson = isNaturalPerson ? naturalPerson : legalPerson;

  const streetName = getUnknownValue(responsiblePerson, "address.address.streetName");
  const streetNumber = getUnknownValue(responsiblePerson, "address.address.streetNumber");

  let nameAndSurname = getUnknownValue(responsiblePerson, "name");

  if (isNaturalPerson && responsiblePerson) {
    nameAndSurname = `${nameAndSurname} ${getUnknownValue(responsiblePerson, "surname")}`;
  }

  let city = getUnknownValue(responsiblePerson, "address.city.city");
  let zip = getUnknownValue(responsiblePerson, "address.city.zip");
  let country = getUnknownValue(responsiblePerson, "address.country.name");
  let policyNumber = getUnknownValue(responsiblePerson, "policyNumber");
  let taxNumber = getUnknownValue(responsiblePerson, "taxNumber");
  let sex = _.get(responsiblePerson, "sex", "");
  let fromPolicy = _.get(responsiblePerson, "fromPolicy", "unknown");

  return {
    nameAndSurname,
    sex,
    birthDate: _.get(responsiblePerson, "birthDate", null),
    streetAndNumber: `${streetName}, ${streetNumber}`,
    city,
    zip,
    country,
    policyNumber,
    taxNumber,
    fromPolicy,
  }
}

function formatNaturalPersons(responsiblePerson) {
  const nameAndSurname = formatParticipantNameAndSurname(responsiblePerson);
  const sex = formatSex(_.at(responsiblePerson, "sex")[0]);
  const birthDate = formatDate(_.at(responsiblePerson, "birthDate")[0]) || "Ni znano";
  const address = formatParticipantAddress(responsiblePerson);
  const policyNumber = getUnknownValue(responsiblePerson, "policyNumber");
  const taxNumber = getUnknownValue(responsiblePerson, "taxNumber");

  return nameAndSurname + sex + `${birthDate}, ` + `${address}, ` + `${policyNumber}, ` + taxNumber;
}

function formatLegalPerson(responsiblePerson) {
  const nameAndSurname = getUnknownValue(responsiblePerson, "name");
  const address = formatParticipantAddress(responsiblePerson);
  const policyNumber = getUnknownValue(responsiblePerson, "policyNumber");

  return `${nameAndSurname}, ` + `${address}, ` + policyNumber;
}

function formatWitnessData(witness) {
  const {telephoneNumber, email} = witness;
  const telephoneNumberString = !isUndefined(telephoneNumber) && telephoneNumber.length > 0 && !isEmpty(telephoneNumber) ? telephoneNumber : "Ni znano";
  const emailString = !isUndefined(email) && email.length > 0 && !isEmpty(email) ? email : "Ni znano";

  return `${formatWitnessesData(witness)}, ${telephoneNumberString}, ${emailString}`;
}

function formatWitnessesData(witnessInfo) {
  const nameAndSurname = formatParticipantNameAndSurname(witnessInfo);
  const address = formatParticipantAddress(witnessInfo);

  return nameAndSurname + address;
}

function formatBeneficiaryPersonData(beneficiaryPerson) {
  const {birthDate, sex, telephoneNumber, email, taxNumber, relationship} = beneficiaryPerson;
  const nameAndSurname = formatParticipantNameAndSurname(beneficiaryPerson);
  const sexString = formatSex(sex);
  const dateString = formatDate(birthDate);
  const addressString = formatParticipantAddress(beneficiaryPerson);
  const telephoneNumberString = !isUndefined(telephoneNumber) && telephoneNumber.length > 0 && !isEmpty(telephoneNumber) ? telephoneNumber : "Ni znano";
  const emailString = !isUndefined(email) && email.length > 0 && !isEmpty(email) ? email : "Ni znano";
  const taxNumberString = !isUndefined(taxNumber) && taxNumber.length > 0 && !isEmpty(taxNumber) ? taxNumber : "Ni znano";
  const relationshipString = formatRelationship(relationship);

  return `${nameAndSurname}${sexString}${dateString}, ${addressString}, ${telephoneNumberString}, ${emailString}, ${taxNumberString}, ${relationshipString}`;
}

export function formatUserInfo(form) {
  if (form.personType === personTypesConstants.NATURAL) {
    return {
      name: _.at(form, "userInfo.name")[0],
      surname: _.at(form, "userInfo.surname")[0],
      sex: _.at(form, "userInfo.sex")[0],
      birthDate: formatDate(_.at(form, "userInfo.birthDate")[0]),
      ...formatAddress(_.at(form, "userInfo.address")),
      address: _.at(form, "userInfo.address")[0],
      telephoneNumber: _.at(form, "userInfo.telephoneNumber")[0],
      email: _.at(form, "userInfo.email")[0],
      taxNumber: _.at(form, "userInfo.taxNumber")[0],
      licenceNumber: _.at(form, "userInfo.licenceNumber")[0],
      policyNumber: _.at(form, "userInfo.policyNumber")[0],
      policyNumberSelected: _.at(form, "userInfo.policyNumberSelected")[0],
      relationship: _.at(form, "userInfo.relationship")[0],
      crsId: _.at(form, "crsId")[0],
      isBeneficiary: _.at(form, "userInfo.isBeneficiary")[0] ? booleanTypesConstants.YES : booleanTypesConstants.NO
    };
  } else {
    return {
      name: _.at(form, "legalPerson.name")[0],
      ...formatAddress(_.at(form, "legalPerson.address")),
      address: _.at(form, "legalPerson.address")[0],
      email: _.at(form, "legalPerson.email")[0],
      contactPersonName: _.at(form, "legalPerson.contactPersonName")[0],
      telephoneNumber: _.at(form, "legalPerson.telephoneNumber")[0],
      taxNumber: _.at(form, "legalPerson.taxNumber")[0],
      policyNumber: _.at(form, "legalPerson.policyNumber")[0],
    };
  }
}

export function getNotKnownValue(value) {
  return !isUndefined(value) && value.length > 0 && !isEmpty(value) ? value : "Ni znano";
}

export function formatApplicantData(form, wasDriver) {
  if (wasDriver) {
    return {
      name: _.at(form, "driver.name")[0],
      surname: _.at(form, "driver.surname")[0],
      sex: _.at(form, "driver.sex")[0],
      birthDate: formatDate(_.at(form, "driver.birthDate")[0]),
      ...formatAddress(_.at(form, "driver.address")),
      address: _.at(form, "driver.address")[0],
      telephoneNumber: getNotKnownValue(_.at(form, "driver.telephoneNumber")[0]),
      email: getNotKnownValue(_.at(form, "driver.email")[0]),
      licenceNumber: _.at(form, "driver.licenceNumber")[0],
      taxNumber: _.at(form, "driver.taxNumber")[0],
    };
  } else {
    return {
      name: _.at(form, "general.name")[0],
      taxNumber: _.at(form, "general.taxNumber")[0],
      telephoneNumber: getNotKnownValue(_.at(form, "general.telephoneNumber")[0]),
      email: getNotKnownValue(_.at(form, "general.email")[0]),
    };
  }
}

export function formatDeceasedPersonInformation(form) {
  const {deceasedPersonInformation} = form;
  const streetName = getUnknownValue(deceasedPersonInformation, "address.address.streetName");
  const streetNumber = getUnknownValue(deceasedPersonInformation, "address.address.streetNumber");
  const city = getUnknownValue(deceasedPersonInformation, "address.city.city");
  const zip = getUnknownValue(deceasedPersonInformation, "address.city.zip");

  return {
    name: getUnknownValue(deceasedPersonInformation, "name"),
    surname: getUnknownValue(deceasedPersonInformation, "surname"),
    sex: _.at(form, "deceasedPersonInformation.sex")[0],
    birthDate: formatDate(_.at(form, "deceasedPersonInformation.birthDate")[0]),
    country: getUnknownValue(deceasedPersonInformation, "address.country.name"),
    streetAndStreetNo: streetName + ", " + streetNumber,
    cityAndZip: city + ", " + zip,
    policyNumber: getUnknownValue(deceasedPersonInformation, "policyNumber"),
    taxNumber: getUnknownValue(deceasedPersonInformation, "taxNumber"),
  };
}

function formatInsuranceOwnersData(insuranceOwner) {
  const {name} = insuranceOwner;

  return !isUndefined(name) && name.length > 0 && !isEmpty(name) ? name : null;
}

function formatPersonDocuments(personDocumentsForm, medicalCareTypeForm) {
  switch (medicalCareTypeForm.medicalCareType) {
    case medicalCareTypes.PAYMENT:
      switch (medicalCareTypeForm.medicalCareTypeDescription) {
        case medicalCareDescriptionTypes.SICK:
          return [
            ...(personDocumentsForm.referrals || []),
            ...(personDocumentsForm.medicalDocuments || []),
            ...(personDocumentsForm.other || []),
          ];
        case medicalCareDescriptionTypes.INJURY:
          return [
            ...(personDocumentsForm.medicalDocuments || []),
            ...(personDocumentsForm.sickLeave || []),
            ...(personDocumentsForm.drivingLicense || []),
            ...(personDocumentsForm.policeCertificate || []),
            ...(personDocumentsForm.other || []),
          ];
        case medicalCareDescriptionTypes.DEATH:
          return [
            ...(personDocumentsForm.deathCertificate || []),
            ...(personDocumentsForm.deathReport || []),
            ...(personDocumentsForm.inheritanceDeal || []),
            ...(personDocumentsForm.medicalDocuments || []),
            ...(personDocumentsForm.other || []),
          ];
        case medicalCareDescriptionTypes.RETURN_OF_COSTS:
          return [
            ...(personDocumentsForm.referrals || []),
            ...(personDocumentsForm.check || []),
            ...(personDocumentsForm.receipt || []),
            ...(personDocumentsForm.workOrder || []),
            ...(personDocumentsForm.decision || []),
            ...(personDocumentsForm.orderForm || []),
            ...(personDocumentsForm.report || []),
            ...(personDocumentsForm.dismissalLetter || []),
            ...(personDocumentsForm.operationalSheet || []),
            ...(personDocumentsForm.other || []),
          ];
      }
    case medicalCareTypes.HEALTH_SERVICE:
      return [
        ...(personDocumentsForm.referrals || []),
        ...(personDocumentsForm.check || []),
        ...(personDocumentsForm.workOrder || []),
        ...(personDocumentsForm.report || []),
        ...(personDocumentsForm.orderForm || []),
        ...(personDocumentsForm.dismissalLetter || []),
        ...(personDocumentsForm.deathCertificate || []),
        ...(personDocumentsForm.other || []),
      ];
    default:
      return [
        ...(personDocumentsForm.attachments || []),
      ];
  }
}
