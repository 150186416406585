import React from "react";

import * as dateFns from "date-fns";

import Text from "components/common/Text";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import SummaryTable from "components/step/summary/SummaryTable";
import bankAccountTypes from "constants/bankAccountTypes"
import claimTypes from "constants/claimTypes";
import flowConstants from "constants/flow";
import summaryConstants from "constants/summary";
import summaryRefConstants from "constants/summaryRef";
import SummaryBankAccount from "containers/step/summary/bankAccounts/SummaryBankAccount"
import SummaryBankAccountLegal from "containers/step/summary/bankAccounts/SummaryBankAccountLegal"
import Summary from "containers/step/summary/Summary";
import personTypeConstants from "constants/personTypes";


import styles from "containers/step/summary/bankAccounts/SummaryBankAccounts.module.scss";

export default function SummaryBankAccounts({claimType}) {
  return (
    <>
      <Summary title="summary.content.bankAccounts.title"
               refName={summaryRefConstants.BANK_ACCOUNTS.REF}>
        <Summary.Items formName={{name: flowConstants.steps.BANK_ACCOUNTS.NAME}}>
          {form => {
            const {bankAccountType, otherPersonType} = form;
            if (bankAccountType === bankAccountTypes.REPAIR_SHOP) {
              return <SummaryBankAccountRepairShop {...form}/>;
            }
            else if (bankAccountType === bankAccountTypes.OTHER_PERSON && otherPersonType === personTypeConstants.NATURAL) {
              return <SummaryBankAccountOtherPerson {...form} />;
            }
            else if (bankAccountType === bankAccountTypes.OTHER_PERSON && otherPersonType === personTypeConstants.LEGAL) {
                return <SummaryBankAccountOtherPersonLegal {...form} />;
            }
            else if (bankAccountType === bankAccountTypes.OTHER_LEGAL_PERSON) {
              return <SummaryBankAccountOtherLegalPerson {...form} claimType={claimType}/>
            } else if (bankAccountType === bankAccountTypes.DONT_KNOW) {
              return <SummaryBankAccountDontKnow {...form}/>
            }
            return <SummaryBankAccountRegular {...form}/>;
          }}
        </Summary.Items>
      </Summary>
    </>
  );
}

function SummaryBankAccountRepairShop(props) {
  const {repairShopName, repairShopAddress, jumpTo} = props;
  return (
    <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_REPAIR_SHOP.REF}>
      <SummaryTable.HeaderContent>
        <Text element="span" className={styles.header} label="summary.content.bankAccounts.subtitle.repair.shop" />
      </SummaryTable.HeaderContent>
    </SummaryTable.Part>
  );
}

function SummaryBankAccountOtherPerson({otherPerson, jumpTo}) {
  return (
    <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.REF}>
      <SummaryTable.HeaderContent>
        <Text element="span" className={styles.header} label="summary.content.bankAccounts.subtitle.other.person"/>
      </SummaryTable.HeaderContent>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.name"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.NAME}>
        {otherPerson.name}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.surname"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.SURNAME}>
        {otherPerson.surname}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.country"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.COUNTRY}>
        {otherPerson.address.country.name}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.street"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.STREET_NAME}>
        {otherPerson.address.address.street.name}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.street.number"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.STREET_NUMBER}>
        {otherPerson.address.address.streetNumber}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.city"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.CITY}>
        {otherPerson.address.city.city}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.tax.number"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.TAX_NUMBER}>
        {otherPerson.taxNumber}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.birthday"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.BIRTH_DATE}>
        {dateFns.format(new Date(otherPerson.birthDate), summaryConstants.DATE_FORMAT)}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.phone"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.TELEPHONE_NUMBER}>
        {otherPerson.telephoneNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.other.person.email"
                         formName={jumpTo}
                         refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.EMAIL}>
        {otherPerson.email || <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
      <SummaryTable.HeaderContent>
        <Text element="span" className={styles.header}
              label="summary.content.bankAccounts.other.person.bank.account.subtitle"/>
      </SummaryTable.HeaderContent>
      <SummaryRefContainer refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.BANK_ACCOUNT.REF}>
        <SummaryBankAccount jumpTo={jumpTo}
                            bankAccount={otherPerson} />
      </SummaryRefContainer>
    </SummaryTable.Part>
  );
}

function SummaryBankAccountOtherPersonLegal({otherPerson, jumpTo}) {
    return (
        <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.REF}>
            <SummaryTable.HeaderContent>
                <Text element="span" className={styles.header} label="summary.content.bankAccounts.subtitle.other.person.legal"/>
            </SummaryTable.HeaderContent>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.name.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.NAME}>
                {otherPerson.legalPerson.name}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.country.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.COUNTRY}>
                {otherPerson.legalPerson.address.country.name}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.street.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.STREET_NAME}>
                {otherPerson.legalPerson.address.address.street.name}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.street.number.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.STREET_NUMBER}>
                {otherPerson.legalPerson.address.address.streetNumber}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.city.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.CITY}>
                {otherPerson.legalPerson.address.city.city}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.tax.number.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.TAX_NUMBER}>
                {otherPerson.legalPerson.taxNumber}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.phone.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.TELEPHONE_NUMBER}>
                {otherPerson.legalPerson.telephoneNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.other.person.email.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.EMAIL}>
                {otherPerson.legalPerson.email || <Text label="summary.content.common.boolean.dontKnow"/>}
            </Summary.ItemValue>
            <SummaryTable.HeaderContent>
                <Text element="span" className={styles.header}
                      label="summary.content.bankAccounts.other.person.bank.account.subtitle.legal"/>
            </SummaryTable.HeaderContent>
            <SummaryRefContainer refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.LEGAL_PERSON.BANK_ACCOUNT.REF}>
                <SummaryBankAccountLegal jumpTo={jumpTo}
                                    bankAccount={otherPerson.legalPerson} />
            </SummaryRefContainer>
        </SummaryTable.Part>
    );
}

function SummaryBankAccountOtherLegalPerson({otherPerson, jumpTo, claimType}) {
  return (
    <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.REF}>
      <Summary.ItemValue label=""
                           formName={jumpTo}
                           refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_OTHER_PERSON.NAME}>
          <Text label={claimType === claimTypes.PERSON ? "summary.content.bankAccounts.subtitle.contractor" : "summary.content.bankAccounts.subtitle.repair.shop"}/>
      </Summary.ItemValue>
    </SummaryTable.Part>
  );
}

function SummaryBankAccountRegular(props) {
  const {finalBankAccount, jumpTo} = props;

  return (
    <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_REGULAR.REF}>
      <SummaryBankAccount jumpTo={jumpTo} bankAccount={finalBankAccount}/>
    </SummaryTable.Part>
  );
}

function SummaryBankAccountDontKnow({jumpTo}) {
  return (
    <SummaryTable.Part refName={summaryRefConstants.BANK_ACCOUNTS.BANK_ACCOUNT_DONT_KNOW.REF}>
      <Summary.ItemValue label="summary.content.bankAccounts.bank.account.dontKnow"
                         formName={jumpTo}
                         refName={summaryRefConstants.COMMON.BANK_ACCOUNT.NAME}>
        <Text label="summary.content.common.boolean.dontKnow"/>
      </Summary.ItemValue>
    </SummaryTable.Part>
  );
}