import React from "react";

import {isUndefined} from "underscore";

import Text from "components/common/Text";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryBankAccountLegal({bankAccount, jumpTo}) {

    const hasBankAccount = !isUndefined(bankAccount);
    const bankAccountNumber = hasBankAccount ? bankAccount.bankAccount : undefined;
    const bank = hasBankAccount && !isUndefined(bankAccount.bank) ? bankAccount.bank : {};

    return (
        <>
            <Summary.ItemValue label="summary.content.bankAccounts.bank.account.number.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.COMMON.BANK_ACCOUNT.NAME}>
                {bankAccountNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.bank.account.swift.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.COMMON.BANK_ACCOUNT.SWIFT}
                               condition={form => !!bankAccountNumber && !bankAccountNumber.toUpperCase().startsWith("SI")}>
                {bank.bic || <Text label="summary.content.common.boolean.dontKnow"/>}
            </Summary.ItemValue>
            <Summary.ItemValue label="summary.content.bankAccounts.bank.account.bank.name.legal"
                               formName={jumpTo}
                               refName={summaryRefConstants.COMMON.BANK_ACCOUNT.BANK_NAME}
                               condition={form => !!bankAccountNumber && !bankAccountNumber.toUpperCase().startsWith("SI")}>
                {bank.name || <Text label="summary.content.common.boolean.dontKnow"/>}
            </Summary.ItemValue>
        </>
    );
}