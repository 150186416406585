import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import TaxNumber from "components/step/TaxNumber"
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import wasDriverTypeConstants from "constants/wasDriverTypes";
import SexField from "components/form/SexField";
import {leaveNumbersAndLetters} from "utility/textTransform";
import isUndefinedOrNull from "utility/utilityFunctions";
import dl from "images/stevilka-vozniskega-dovoljenja.jpg";

export default function ApplicantDataRight(props) {
  const {values: {wasDriver}} = props;

  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      {wasDriver === wasDriverTypeConstants.YES
        ? <DriverApplicantData {...props}/>
        : <GeneralApplicantData {...props} />
      }
    </Layout>
  )
}

function GeneralApplicantData(props) {

  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <TextField name="general.name"
                   placeholder="applicantData.content.name.placeholder"
                   label="applicantData.content.name.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
        <Layout.OneColumn>
            <TaxNumber name="general.taxNumber"
                       placeholder="applicantData.content.taxNumber.placeholder"
                       label="applicantData.content.taxNumber.label"
                       isRequired={false}
                       isSlovenia={false}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
            <TextField name="general.telephoneNumber"
                       placeholder="applicantData.content.telephoneNumber.placeholder"
                       label="applicantData.content.telephoneNumber.label"
                       maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].telephoneNumber + 1} />
        </Layout.OneColumn>
        {isUndefinedOrNull(props.values) || isUndefinedOrNull(props.values.general) || isUndefinedOrNull(props.values.general.telephoneNumber) || props.values.general.telephoneNumber === '' ?
            <Layout.OneColumn>
                <Text element="span" variant="variant8" label="applicantData.content.telephoneNumber.info" />
            </Layout.OneColumn>
            :
            null
        }
        <Layout.OneColumn>
            <TextField name="general.email"
                       placeholder="applicantData.content.email.placeholder"
                       label="applicantData.content.email.label"
                       maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email + 1} />
        </Layout.OneColumn>
        {isUndefinedOrNull(props.values) || isUndefinedOrNull(props.values.general) || isUndefinedOrNull(props.values.general.email) || props.values.general.email === '' ?
            <Layout.OneColumn>
                <Text element="span" variant="variant8" label="applicantData.content.email.info" />
            </Layout.OneColumn>
            :
            null
        }
    </Layout>
  );
}

function DriverApplicantData(props) {

  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="applicantData.content.general.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.name"
                   placeholder="applicantData.content.name.placeholder.driver"
                   label="applicantData.content.name.label.driver"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.surname"
                   placeholder="applicantData.content.surname.placeholder.driver"
                   label="applicantData.content.surname.label.driver"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].surname + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
        <Layout.OneColumn>
            <TaxNumber name="driver.taxNumber"
                       placeholder="applicantData.content.taxNumber.placeholder"
                       label="applicantData.content.taxNumber.label"
                       isRequired={true}
                       isSlovenia={false}/>
        </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name="driver.birthDate"
                   isRequired={true}
                   placeholder="applicantData.content.birthDate.placeholder"
                   label="applicantData.content.birthDate.label"
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SexField name="driver.sex" isRequired={true} />
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name="driver.address"
                 isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.telephoneNumber"
                   placeholder="applicantData.content.telephoneNumber.placeholder"
                   label="applicantData.content.telephoneNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].telephoneNumber + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
        {isUndefinedOrNull(props.values) || isUndefinedOrNull(props.values.driver) || isUndefinedOrNull(props.values.driver.telephoneNumber) || props.values.driver.telephoneNumber === '' ?
            <Layout.OneColumn>
                <Text element="span" variant="variant8" label="applicantData.content.telephoneNumber.info" />
            </Layout.OneColumn>
            :
            null
        }
        <Layout.OneColumn>
            <TextField name="driver.email"
                       placeholder="applicantData.content.email.placeholder"
                       label="applicantData.content.email.label"
                       maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email + 1} />
        </Layout.OneColumn>
        {isUndefinedOrNull(props.values) || isUndefinedOrNull(props.values.driver) || isUndefinedOrNull(props.values.driver.email) || props.values.driver.email === '' ?
            <Layout.OneColumn>
                <Text element="span" variant="variant8" label="applicantData.content.email.info" />
            </Layout.OneColumn>
            :
            null
        }
      <Layout.OneColumn>
        <TextField name="driver.licenceNumber"
                   placeholder="applicantData.content.licenceNumber.placeholder"
                   transform={leaveNumbersAndLetters}
                   label="applicantData.content.licenceNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].licenceNumber + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <div style={{ paddingTop: 16 }}>
          <img src={dl} alt="Drivers license" style={{ maxWidth: "100%" }} />
        </div>
      </Layout.OneColumn>
    </Layout>
  );
}