import React from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import fontConstants from "constants/font";
import repairServiceTypeConstants from "constants/repairServiceTypes";
import carDamageTypeConstants from "constants/carDamageTypes";
import FontIcon from "../../../components/icon/FontIcon";

export default function MapsIntroRight({ onNextStep, step }) {
  const carDamageType = step.carDamageType;

  return (
    <Layout>
      <Layout.OneColumn>
        <Text element="h2" label="mapsIntro.content.title" variant="variant1" />
      </Layout.OneColumn>
      <Layout.OneColumn spacing={10} />
      <Layout.OneColumn width="100%">
        <NextStepButton
          component={ToggleButton}
          isValid={true}
          onNextStep={onNextStep}
          buttonProps={{
            name: "repairServiceType",
          }}
        >
          <Layout spacing={10} width="100%" textAlign="center">
              <Layout.OneColumn>
                  <ToggleButton.Choice
                      value={repairServiceTypeConstants.BUSINESS_UNIT}
                      icon={<FontIcon font="repairService" icon={fontConstants.REPAIR_SERVICE_ICON.GARAGE} />}
                      label="mapsIntro.content.button.businessUnit.title"
                      showCheckMark={false}
                      variant="variant24"
                  />
              </Layout.OneColumn>
            <Layout.OneColumn>
              <ToggleButton.Choice value={repairServiceTypeConstants.PHOTO} icon={<FontIcon font="icoMoon" icon={fontConstants.ICOMOON.CAMERA} />} label="mapsIntro.content.button.photo.title" showCheckMark={false} variant="variant24" />
            </Layout.OneColumn>
            {carDamageType === carDamageTypeConstants.BREAKING_GLASS ? null : (
              <Layout.OneColumn>
                <ToggleButton.Choice value={repairServiceTypeConstants.NOT_DRIVEABLE} icon={<FontIcon font="asDom" icon={fontConstants.ASDOM_ICON.VEHICLE_IMPACT} />} label="mapsIntro.content.button.notDriveable.title" showCheckMark={false} variant="variant24" />
              </Layout.OneColumn>
            )}
          </Layout>
        </NextStepButton>
      </Layout.OneColumn>
    </Layout>
  );
}
