import React, {Component} from "react";

import Switch, {Case} from "react-switch-case";

import Layout from "components/common/Layout";
import CheckBoxTreeField from "components/form/CheckBox/CheckBoxTreeField";
import Asdom from "components/icon/Asdom";
import DomOseba from "components/icon/DomOseba";
import homeDamagedGoodsTypesConstants from "constants/homeDamagedGoodsTypes";
import homeGoodsTypesConstants from "constants/homeGoodsTypes";

export default class HomeDamagedGoodsRight extends Component {
  render() {
    const {values: {homeDamagedGoodsType, lightningSelected}} = this.props;

    return (
      <Layout>
        <Layout.OneColumn width="100%" maxWidth={470} justifyContent="center">
          <Layout.OneRow spacing={10} flex={1}>
            <Switch condition={homeDamagedGoodsType}>
              <Case value={homeDamagedGoodsTypesConstants.GLASS}>
                <GlassAndCeramics/>
              </Case>
              <Case value={homeDamagedGoodsTypesConstants.DEVICES}>
                <Devices/>
              </Case>
              <Case value={homeDamagedGoodsTypesConstants.REGULAR}>
                <CheckBoxTreeField name="homeDamagedGoods" multiple={true}>
                  {housesAndBuildings()}
                  {auxiliary()}
                  {furnishings()}
                  {personalItems()}
                  {farm()}
                  {lightningSelected ? devices() : null}
                  {other()}
                </CheckBoxTreeField>
              </Case>
            </Switch>
          </Layout.OneRow>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={10}/>
      </Layout>
    )
  }
}

function housesAndBuildings() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.NAME}`}
      name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.ROOF.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.ROOF.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.FACADE.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.FACADE.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.WINDOWS_AND_DOORS.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.WINDOWS_AND_DOORS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_WALLS.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_WALLS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.FLOOR_COVERING.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.FLOOR_COVERING.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_INSTALLATIONS.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_INSTALLATIONS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.SOLAR_COLLECTORS.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.SOLAR_COLLECTORS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.SHADES.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.SHADES.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_OTHER.NAME}`}
        name={homeGoodsTypesConstants.HOUSES_AND_BUILDINGS.CHILDREN.HOUSE_OTHER.NAME}/>
    </CheckBoxTreeField.Option>
  );
}

function auxiliary() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.NAME}`}
      name={homeGoodsTypesConstants.AUXILIARY.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.AUXILIARY.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.STORAGE.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.STORAGE.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.SUMMER_KITCHEN.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.SUMMER_KITCHEN.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.SWIMMING_POOL.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.SWIMMING_POOL.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.AUXILIARY_WALLS.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.AUXILIARY_WALLS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.WINTER_GARDENS.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.WINTER_GARDENS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.ACCOMMODATION.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.ACCOMMODATION.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.AUXILIARY.CHILDREN.AUXILIARY_OTHER.NAME}`}
        name={homeGoodsTypesConstants.AUXILIARY.CHILDREN.AUXILIARY_OTHER.NAME}/>
    </CheckBoxTreeField.Option>
  );
}

function furnishings() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.NAME}`}
      name={homeGoodsTypesConstants.FURNISHINGS.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.FURNISHINGS.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNITURE.NAME}`}
        name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNITURE.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.HOUSING_OBJECTS.NAME}`}
        name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.HOUSING_OBJECTS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.ART.NAME}`}
        name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.ART.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNISHINGS_DEVICES.NAME}`}
        name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNISHINGS_DEVICES.NAME}/>
        <CheckBoxTreeField.Option
            label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.STOCKS_OF_LEGAL_ENTITIES.NAME}`}
            name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.STOCKS_OF_LEGAL_ENTITIES.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNISHINGS_OTHER.NAME}`}
        name={homeGoodsTypesConstants.FURNISHINGS.CHILDREN.FURNISHINGS_OTHER.NAME}/>
    </CheckBoxTreeField.Option>
  );
}

function farm() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.NAME}`}
      name={homeGoodsTypesConstants.FARM.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.FARM.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURE_OBJECTS.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURE_OBJECTS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURE.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURE.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURAL_EQUIPMENT.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.AGRICULTURAL_EQUIPMENT.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.ANIMALS.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.ANIMALS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.MACHINERY.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.MACHINERY.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.HAY.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.HAY.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.FARM.CHILDREN.FARM_OTHER.NAME}`}
        name={homeGoodsTypesConstants.FARM.CHILDREN.FARM_OTHER.NAME}/>
    </CheckBoxTreeField.Option>
  );
}

function GlassAndCeramics() {
  return (
    <CheckBoxTreeField name="homeDamagedGoods" multiple={true}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.NAME}`}
        name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.NAME}
        icon={<Asdom icon={homeGoodsTypesConstants.GLASS_AND_CERAMICS.ICON}/>}>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_WINDOW_DOOR.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_WINDOW_DOOR.NAME}/>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.INDUCTION_PLATE.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.INDUCTION_PLATE.NAME}/>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_SANITARY_CERAMICS.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_SANITARY_CERAMICS.NAME}/>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_FURNITURE.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_FURNITURE.NAME}/>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_PARTITIONS.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_PARTITIONS.NAME}/>
        <CheckBoxTreeField.Option
          label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_OTHER.NAME}`}
          name={homeGoodsTypesConstants.GLASS_AND_CERAMICS.CHILDREN.GLASS_OTHER.NAME}/>
      </CheckBoxTreeField.Option>
    </CheckBoxTreeField>
  );
}

function Devices() {
  return (
    <CheckBoxTreeField name="homeDamagedGoods" multiple={true}>
      {devices()}
    </CheckBoxTreeField>
  );
}

function devices() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.NAME}`}
      name={homeGoodsTypesConstants.DEVICES.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.DEVICES.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.APPLIANCES.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.APPLIANCES.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.ELECTRONIC_EQUIPMENT.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.ELECTRONIC_EQUIPMENT.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.HEATING_SYSTEMS.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.HEATING_SYSTEMS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.AIR_CONDITIONING.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.AIR_CONDITIONING.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.INSTALLATIONS.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.INSTALLATIONS.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.DEVICES.CHILDREN.DEVICES_OTHER.NAME}`}
        name={homeGoodsTypesConstants.DEVICES.CHILDREN.DEVICES_OTHER.NAME}/>
    </CheckBoxTreeField.Option>
  );
}

function other() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.OTHER.NAME}`}
      name={homeGoodsTypesConstants.OTHER.NAME}
      icon={<Asdom icon={homeGoodsTypesConstants.OTHER.ICON}/>}/>
  );
}

function personalItems() {
  return (
    <CheckBoxTreeField.Option
      label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.PERSONAL_ITEMS.NAME}`}
      name={homeGoodsTypesConstants.PERSONAL_ITEMS.NAME}
      icon={<DomOseba icon={homeGoodsTypesConstants.PERSONAL_ITEMS.ICON}/>}>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.PERSONAL_ITEMS_VALUABLES.NAME}`}
        name={homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.PERSONAL_ITEMS_VALUABLES.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.CLOTHES.NAME}`}
        name={homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.CLOTHES.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.SPORT_EQUIPMENT.NAME}`}
        name={homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.SPORT_EQUIPMENT.NAME}/>
      <CheckBoxTreeField.Option
        label={`homeGoodsTypes.content.checkBoxTree.${homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.PERSONAL_ITEMS_OTHER.NAME}`}
        name={homeGoodsTypesConstants.PERSONAL_ITEMS.CHILDREN.PERSONAL_ITEMS_OTHER.NAME}/>

    </CheckBoxTreeField.Option>
  );
}

