import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import UserIdentificationTypeRight from "containers/step/userIdentificationType/UserIdentificationTypeRight";

export default class UserIdentificationTypeStepContainer extends Component {
  left({fromSummary, claimType, insuranceOwner}) {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`userIdentificationType.sidebar.${claimType}.${insuranceOwner}.title`} />
        <Text element="div" isHtml={true} label={`userIdentificationType.sidebar.${claimType}.${insuranceOwner}.subtitle`} />
      </SplitViewContainer.Left>
    );
  }

  right(props) {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <UserIdentificationTypeRight {...props} />
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}