import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import SexField from "components/form/SexField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import claimTypes from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {capitalize} from "utility/textTransform";
import {leaveNumbersAndLetters} from "utility/textTransform";
import dl from "images/stevilka-vozniskega-dovoljenja.jpg";

export default function OwnVehicleInfoWasDriverOtherDriver({claimType}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="h4" variant="variant1" label="ownVehicle.wasDriver.otherDriver.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherDriver.name"
                   transform={capitalize}
                   placeholder="ownVehicle.wasDriver.otherDriver.name.placeholder"
                   label="ownVehicle.wasDriver.otherDriver.name.label"
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherDriver.surname"
                   transform={capitalize}
                   placeholder="ownVehicle.wasDriver.otherDriver.surname.placeholder"
                   label="ownVehicle.wasDriver.otherDriver.surname.label"
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].surname + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name="otherDriver.birthDate"
                   label="ownVehicle.wasDriver.otherDriver.birthDate.label"
                   placeholder="ownVehicle.wasDriver.otherDriver.birthDate.placeholder"
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SexField name="otherDriver.sex" isRequired={true} />
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name="otherDriver.address"
                 isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherDriver.phoneNumber"
                   placeholder="ownVehicle.wasDriver.otherDriver.phoneNumber.placeholder"
                   label="ownVehicle.wasDriver.otherDriver.phoneNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].telephoneNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherDriver.licenceNumber"
                   placeholder="ownVehicle.wasDriver.otherDriver.licenceNumber.placeholder"
                   label="ownVehicle.wasDriver.otherDriver.licenceNumber.label"
                   transform={leaveNumbersAndLetters}
                   isRequired={claimType !== claimTypes.PERSON}
                   maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].licenceNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <div style={{ paddingTop: 16 }}>
          <img src={dl} alt="Drivers license" style={{ maxWidth: "100%" }} />
        </div>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
    </Layout>
  );
}
